import React from 'react'
import { useState, useEffect } from 'react';
import wethImg from '../images/weth.png';
import ethImg from '../images/eth.png';

var sortArgs = {};

export default function Filter(props) {
    const [active, setActive] = useState({'1': true, '2': false, '3': false, '4': false});
    const [categoryActive, setCategoryActive] = useState('');
    const [statusActive, setStatusActive] = useState('');
    const [chainActive, setChainActive] = useState('');
    
    useEffect(() => { //When page changes, reset active arguments, the actual argument varibles get reset above this Filter function on initial render (so per every page change). The active varibles dont though because they are set state varibles.
        resetArgs();
    }, [window.location.href])
    
    

    function handleDropdown(e) {
        var id = e.target.id;
        setActive( prevValues => {
            return {...prevValues, [id]: active[id] ? false : true}
        })
        var content = e.target.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }

    function filterArgs() {
        // if (props.show === true) { can use to get rid of mobile filter when we select an item
        //     props.mobileFilter();
        // }
        props.getNFTs(sortArgs);
    }

    function handleReset() {
        resetArgs();
        if (props.isMasterReset) {
            props.masterReset();
        }
        props.getNFTs(sortArgs);
    }

    function resetArgs() {
        setCategoryActive('');
        setStatusActive('');
        setChainActive('');
        sortArgs = {};
    }

    function CategoryButton(props) {
        var category = props.category;
        var image = '';
        const categories = {
            'album': 'library_music', 
            'ep': 'music_video', 
            'single': 'music_note', 
            'ticket': 'local_activity', 
            'collectable': 'diamond', 
            'art': 'palette', 
            'other': 'category'
        }

        function handleCategory() { //onClick={(e) => function(e, arg)} the first e is the actual onclick function, so that is where we define the event varible. That onclick function is calling the new function which we can pass the event varble too while defining new arguments
            if (categoryActive === category) {
                setCategoryActive('');
                sortArgs['category'] = '';
            } else {
                setCategoryActive(category);
                sortArgs['category'] = category;
            }
    
            filterArgs();
        }

        return(
            <button className={`categorybutton cb ${categoryActive === category ? "active" : ""}`} onClick={handleCategory}>
                <div className="categoryimagecontainer">
                    <i className="material-icons">{categories[category]}</i>
                    {/* <img className="categoryimage" src={image}/> */}
                </div>
                <div className="filtercategoryname">{category.charAt(0).toUpperCase() + category.slice(1)}</div>
            </button>
        )
    }

    function StatusButton(props) {
        var status = props.status;
        var statusText = '';
        if (status === 'hasoffers') {
            statusText = 'Has Offers';
        } else {
            statusText = status.charAt(0).toUpperCase() + status.slice(1)
        }

        function handleStatus() {
            statusActive === status ? setStatusActive('') : setStatusActive(status);
            sortArgs['status'] = status;
            filterArgs();
        }

        return(
            <button className={`panelbutton statusb ${statusActive === status ? "active" : ""}`} onClick={handleStatus}>
                {statusText}
            </button>
        )
    }

    function PriceSort() {

        function handlePrice() {
            var min = document.querySelector("#minnum").value;
            var max = document.querySelector("#maxnum").value;
            if (min === '' && max === '') {
                sortArgs['price'] = null;
            } else {
                sortArgs['price'] = min + "," + max;
            }
            filterArgs();
        }

        return(
            <div className="panelpadded">
                <div className="selectinputcontainer">
                    <div className="currencyflex">
                        <div className="currencycontainer">
                            <img src={ethImg} className="currencyimg" />
                        </div>
                    </div>
                    <input readOnly value="Ether (ETH)" className="currencyinput" />
                    <div className="inputarrowflex">
                        <i className="inputarrowicon">keyboard_arrow_down</i>
                    </div>
                </div>
                <div className="minmaxflex">
                    <div className="minmaxblock">
                        <section className="minmaxsection">
                            <div className="minmaxinputcontainer">
                                <div className="minmaxmain">
                                    <div className="minmaxprefix"></div>
                                    <input autocapitalize="off" autocomplete="off" autocorrect="off" className="minmaxinput" inputmode="decimal" placeholder="Min" spellcheck="false" type="text" defaultValue="" id="minnum"/>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="toflex">
                        to
                    </div>
                    <div className="minmaxblock">
                        <section className="minmaxsection">
                            <div className="minmaxinputcontainer">
                                <div className="minmaxmain">
                                    <div className="minmaxprefix"></div>
                                    <input autocapitalize="off" autocomplete="off" autocorrect="off" className="minmaxinput" inputmode="decimal" placeholder="Max" spellcheck="false" type="text" defaultValue="" id="maxnum"/>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="applyblock">
                    <button className="applybutton" onClick={handlePrice}>
                        Apply
                    </button>
                </div>
            </div>
        )
    }

    function ChainSort() {
        function ChainButton(props) {
            var chain = props.chain;
            var chainText = '';
            var chainIcon = '';
            switch (chain) {
                case 'matic':
                    chainText = 'Polygon (MATIC)';
                    chainIcon = wethImg;
                    break;
                case 'eth':
                    chainText = 'Ethereum';
                    chainIcon = ethImg;
                    break;
            }

            function handleChain() {
                chainActive === chain ? setChainActive('') : setChainActive(chain);
                console.log('chainActive');
                sortArgs['chain'] = chain;
                filterArgs();
            }

            return (
                <button className={`categorybutton chainb ${chainActive === chain ? "active" : ""}`} onClick={handleChain}>
                    <div className="chainimagecontainer">
                        <div className="chainimagediv">
                            <img className="chainimg" src={chainIcon} />
                        </div>
                    </div>
                    <div className="filtercategoryname" id={chain}>{chainText}</div>
                </button>
            )
        }

        return(
            <div className="panelpadded">
                <div className="scrollboxcontainer">
                    <div className="scrollboxcontent">
                        <ChainButton chain="matic" />
                        <ChainButton chain="eth" />
                    </div>
                </div>
            </div>
        )
    }

  return (
    <div className={`filterblock ${props.show ? 'show' : ''}`}  id="filterblock">
            <div className="filtercontainer">
                <div className="filtermain">
                    <header className="filterheader">
                        <div className="filterback" onClick={props.mobileFilter}>
                            <i className="material-icons filterbackicon">arrow_back</i>
                        </div>
                        <button className="filterbutton unstyledbutton" type="button">
                            <div className="filterheadertext">
                                <i className="filtericon">filter_list</i>
                                Filter
                            </div>
                        </button>
                        <div className="resetfilters" id="resetfilters" onClick={handleReset}>
                            <span className="material-icons reseticon">
                                replay
                            </span>
                        </div>
                    </header>
                    <div className="categoryfilter">
                        <div className="panelcontainer">
                            <div className="paneldiv">
                                <header className={`panelheader ${active['1'] === true ? "active" : ""}`} id="1" onClick={handleDropdown}>
                                    <span>Category</span>
                                    <i className="expandlessicon">expand_less</i>
                                </header>
                                <div className="panelbody" style={{maxHeight: '320px'}}>
                                    <div className="panelcontent">
                                        <div className="panelpadded">
                                            <div className="scrollboxcontainer">
                                                <CategoryButton category="album" />
                                                <CategoryButton category="ep" />
                                                <CategoryButton category="single" />
                                                <CategoryButton category="ticket" />
                                                <CategoryButton category="collectable" />
                                                <CategoryButton category="art" />
                                                <CategoryButton category="other" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="statefiltercontainer">
                        <div className="panelcontainer">
                            <div className="paneldiv">
                                <header className={`panelheader ${active['2'] === true ? "active" : ""}`} id="2" onClick={handleDropdown}>
                                    <span>Status</span>
                                    <i className="expandlessicon">expand_less</i>
                                </header>
                                <div className="panelbody">
                                    <div className="panelcontent">
                                        <div className="panelfilteritems">
                                            <StatusButton status="listed" />
                                            <StatusButton status="auctioned" />
                                            <StatusButton status="hasoffers" />
                                            <StatusButton status="new" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panelcontainer">
                        <div className="paneldiv">
                            <header className={`panelheader ${active['3'] === true ? "active" : ""}`} id="3" onClick={handleDropdown}>
                                <span>Price</span>
                                <i className="expandlessicon">expand_less</i>
                            </header>
                            <div className="panelbody">
                                <div className="panelcontent">
                                    <PriceSort />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panelcontainer">
                        <div className="panelcontainer">
                            <div className="paneldiv">
                                <header className={`panelheader ${active['4'] === true ? "active" : ""}`} id="4" onClick={handleDropdown}>
                                    <span>Chain</span>
                                    <i className="expandlessicon">expand_less</i>
                                </header>
                                <div className="panelbody">
                                    <div className="panelcontent">
                                        <ChainSort />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
