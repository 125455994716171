import React, {useEffect, useState} from "react";
import SiteHelmet from "../components/siteHelmet";
import Config from '../Config.json';
import { useParams, Navigate } from "react-router-dom";
import jQuery from 'jquery';
import Tippy from '@tippyjs/react';
import tippy from 'tippy.js';
import {Link} from 'react-router-dom';
import {copy, copyLink} from '../js/functions';
import Filter from "../components/filter";
import NFTCard from "../components/nftcard";
import CopyAddress from "../components/copyAddress";
import NotFound from "./notfound";
import '../css/profile.css';
import loadingImg from '../images/loading.gif';
import verifiedCheck from '../images/verified.png';
import defaultProfile from '../images/defaultProfile.png';
import wethImg from '../images/weth.png';
import ethImg from '../images/eth.png';
import cineteriaLogo from '../images/cineteria.png';
import facebook from '../images/facebook.png';
import twitter from '../images/twitter.png';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const cryptoImages = {
    "WETH": wethImg,
    "ETH": ethImg,
};

var MasterArgs = {};
var profileNav = ''; //not using useState because it won't update in time
var searchValue = ''; //Using varible instead of looking for html element. This makes sure that no matter what the page load is, this varible is here. So it will never be null or undefined.

function Profile(props) {
    const {getUser} = useParams();
    const [profileData, setProfileData] = useState([]);
    const [NFTFunction, setGetNFTs] = useState('');
    const [nftData, setNFTData] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [search, setSearch] = useState('');
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [profileActive, setProfileActive] = useState('owner');
    const [showMobileFilter, setMobileFilter] = useState(false);
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgError, setImgError] = useState(false);
    console.log(props.user);
    function handleImgLoad() {
        if (imgLoaded === false) {
            setImgLoaded(true);
        }
    }

    function handleImgError() {
        if (imgError === false) {
            setImgError(true);
        }
    }

    useEffect(() => {
        return () => {
            MasterArgs = {};
            searchValue = '';
            handleProfileNav('owner');
        }
    }, [window.location.href])
    useEffect(() => {
        console.log(window.userAddress);
        var userData = {
            'userAddress': props.user === null ? '' : props.user.address,
            'getUser': getUser,
        };
        console.log(userData);
        async function fetchData() {
            var user = await jQuery.ajax({
                url: `${API_ENDPOINT}profilepage.php`,
                type: 'POST',
                data: userData,
                xhrFields: {
                    withCredentials: true,
                },
            });
            user = JSON.parse(user);
            if (user.address === '0x25299a2acdd39d81988e3405d43de9912621333f') {
                user.profilePic = loadingImg;
            }
            console.log(user);
            if (props.isLoggedIn) {
                console.log("ISLOGGEDIN");
            } else {
                console.log("IS NOT LOGEED IN");
            }
            setProfileData(user);
            getNFTs(user);
        }

        if (isLoaded === true) {
            setLoaded(false);
        }
        fetchData();
    }, [getUser, window.userAddress])

    async function getNFTs(user, sortArgs = '') {
        if (nftData !== '') {
            setNFTData('');
        }
        console.log(sortArgs);
        if (sortArgs !== '') { //This works because if user has sorted before it keeps the array key values
            MasterArgs = sortArgs; //Store Globally so we can add the search value to it
             //MasterArgs gets updated everytime sortargs is updated, as long as it has values. Then we add the search value to it.
        }
        MasterArgs['searchString'] = searchValue;
        var userData = {
            'userAddress': props.user === null ? '' : props.user.address,
            'getUser': getUser,
            'args': MasterArgs,
            'param': profileNav
        };

        userData.getUser = user.address;
        var nfts = await jQuery.ajax({
            url: `${API_ENDPOINT}getNFTs.php`,
            type: 'POST',
            data: userData,
        });
        nfts = JSON.parse(nfts);
        console.log(nfts);
        setNFTData(nfts);
        setLoaded(true);
        //setGetNFTs(getNFTs);
    }

    console.log(profileData);
    if (profileData.user === false) {
        return (
            <NotFound />
        )
    }  

    function masterReset() { //Reset the master args value
        MasterArgs = {};
        //setSearch('');
        searchValue = '';
    }

    function handleProfileNav(param) {
        profileNav = param;
        setProfileActive(param);
        if (param !== 'activity') {
            getNFTs(profileData);
        }
    }

    function handleSearch(e) {
        searchValue = e.target.value;
        if (e.key === "Enter") {
            getNFTs(profileData);
        }
        //setSearch(searchValue);
    }

    function mobileFilter() {
        setMobileFilter(showMobileFilter ? false : true);
        showMobileFilter ? document.body.classList.remove('noScroll') : document.body.classList.add('noScroll');
        console.log(showMobileFilter);
    }

    const colorShade = (col, amt) => { //figure out how to resuse instead of copy and pasting
        col = col.replace(/^#/, '')
        if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]
    
        let [r, g, b] = col.match(/.{2}/g);
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
    
        r = Math.max(Math.min(255, r), 0).toString(16)
        g = Math.max(Math.min(255, g), 0).toString(16)
        b = Math.max(Math.min(255, b), 0).toString(16)
    
        const rr = (r.length < 2 ? '0' : '') + r
        const gg = (g.length < 2 ? '0' : '') + g
        const bb = (b.length < 2 ? '0' : '') + b
    
        return `#${rr}${gg}${bb}`
    }

    var accountstr = [];
    if (profileData.length !== 0) {
        console.log(profileData);
        var color = profileData.accentColor;
        var darkColor = colorShade(color, -120);
        const beginningstr = profileData.address.substring(0,6);
        const endstr = profileData.address.slice(profileData.address.length - 4);
        accountstr = beginningstr + '...' + endstr;
        jQuery("#profilebackground").css("opacity", "1");
    }
    
    function copyUrl() {
        if (copyLink() === true) {
            setCopiedUrl(true);
        }
    }

    function copyUrlOut() {
        setCopiedUrl(false);
    }

    var sharedropdown = document.getElementById('sharedropdown');

    return (
        <div className="profileMain">
            {getUser &&
                <SiteHelmet TITLE={`${profileData.displayName} on Cineteria`} CANONICAL={`${Config.SITE_DOMAIN}/${profileData.displayName}`} DESC={`See ${profileData.displayName} and all of their music collectables on Cineteria!`} IMAGE={profileData.profilePic} />
            }
            {!getUser &&
                <SiteHelmet TITLE={`Cineteria - Profile`} CANONICAL={`${Config.SITE_DOMAIN}/profile`} DESC={`View or edit your profile and all of your music collectables!`} />
            }
            <div className="popup" id="changeinfopopup">
                <div className="popupoverlay"></div>
                <div className="popupcontent">
                    <h3>Before you get started 🎵</h3>
                    <p>In order to help more people find you, we suggest that you enter your profile name and information now. Especially if you are an established artist. Without one, your display name will be your Ethereum Address.</p>
                    <div className="d-flex flex-row">
                        <button className="popupbutton" onclick="changeinfoYes()">Okay!</button>
                        <button className="popupbutton" onclick="changeinfoNo()">Not right now.</button>
                    </div>
                </div>
            </div>

            <div className="profilehead">
                <div className="profilebackground" id="profilebackground">
                    <div className="headerbuttonsgrid">
                        <div className="headerbuttonsflex">
                            <div className="headerbuttonscontainer">
                                {(props.isLoggedIn) && (props.user.address === profileData.address) && 
                                <Link className="headerbutton" to="/profile/settings">
                                    <div className="d-flex">
                                        <i className="material-icons">settings</i>
                                    </div>
                                </Link>
                                }
                                <Tippy placement="bottom" interactive={true} interactiveBorder={5} arrow={false} theme='transparent' appendTo={document.body} trigger='click' offset={[0, 0]} animation='shift-away' onHidden={copyUrlOut} content={
                                    <div className="sharedropdown" id="sharedropdown">
                                        <ul className="sharelist">
                                            <li>
                                                <div className="shareitem" id="copyurl" onClick={copyUrl}>
                                                    <img src={cineteriaLogo} />
                                                    <span id="copyurltext">{copiedUrl ? 'Copied!' : 'Copy Link'}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <a href={window.userAddress === profileData.address ? `https://twitter.com/intent/tweet?text=Check%20out%20me%20and%20my%20NFTs%20on%20the%20Cineteria%20Marketplace%21%20https%3A%2F%2Fcineteria.com%2F${profileData.ref}%20via%20%40cineteria` : `https://twitter.com/intent/tweet?text=Check%20out%20${profileData.ref}%20and%20their%20NFTs%20on%20the%20Cineteria%20Marketplace%21%20https://cineteria.com/${profileData.ref}%20via%20%40cineteria`} rel="nofollow noopener" target="_blank" id="sharetwitter">
                                                    <div className="shareitem">
                                                        <img src={twitter} />
                                                        <span>Share on Twitter</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={`https://www.facebook.com/sharer/sharer.php?u=https://cineteria.com/"${profileData.ref}`} rel="nofollow noopener" target="_blank" id="sharefacebook">
                                                    <div className="shareitem">
                                                        <img src={facebook} />
                                                        <span>Share on Facebook</span>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                }>
                                    <button className="headerbutton" id="sharebutton">
                                        <div className="d-flex">
                                            <i className="material-icons">share</i>
                                        </div>
                                    </button>
                                </Tippy>
                            </div>
                        </div>
                    </div>
                    <div id="profileinfo">
                        <div className="profileinfo">
                            <div className="imagediv">
                                <div className="imageinputcontainer">
                                    <input accept="image/*" type="file" autocomplete="off" tabindex="-1"/>
                                    <div aria-hidden="true" className="imageinputoverlay">
                                        <div className="imageinputoverlayflex">
                                            <i color="white" value="edit" size="24" className="editprofileimageicon">edit</i>
                                        </div>
                                    </div>
                                </div>
                                <div className="profileimagecontainer" id="profileimagecontainer" style={{backgroundImage: `linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, ${color}, ${darkColor})`}}>
                                    {imgLoaded === false &&
                                        <div className="profilepicture loadingNFT"/>
                                    }
                                    <img src={!imgError ? profileData.profilePic : defaultProfile} className="profilePic" style={imgLoaded ? {} : {display: 'none'}} onLoad={handleImgLoad} onError={handleImgError}/>
                                </div>
                            </div>
                            <div className="profilenamecontainer">
                                <div className="profilename" id="profilename">
                                    {profileData.displayName}
                                </div>
                                {profileData.verified === true &&
                                    <div className="verificationbadge">
                                            <img src={verifiedCheck}/>
                                    </div>
                                }
                            </div>
                            <div className="profileaddresscontainer">
                                <button className="addresscopybutton" type="button">
                                    <CopyAddress accountstr={accountstr} profileData={profileData} />
                                    {/* <Tippy theme='white-bold' animation='scale-subtle' placement='bottom' onHidden={outCopy} hideOnClick={false} content={copied ? 'Copied!' : 'Click to Copy Address'}>
                                        <div className="addresstext" id="userAddress" style={copied ? {color: '#00ffcd'} : {}} onClick={() => copyAddress(profileData.address)}>
                                            {accountstr}
                                        </div>
                                    </Tippy> */}
                                </button>
                            </div>
                            <div className="profilebiodiv">
                                <div className="profilebio">
                                    <p id="profilebio" className="profilebiotext">{profileData.bio}</p>
                                </div>
                            </div>
                            <div className="joindate">
                                Joined {profileData.dateJoined}
                            </div>
                        </div>
                        <ul className="profilenav" direction="horizontal">
                            <li className="profilenavbutton">
                                <a direction="horizontal" className={`profilenavlink ${profileActive === 'owner' ? 'active' : ''}`} onClick={() => handleProfileNav('owner')}>
                                    <i className="profilenavicon" value="grid_on" size="24">grid_on</i>
                                    <span className="profilenavtext">Owned</span>
                                    <div className="profilenavcountcontainer">
                                        <span className="profilenavcount">{profileData.ownedCount}</span>
                                    </div>
                                </a>
                            </li>
                            <li className="profilenavbutton">
                                <a direction="horizontal" className={`profilenavlink ${profileActive === 'creator' ? 'active' : ''}`} onClick={() => handleProfileNav('creator')}>
                                    <i className="profilenavicon" value="create" size="24">create</i>
                                    <span className="profilenavtext">Created</span>
                                    <div className="profilenavcountcontainer">
                                        <span className="profilenavcount">{profileData.createdCount}</span>
                                    </div>
                                </a>
                            </li>
                            <li className="profilenavbutton">
                                <a direction="horizontal" className={`profilenavlink ${profileActive === 'favorites' ? 'active' : ''}`} onClick={() => handleProfileNav('favorites')}>
                                    <i className="profilenavicon" value="favorite" size="24">favorite</i>
                                    <span className="profilenavtext">Favorites</span>
                                    <div className="profilenavcountcontainer">
                                        <span className="profilenavcount">{profileData.likesCount}</span>
                                    </div>
                                </a>
                            </li>
                            <li className="profilenavbutton">
                                <a direction="horizontal" className={`profilenavlink ${profileActive === 'activity' ? 'active' : ''}`} onClick={() => handleProfileNav('activity')}>
                                    <i className="profilenavicon" value="activity" size="24">swap_calls</i>
                                    <span className="profilenavtext">Activity</span>
                                    <div className="profilenavcountcontainer">
                                        <span className="profilenavcount"></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="navresultsblock">
                    <div className="material-icons mobilefilterbutton" id="mobilefilter" onClick={mobileFilter}>filter_alt</div>
                    <div className="navresultscontainer">
                        <div className="navresultsflex">
                            <Filter getNFTs={(sortArgs) => getNFTs(profileData, sortArgs)} masterReset={masterReset} isMasterReset={true} show={showMobileFilter} mobileFilter={mobileFilter} />
                            {profileNav !== 'activity' && 
                            <NFTs/>
                            }
                            {profileNav === 'activity' && 
                            <UserActivity/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function NFTs() {
        return (
            <div className="assetresults">
                <div className="assetresultsheader">
                    <div className="searchcontainer">
                        <div className="searchiconcontainer" onClick={() => getNFTs(profileData)}>
                            <i className="material-icons">search</i>
                        </div>
                        <input placeholder="Search" className="searchinput" id="searchstring" defaultValue={searchValue} onKeyUp={handleSearch}/>
                    </div>
                </div>
                <div className="assetmaster" id="assetcardmaster">
                {isLoaded === false && 
                <img className="cineteriaLoading" src={loadingImg} />
                }
                {!nftData.noNFTs && isLoaded === true && Object.keys(nftData).map((nft, key) =>
                        <NFTCard data={nftData[nft]} />
                )}
                {nftData.noNFTs && isLoaded === true &&
                <p className="noNFTs">No NFTs found.</p>
                }
                </div>
            </div>
        )
    }

    function UserActivity() {
    const [userActivity, setUserActivity] = useState('');
        useEffect(() => {
            getUserActivity();
            async function getUserActivity() {
                var userData = {
                    'getUser': getUser,
                    'userAddress': window.userAddress !== '' ? window.userAddress : '',
                }
    
                var activity = await jQuery.ajax({
                    url: `${API_ENDPOINT}useractivity.php`,
                    type: 'POST',
                    data: userData,
                });
    
                setUserActivity(JSON.parse(activity));
            }
        }, [])
        
        console.log(userActivity);
        return (
            <div className="itemactivityframe">
                <div className="itemactivitycontainer">
                    <div className="panelcontainer">
                        <div className="basepanelcontainer">
                            <header className="basepanelheader">
                                <i className="material-icons panelicon">swap_vert</i>
                                <span>User Activity</span>
                                <i className="material-icons togglepanel">expand_less</i>
                            </header>
                            <div className="basepanelbody">
                                <div className="panelcontentcontainer">
                                    <div className="assethistorypanel">
                                        <div className="assethistoryfilters">

                                        </div>
                                        <div className="scrollboxcontainer">
                                            <div className="scrollboxcontent" id="nftactivity"><div className="rowheadercontainer" role="row">
                                                <div className="spacedcellevent rowcell">
                                                    Event
                                                </div>
                                                <div className="spacedcellprice rowcell">
                                                    Item
                                                </div>
                                                <div className="spacedcellprice rowcell">
                                                    Price
                                                </div>
                                                <div className="rowcell cellspaced">From</div>
                                                <div className="rowcell cellspaced">To</div>
                                                <div className="rowcell cellspaced">Date</div>
                                            </div>
                                            {userActivity !== '' && 
                                                Object.keys(userActivity).map((event, key) =>
                                                    <ActivityRow event={userActivity[event]} />
                                                )
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ActivityRow(props) {
        const [imgLoaded, setImgLoaded] = useState(false);
        const [imgError, setImgError] = useState(false);

        function handleLoad() {
            if (imgLoaded === false) {
                setImgLoaded(true);
            }
          }
      
          function handleImgError() {
            if (imgError === false) {
              setImgError(true)
            }
          }

        props = props.event;
        return(
            <div className="activityMaster" role="row">
                <div className="rowcell cellspaced spacedcellevent">
                    <a href={props.url} className="eventLink" target="_blank">
                        <i className="eventcellicon material-icons">{props.icon}</i>
                        <span>{props.text}</span>
                    </a>                
                </div>
                <div className="rowcell cellspaced">
                    <Link to={`/${props.nft.contractAddress}/${props.nft.tokenId}`} className="eventNFT">
                        <div className="eventNFTImageContainer">
                            {(imgLoaded === false || imgError) && 
                                <div className="loadingNFT"/>
                            }
                            {imgError === false &&
                                <img className="eventNFTImage" src={props.nft.image} onError={handleImgError} onLoad={handleLoad} style={imgLoaded ? {} : {display:'none'}} />
                            }
                        </div>
                        <div className="eventNFTInfo">
                            <div className="eventNFTName">
                                {props.nft.name}
                            </div>
                            <div className="eventNFTCreator">
                                {props.nft.creator.name}
                                {props.nft.creator.verified === true &&
                                    <img className="eventNFTVerified" src={verifiedCheck} />
                                }
                            </div>
                        </div>
                    </Link>                
                </div>
                <div className="rowcell cellspaced">
                    <div className="displayflex">
                        <div className="rowpricecontainer">
                            <div cursor="pointer">
                                {props.price !== '' &&
                                <a className="styledlink" href={props.ETHLink} rel="nofollow noopener" target="_blank">
                                    <div className="rowethimgcontainer">
                                        <img className="rowethimg" src={cryptoImages[props.ETHIcon]} />
                                    </div>
                                </a>
                                }
                            </div>
                            <div className="rowpriceamount">
                                {props.price}
                                <span className="pricesymbol"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rowcell cellspaced">
                    <div className="ownernameflex">
                        <Link className="styledlink ellipsisoverflow" to={`/${props.from.name}`}>
                            <div className="d-flex flex-row align-items-center">
                            <span className="activityname">{props.from.name}</span>
                            {props.from.verified === true && 
                                <img className="activityverified" src={verifiedCheck} />
                            }
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="rowcell cellspaced">
                    <div className="ownernameflex">
                        <Link className="styledlink ellipsisoverflow" to={`/${props.to.name}`}>
                            <div className="d-flex flex-row align-items-center">
                            <span className="activityname">{props.to.name}</span>
                            {props.to.verified === true && 
                                <img className="activityverified" src={verifiedCheck} />
                            }
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="rowcell cellspaced">
                    <div className="ellipsisoverflow" id="eventtimestamp">
                        <span aria-expanded="false">{props.date}</span>
                    </div>
                </div>
            </div>
        )
    }

}

export default Profile;