import React, {useEffect, useState} from 'react';
import SiteHelmet from "../components/siteHelmet";
import Config from "../Config.json";
import jQuery from 'jquery';
import '../css/login.css';
import { checkPassword } from '../js/functions';
import CustomButton from '../components/custombutton';
import Tippy from '@tippyjs/react';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const MANAGED_INFURA = process.env.REACT_APP_MANAGED_INFURA;

//const TITLE = Config.SITE_TITLE + "Sign up with your email on Cineteria";
const TITLE = "Sign up with your email on Cineteria";
const DESC = "Make an account on Cineteria to sell your music as digital collectables!";
const CANONICAL = Config.SITE_DOMAIN + "/signup";

export default function Signup(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setComfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [error, setError] = useState('');
    const [requirements, setRequirements] = useState({});
    const [loading, setLoading] = useState(false);

    

  return (
    <div className='loginmain'>
        <SiteHelmet TITLE={TITLE} CANONICAL={CANONICAL} DESC={DESC} />
        <div className='logincontent'>
            <div className="titleblock">
                <h2>Sign up with your email</h2>
                <h4>You'll use this email to access your account from anywhere.</h4>
            </div>
            <div className={`emailLoginForm ${passwordError ? 'passwordError' : ''} ${emailError ? 'emailError' : ''}`}>
                <input type="email" placeholder="Email" disabled={loading} onKeyUp={(e) => handleEmail(e)} />
                <Tippy visible={error === 'unsecure'} theme='dark' arrow={true} animation='shift-away' content={
                    <div className='passwordSecureTippy'>
                        <div className="error" id="error">
                        <div>
                            Password must:
                            <ul>
                                <li className={`${requirements.length ? 'correct' : ''}`}>Be longer than 8 characters</li>
                                <li className={`${requirements.upperCase ? 'correct' : ''}`}>Have a capital letter</li>
                                <li className={`${requirements.lowerCase ? 'correct' : ''}`}>Have a lowercase letter</li>
                                <li className={`${requirements.numbers ? 'correct' : ''}`}>Have at least one number</li>
                                <li className={`${requirements.symbols ? 'correct' : ''}`}>Have at least one symbol</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                }>
                    <input type="password" placeholder="Password" disabled={loading} onKeyUp={(e) => handlePassword(e)} />
                </Tippy>
                <input type="password" placeholder="Confirm Password" disabled={loading} onKeyUp={(e) => handleConfirmPassword(e)} />
                <CustomButton loading={loading} function={handleSignup} disabled={error !== '' || loading}>Sign up</CustomButton>
                {/* <button onClick={handleSignup} disabled={loading}>Signup</button> */}
            </div>
            <div className="error" id="error">
                    {/* {error === 'unsecure' &&
                    <>
                    <div>
                        Password must:
                        <ul>
                            <li className={`${requirements.length ? 'correct' : ''}`}>Be longer than 8 characters</li>
                            <li className={`${requirements.upperCase ? 'correct' : ''}`}>Have a capital letter</li>
                            <li className={`${requirements.lowerCase ? 'correct' : ''}`}>Have a lowercase letter</li>
                            <li className={`${requirements.numbers ? 'correct' : ''}`}>Have at least one number</li>
                            <li className={`${requirements.symbols ? 'correct' : ''}`}>Have at least one symbol</li>
                        </ul>
                    </div>
                    </>
                    } */}
                    {error !== 'unsecure' &&
                        error
                    }
                    
                    {/* {props.error !== '' && 
                        <p>{props.error} If this continues, please <Link to="/contact">contact us.</Link></p>
                    } */}
            </div>
        </div>
    </div>
  )

    function handleEmail(e) {
        console.log(e.target.value);
        setEmail(e.target.value);
        if (e.target.value === '') {
            setError('You must enter an email.');
            setEmailError(true);
        } else {
            setError('');
            setEmailError(false);
        }
    }

    function handlePassword(e) {
        console.log(e.target.value);
        setPassword(e.target.value);
        var isSecure = checkPassword(e.target.value, true)
        if (isSecure !== true) {
            console.log(isSecure);
            setError('unsecure');
            setPasswordError(true);
            setRequirements(isSecure);
        } else {
            if (confirmPassword !== e.target.value) {
                setError('Passwords do not match.');
                setPasswordError(true);
            } else {
                setError('');
                setPasswordError(false);
            }
        }
    }

    function handleConfirmPassword(e) {
        console.log(e.target.value);
        setComfirmPassword(e.target.value);
        var isSecure = checkPassword(password, true)
        if (isSecure !== true) {
            console.log(isSecure);
            setError('unsecure');
            setPasswordError(true);
            setRequirements(isSecure);
            return;
        } else if (password !== e.target.value) {
            setError('Passwords do not match.');
            setPasswordError(true);
        } else if (e.target.value === '') {
            setError('Password is required.');
            setPasswordError(true);
        } else {
            setPasswordError(false);
            if (email === '') {
                setError('You must enter an email.');
                setEmailError(true);
            } else {
                setError('');
            }
        }
    }

    async function handleSignup() {
        if (loading) {
            return;
        }
        setLoading(true);
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setPasswordError(true);
            setLoading(false);
            return;
        } else {
            if (password === '' && confirmPassword === '') {
                setError('Password is required.');
                setPasswordError(true);
                setLoading(false);
                return;
            } else {
                var isSecure = checkPassword(password, true)
                if (isSecure !== true) {
                    console.log(isSecure);
                    setError('unsecure');
                    setPasswordError(true);
                    setRequirements(isSecure);
                    setLoading(false);
                    return;
                } else {
                    setError('');
                    setPasswordError(false);
                }
            }
        }

        var userData = {
            'email': email,
            'password': password,
            'confirmPassword': confirmPassword,
        }


        console.log("Signing up");
        var data = await jQuery.ajax({
            url: `${API_ENDPOINT}signup.php`,
            type: 'POST',
            data: userData,
            xhrFields: {
                withCredentials: true,
            },
        })
        console.log(data)
        data = JSON.parse(data);
        if (data.error) {
            setError(data.error);
            if (data.error === "Not a valid email." || data.error === "You must enter an email." || data.error === "There is already an account with that email address.") {
                setEmailError(true);
            } else {
                setPasswordError(true);
            }
        } else {
            window.managed = true;
            props.enableWeb3(MANAGED_INFURA);
        }
        setLoading(false);
    }

}
