import React from 'react';
import {Helmet} from "react-helmet";
import Config from "../Config.json";
import '../css/contact.css';

const TITLE = Config.SITE_TITLE + " - Contact";
const DESC = "Contact Cineteria";
const CANONICAL = Config.SITE_DOMAIN + "/contact";

export default function Contact() {
  return (
    <div className='contactMaster'>
            <Helmet>
                <title>{TITLE}</title>
                <link rel="canonical" href={CANONICAL}/>
                <meta name="description" content={DESC}/>
                <meta name="theme-color" content={Config.THEME_COLOR}/>
            </Helmet>
        <a href="http://contact@cineteria.com" className='email'>
            contact@cineteria.com
        </a>
        <div className='iconsList'>
            <a href="https://instagram.com/cineteria" target="_blank" className='contactIcon'>
                <i className='fa-brands fa-instagram'/>
            </a>
            <a href="https://youtube.com/cineteria" target="_blank" className='contactIcon'>
                <i className='fa-brands fa-youtube'/>
            </a>
            <a href="https://twitter.com/cineteria" target="_blank" className='contactIcon'>
                <i className='fa-brands fa-twitter'/>
            </a>
            <a href="https://facebook.com/cineteria" target="_blank" className='contactIcon'>
                <i className='fa-brands fa-facebook'/>
            </a>
            <a href="https://discord.gg/nUnRGsbb5T" target="_blank" className='contactIcon'>
                <i className='fa-brands fa-discord'/>
            </a>
            <a href="https://telegram.org/cineteria" target="_blank" className='contactIcon'>
                <i className='fa-brands fa-telegram'/>
            </a>
        </div>
    </div>
  )
}
