import React, {useState, useEffect} from 'react';
import NFTCard from './nftcard';
import User from './user';
import jQuery from 'jquery';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function Gifts(props) {
    const [currentGift, setCurrentGift] = useState(0);
    const [show, setShow] = useState(true);
    var gifts = props.gifts;

    function handleGifts() {
        if (currentGift !== gifts.length - 1) {
            setCurrentGift(currentGift + 1);
        } else {
            setShow(false);
            var userData = {
                'userAddress': window.userAddress,
                'method': 'gift',
                'id': props.giftId,
            }
            var data = jQuery.ajax({
                url: `${API_ENDPOINT}seen.php`,
                type: 'POST',
                data: userData,
            })
        }
    }

  return (
    <div className={`giftMaster ${show ? 'show' : ''}`}>
        <div className='overlay'/>
        <div className='giftModal'>
            <div className='giftContent'>
                <div className='giftHeader'>
                    You have {gifts.length} new gift{gifts.length > 1 ? 's' : ''}!
                </div>
                <div class='giftFrom'>
                    From:
                    <div className='giftFromUser'>
                        <User data={gifts[currentGift].user} />
                    </div>
                </div>
                <div className='giftMain'>
                    <NFTCard data={gifts[currentGift].nft} />
                </div>
                <div className='nextButton' onClick={handleGifts}>{currentGift === gifts.length - 1 ? 'Next' : 'Next Gift'}</div>
            </div>
        </div>
    </div>
  )
}
