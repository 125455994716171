import { ErrorResponse } from '@coinbase/wallet-sdk/dist/relay/Web3Response';
import jQuery from 'jquery';
import { Suspense } from 'react';

//Copy Address
export function copy(value) {
    /* Get the text field */
    var copyText = value;

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText);
}

export function copyLink(ref = null) {
    if (window.location.href == 'https://cineteria.com/profile/' || window.location.href == 'https://www.cineteria.com/profile/') {
        var copyUrl = 'https://cineteria.com/' + ref;
    } else {
        var copyUrl = window.location.href;
    }

    navigator.clipboard.writeText(copyUrl);

    return true;
}

export function isAddress(address) {
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
        return false;
    } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
        return true;
    } else {
        return true;
    }
}

export function notify(notif) {
    unnotify();
    document.getElementById(notif).classList.add("active");
    //document.getElementById(notif).classList.toggle("active");
}

export function unnotify(notif) {
    if (notif == null) {
        document.querySelectorAll('.popup').forEach(item => {
            item.classList.remove("active");
        });
    } else {
        document.getElementById(notif).classList.remove("active");
    }
}

export function switchMATIC() {
    if (document.getElementById("switchMATIC")) {
        var switchbttn = document.getElementById("switchMATICbttn")
        unnotify();
        notify('switchMATIC');
        switchbttn.removeEventListener("click", event => {});
        switchbttn.addEventListener('click', event => {
            switchbttn.disabled = true;
            switchChain('0x89');       
        })
    }
}

export function switchETH() {
    if (document.getElementById("switchETH")) {
        var switchbttn = document.getElementById("switchETHbttn")
        switchbttn.disabled = false;
        unnotify();
        notify('switchETH');
        switchbttn.removeEventListener("click", event => { });
        switchbttn.addEventListener('click', event => {
            switchbttn.disabled = true;
            switchChain('0x1');
        })
    }
}

async function switchChain(chainId) {
    var ethereum = window.ethereum;
    await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }]
    })
    .then((data) => {
        if (chainId == '0x89') {
            if (document.getElementById("switchMATIC")) {
                unnotify();
            }
        } else if (chainId == '0x1') {
            if (document.getElementById("switchETH")) {
                unnotify('switchETH');
            }
        }
    })
    .catch((error) => {
        if (error.code === 4001) {
            if (chainId == '0x89') {
                if (document.getElementById("switchMATIC")) {
                    document.getElementById("switchMATICbttn").disabled = false;
                }
            } else if (chainId == '0x1') {
                if (document.getElementById("switchETH")) {
                    document.getElementById("switchETHbttn").disabled = false;
                }
            }
        } else if (error.code === 4902) {
            if (chainId == '0x89') {
                try {
                    addChain(chainId, 'Polygon Mainnet', 'MATIC', 18, ['https://polygon-rpc.com/'], ['https://polygonscan.com']);
                } catch (addError) {
                    console.error(addError);
                }
            }
        }
    })
}

async function addChain(chainId, chainName, currency, decimals, rpcUrls, explorerUrls) {
    var ethereum = window.ethereum;
    await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
            {
                chainId: chainId,
                chainName: chainName,
                nativeCurrency: {
                    symbol: currency,
                    decimals: decimals
                },
                rpcUrls: rpcUrls,
                blockExplorerUrls: explorerUrls,
            }
        ]
    })
    .then((data) => {
        if (document.getElementById("switchMATIC")) {
            unnotify();
        }
    })
    .catch((error) => {
        if (error.code === 4001) {
            if (document.getElementById("switchMATIC")) {
                document.getElementById("switchMATICbttn").disabled = false;
            }
        }
    })
}

export async function tradeRate(val, fixed = false, symbol = true) {
	var response = await jQuery.ajax({
		url: "https://api.coinbase.com/v2/exchange-rates?currency=ETH",
		type: 'GET',
	});

    var usdrate = response.data.rates.USD;
    var usd = Math.round(100 * (usdrate * val)) / 100;
    if (fixed) {
        usd = usd.toFixed(fixed);
    }
    if (symbol) {
	    return "$" + usd;
    } else {
        return usd;
    }
}

export function setupTransactionData(transactiondata, user) {
    if (user.managed) {
        var TransactionParameters = transactiondata;
    } else {
        var serializedTransaction = JSON.parse(transactiondata.serializedTransaction);
        if (serializedTransaction.value) {
            var TransactionParameters = {
                to: serializedTransaction.to,
                from: window.userAddress,
                data: serializedTransaction.data,
                value: serializedTransaction.value,
            };
        } else {
            var TransactionParameters = {
                to: serializedTransaction.to,
                from: window.userAddress,
                data: serializedTransaction.data,
            };
        }
    }
    return TransactionParameters;
}

export function checkPassword(password, all = false) {
    var weakPassword = false;
    if (password.length < 8) {
        var length = false;
        weakPassword = true;
    } else {
        var length = true;
    }
    var upperCase = /[A-Z]/.test(password);
    var lowerCase = /[a-z]/.test(password);
    var hasNumbers = /\d/.test(password);
    var hasSymbols = /\W/.test(password);
    if (upperCase + lowerCase + hasNumbers + hasSymbols < 4) {
        weakPassword = true;
    }
    if (!all) {
        return weakPassword ? false : true;
    } else {
        var errors = {length: length, upperCase: upperCase, lowerCase: lowerCase, numbers: hasNumbers, symbols: hasSymbols};
        return weakPassword ? errors : true;
    }
  }