import React from "react";
import SiteHelmet from "../components/siteHelmet";
import Config from '../Config.json';
import { Link } from "react-router-dom";
import '../css/404.css';

function NotFound() {
    return (
        <div className="notfoundmaster">
            <div className="flexbox">
                <h1>Oops! Looks like this isn't a page!</h1>
                <h2>If you typed something in the URL bar, make sure it is spelled correctly.</h2>
                <h3>If this should not be happening, don't hesitate to <a href="/contact">contact us here!</a></h3>
                <div className="buttonflex">
                    <Link to="../">
                        <button className="unstyledbutton" id="goback">Go Back</button>
                    </Link>
                    <Link to="/marketplace">
                        <button id="marketplace">Go to the Marketplace</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;