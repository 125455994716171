import React from 'react'
import {notify, unnotify} from '../js/functions';

export default function Popup(props) {
    return (
        <div className={`popup ${props.darkMode ? 'dark' : ''} ${props.active === props.name ? 'active' : ''}`} id={props.name}>
            <div className="popupoverlay"></div>
            <div className={`popupcontent ${props.maxWidth ? 'w-100' : ''}`}>
                {!props.customHeader && 
                <div className="popupheader">
                    <h3>{props.header}</h3>
                    {!props.noCancel &&
                    <div className="popupclose material-icons-outlined" onClick={() => unnotify(props.name)}>cancel</div>
                    }
                </div>
                }
                {props.customHeader && props.customHeader}
                {props.content}
            </div>
        </div>
    )
}
