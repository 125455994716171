import React, {useState, useEffect} from 'react';
import verifiedCheck from '../images/verified.png';
import defaultProfile from '../images/defaultProfile.png';

export default function User(props) {
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgError, setImgError] = useState(false);

    props = props.data;
    
    function handleImgLoad() {
        if (imgLoaded === false) {
            setImgLoaded(true);
        }
    }

    function handleImgError() {
        if (imgError === false) {
            setImgError(true);
        }
    }

    const colorShade = (col, amt) => {
        col = col.replace(/^#/, '')
        if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]
    
        let [r, g, b] = col.match(/.{2}/g);
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
    
        r = Math.max(Math.min(255, r), 0).toString(16)
        g = Math.max(Math.min(255, g), 0).toString(16)
        b = Math.max(Math.min(255, b), 0).toString(16)
    
        const rr = (r.length < 2 ? '0' : '') + r
        const gg = (g.length < 2 ? '0' : '') + g
        const bb = (b.length < 2 ? '0' : '') + b
    
        return `#${rr}${gg}${bb}`
    }

  return (
    <div className="usernavimg">
        <div className="profilepiccontainer" style={{backgroundImage: `linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, #${props.accentColor}, ${colorShade(props.accentColor, -120)})`}}>
            {imgLoaded === false &&
                <div className="profilepicture loadingNFT"/>
                //<img src={loadingImg} className="profilepicture" style={{backgroundColor:'#00ffcd'}} />
            }
            <img src={imgError ? defaultProfile : props.image} className="profilepicture" style={imgLoaded ? {} : {display: 'none'}} id="profilepic" onError={handleImgError} onLoad={handleImgLoad} />
        </div>
        <div className="usernameContainer">
            <span>{props.name}</span>
            {props.verified === true &&
            <img src={verifiedCheck} className="verified" />
            }
        </div>
    </div>
  )
}
