import React, {useState, useEffect, useRef} from "react";
import SiteHelmet from "../components/siteHelmet";
import Config from "../Config.json";
import jQuery from 'jquery';
import Web3 from 'web3-eth';
import Tippy from '@tippyjs/react';
import '../css/create.css';
import Popup from "../components/popup";
import { isAddress, notify, unnotify, switchETH, switchMATIC } from "../js/functions";
import { Navigate, useNavigate, Link } from "react-router-dom";
import ArtistSearch from "../components/artistSearch";
import whiteLoading from '../images/whiteLoading.gif';
import failedImg from '../images/failed.png';
import stopwatchImg from '../images/stopwatch.png';
import {customSelectInput} from '../js/customselectinput';
import imageUpload from '../images/imageUpload.png';
import polygonImg from '../images/polygon.png';
import ethImg from '../images/eth.png';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const TITLE = Config.SITE_TITLE + " - Create";
const DESC = "Use our creation studio to create all kinds of Digital Music Collectables for free!";
const CANONICAL = Config.SITE_DOMAIN + "/create";

var audioList = [];
var isError = false;
var staticErrors = {category: '', fileToUpload: '', audioupload: '', name: '', exlink: '', amount: '', artists: '', royalty: '', chain: '', submit: ''};
var errorNav = '';

function Create(props) {

    const [errors, setErrors] = useState({category: '', fileToUpload: '', audioupload: '', name: '', exlink: '', amount: '', artists: '', royalty: '', chain: '', submit: ''});
    const [previewImg, setPreviewImg] = useState(imageUpload);
    const [artistSearchVisible, setArtistSearchVisible] = useState(false);
    //const [isError, setIsError] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [loading, setLoading] = useState('');
    const [formProgress, setFormProgress] = useState('');
    const [createState, setCreateState] = useState('Uploading Data');
    const [image, setImage] = useState(''); //setting the default value as an array messes things up and the default image value becomes an array of the fileList, so we use a default string, which is equal to 'undefined'
    //const artiststippy = useRef(null);
    const [customSelect, setCustomSelect] = useState(false);
    const [nftNav, setNFTNav] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (customSelect === false) {
            customSelectInput();
            setCustomSelect(true);
        }
    }, [])

    function handleInput(input) { //If passing a parameter in onClick or onChange has to be like () => FUNCTION(PARAM), need that paranthases with arrow function
        //Erase errors if there is errors
        if (errors.input !== '') {
            unerror(input);
        }
    }

    function handleImage(e) {
        error("fileToUpload", '');
        var fileToUpload = e.target;
        if (fileToUpload.files.length > 0) {
            var selectedImage = fileToUpload.files[0];
            var validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (selectedImage) { //we use selected image because the setImage varible isn't instantly avaible to use since it has to refresh first, and that's why it works on the next image select, and why its always delayed by 1 selection
                const fileType = selectedImage['type'];
                if (jQuery.inArray(fileType, validImageTypes) > 0) {
                    setImage(selectedImage); //this needs to be here because of image validation check
                    setPreviewImg(URL.createObjectURL(selectedImage));
                } else {
                    error("fileToUpload", "Image must be a JPG, PNG, or GIF.");
                }
            }
        }
    }

    function handleAudio(e) {
        var audioupload = e.target;
        const audios = audioupload.files
        var validAudioTypes = ['audio/mp3', 'audio/wav', 'audio/ogg', 'audio/mpeg'];
        if (audios) {
            Array.from(audios).forEach(audio => {
                let audioType = audio['type'];
                if (jQuery.inArray(audioType, validAudioTypes) > 0) {
                    error('audioupload', '');
                    let index = audio.name;
                    audioList[audio.name] = audio;
                    console.log(audioList);
                    let audiosize = Number((audio.size / 1000000).toFixed(2));
                    let mb = ' (' + audiosize + ' MB' + ')';
                    let li = document.createElement("li");
                    li.innerHTML = audio.name + mb;
                    document.getElementById("audioListContainer").appendChild(li);
                    let del = document.createElement("i");
                    del.innerHTML = "clear";
                    del.classList.add("material-icons");
                    li.appendChild(del);
                    var audioTooBig = false;
                    Object.keys(audioList).forEach((audiocheck) => { //Check if another audio is too big
                        if (audioList[audiocheck].size > 10000000) {
                            error('audioupload', 'One or more of your audios is over 10MB. Please choose smaller audios.');
                            audioTooBig = true;
                        } else {
                            if (audioTooBig === false) {
                                error('audioupload', '');
                            }
                        }
                    })
                    e.target.value = ''; //wouldn't allow users to add the same files again if they deleted them from the UI, since the "value" was the same and we are using "onChange", the "value" was the same. Wasn't a "multiple" problem
                    del.addEventListener("click", event => {
                        li.remove();
                        delete audioList[index];
                        console.log(audioList);
                        if (Object.entries(audioList).length === 0) {
                            error('audioupload', '');
                        }
                        audioTooBig = false;
                        Object.keys(audioList).forEach((audio) => {
                            if (audioList[audio].size > 10000000) {
                                error('audioupload', 'One or more of your audios is over 10MB. Please choose smaller audios.');
                                audioTooBig = true;
                            } else {
                                if (audioTooBig === false) {
                                    error('audioupload', '');
                                }
                            }
                        })
                    })
                } else {
                    error('audioupload', 'Please choose a valid file type.');
                }
            })
            
        }
    }

    function dropHandler(e) {
        e.preventDefault();
        const audios = e.dataTransfer.files;
        var validAudioTypes = ['audio/mp3', 'audio/wav', 'audio/ogg', 'audio/mpeg'];
        if (audios) {
            Array.from(audios).forEach(audio => {
                let audioType = audio['type'];
                if (jQuery.inArray(audioType, validAudioTypes) > 0) {
                    let index = audio.name;
                    audioList[audio.name] = audio;
                    let audiosize = Number((audio.size / 1000000).toFixed(2));
                    let mb = ' (' + audiosize + ' MB' + ')';
                    let li = document.createElement("li");
                    li.innerHTML = audio.name + mb;
                    document.getElementById("audioListContainer").appendChild(li);
                    let del = document.createElement("i");
                    del.innerHTML = "clear";
                    del.classList.add("material-icons");
                    li.appendChild(del);
                    var audioTooBig = false;
                    Object.keys(audioList).forEach((audiocheck) => { //Check if another audio is too big
                        if (audioList[audiocheck].size > 10000000) {
                            error('audioupload', 'One or more of your audios is over 10MB. Please choose smaller audios.');
                            audioTooBig = true;
                        } else {
                            if (audioTooBig === false) {
                                error('audioupload', '');
                            }
                        }
                    })
                    del.addEventListener("click", event => {
                        li.remove();
                        delete audioList[index];
                        if (Object.entries(audioList).length === 0) {
                            error('audioupload', '');
                        }
                        audioTooBig = false;
                        Object.keys(audioList).forEach((audio) => {
                            if (audioList[audio].size > 10000000) {
                                error('audioupload', 'One or more of your audios is over 10MB. Please choose smaller audios.');
                                audioTooBig = true;
                            } else {
                                if (audioTooBig === false) {
                                    error('audioupload', '');
                                }
                            }
                        })
                    })
                }
            })
    
        }
    }
    
    function dragOverHandler(e) {
        e.preventDefault();
    }
    
    function dragLeaveHandler(e) {
        e.preventDefault();
    }

    function handleArtists(e) {
        var artistsInput = e.target;
        let artist = artistsInput.value.split(',');
        let focusedArtist = artist[artist.length - 1];

        let data = {
            'artists': focusedArtist
        }

        jQuery.ajax({
            url: `${API_ENDPOINT}searchartists.php`,
            type: 'POST',
            data: data,
            success: function (response) {
                var results = JSON.parse(response);
                console.log(results);
                if (results.userhtml == 0) {
                    //artiststippy.current.hide();
                    setArtistSearchVisible(false);
                } else {
                    //artiststippy.current.show();
                    setArtistSearchVisible(true);
                    var artistsresults = document.getElementById("artistsresults");
                    artistsresults.innerHTML = results.userhtml.join("");
                    document.querySelectorAll(".artistsresults").forEach(item => {
                        item.addEventListener("click", event => {
                            artist[artist.length - 1] = item.firstChild.innerHTML + ',';
                            artistsInput.value = artist.join(', ');
                            setArtistSearchVisible(false);
                        })
                    })
                }
            }
        })
    }


    async function handleFormSubmit(e) {
        e.preventDefault();
        if (mintRequirements() === false) {
            
            var done = false;
            Object.entries(staticErrors).forEach(entry => {
                const [key, error] = entry;
                if (done === false) {
                    if (error !== '') {
                        errorNav = key;
                        done = true;
                    }
                }
            })
            
            let element = document.getElementById(errorNav);
            let offset = 500;
            let bodyRect = document.body.getBoundingClientRect().top;
            let elementRect = element.getBoundingClientRect().top;
            let elementPosition = elementRect - bodyRect;
            let offsetPosition = elementPosition - offset;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });

            return;
        }

        var chain_MATIC = document.getElementById("chain_MATIC");
        var chain_ETH = document.getElementById("chain_ETH");

        console.log(props.web3);
        window.chainId = await props.web3.getChainId();

        //Check if Wallet is correct chain
        if (chain_MATIC.checked && window.chainId !== 137) {
            switchMATIC();
            // return;
        } else if (chain_ETH.checked && window.chainid !== 1) {
            switchETH();
            return;
        }
    
        //$("#submitbttn").attr("disabled", true);
        //$("#chain").attr("disabled", true);
        //$("#userAccount").val(window.userAddress[0]); //just append this
        setErrors({category: '', fileToUpload: '', audioupload: '', name: '', exlink: '', amount: '', artists: '', royalty: '', chain: '', submit: ''});
        staticErrors = {category: '', fileToUpload: '', audioupload: '', name: '', exlink: '', amount: '', artists: '', royalty: '', chain: '', submit: ''};
        setLoading('progressBar');
        jQuery("#connectingwallet").css({ 'opacity': '0', 'transform': 'scale(0)', 'transition': '0s' });
        document.getElementById('progresscont').removeAttribute("style");
        jQuery("#progressdiv").toggleClass("show");

        var formData = new FormData(e.target);
        formData.append('image', image);
        formData.append('userAddress', window.userAddress);
        if (Object.keys(audioList).length > 0) {
            var arrAudioList = Object.values(audioList);
            arrAudioList.forEach((audio, i) => {
                console.log(audio);
                formData.append('audioList[' + i + ']', audio);
            })
        }

        jQuery.ajax({
            xhr: function() {
                var xhr = new window.XMLHttpRequest();
                setCreateState('Uploading Data');
                notify('creatingNFT');
                xhr.upload.addEventListener("progress", function(evt) {
                    if (evt.lengthComputable) {
                        var percentComplete = (evt.loaded / evt.total) * 100;
                        setFormProgress(percentComplete);
                        //$("#progressbar").width(percentComplete + '%');
                        //$("#progressbar").html(Math.round(percentComplete) + '%');
                        if (percentComplete == 100) {
                        setCreateState('Creating NFT');
                        jQuery("#progresscont").css('display', 'none');
                        jQuery("#progressdiv").toggleClass("show");
                        jQuery("#connectingwallet").css({'opacity': '1', 'transform':'scale(1)', 'transition': '1.5s'});
                        }
                    }
                }, false);
                return xhr;
            },
            url: `${API_ENDPOINT}mintnft.php`,
            type: 'POST',
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            xhrFields: {
                withCredentials: true,
            },
            success: function (data) {
                console.log(data);
                try {
                    var parsed = JSON.parse(data);
                } catch (err) {
                    unnotify();
                    error("submit", "Internal System Error: Could not start transaction. Please try again.");
                    jQuery("#connectingwallet").css({ 'opacity': '0', 'transform': 'scale(0)', 'transition': '1.5s' });
                    jQuery.ajax({
                        url: `${API_ENDPOINT}reporterror.php`,
                        type: 'POST',
                        data: { 'error': err },
                        success: function (response) {
                            console.log('Succesfully Reported Error');
                        }
                    })
                };
            if (parsed.unval) {
                props.accountValidation();
                return;
            }
            var transactiondata = parsed.transactiondata;
            if (!props.user.managed) {
                var serializedTransaction = JSON.parse(transactiondata.serializedTransaction);
            }
            var nftdata = parsed.nftdata;
            setNFTNav(nftdata);

        if (!data.success) {
        setFormSubmitting(false);
        jQuery("#connectingwallet").css({'opacity': '0', 'transform':'scale(0)', 'transition': '1.5s'});
            if (parsed.errors.category) {
                error('category', parsed.errors.category);
            }

            if (parsed.errors.fileToUpload) {
                error('fileToUpload', parsed.errors.fileToUpload);
            }

            if (parsed.errors.audioupload) {
                error('audioupload', parsed.errors.audioupload);
            }

            if (parsed.errors.name) {
                error('name', parsed.errors.name);
            }

            if (parsed.errors.exlink) {
                error('exlink', parsed.errors.exlink);
            }
            
            if (parsed.errors.amount) {
                error('amount', parsed.errors.amount);
            }

            if (parsed.errors.artists) {
                error('artists', parsed.errors.artists);
            }

            if (parsed.errors.royalty) {
                error('royalty', parsed.errors.royalty);
            }

            if (parsed.errors.chain) {
                error('chain', parsed.errors.chain);
            }

            if (parsed.errors.NS) {
                error('submit', parsed.errors.NS);
                console.error(parsed.errors.NS);
            }

            if (parsed.errors) {
                //CHANGE TO SCROLL TO FIRST ERROR INSTEAD
                document.getElementById("categorylabel").scrollIntoView({behavior: 'smooth'});
            }

        } 
            if (props.user.managed) {
                var TransactionParameters = transactiondata;
            } else {
                var TransactionParameters = {
                    to: serializedTransaction.to,
                    from: window.userAddress,
                    data: serializedTransaction.data,
                };
            }
            console.log(TransactionParameters)
            doTransaction(TransactionParameters, nftdata);
        }
    })
    }

    function mintRequirements() {
        isError = false;
        //setIsError(false);
        var isArtistsError = false;
        var artistsError = [];
        var errorsNav = [];
        let category = document.getElementById("category");
        //let albumtype = document.getElementById("albumtype");
        let fileToUpload = document.getElementById("fileToUpload");
        let name = document.getElementById("name");
        let exlink = document.getElementById("exlink");
        let desc = document.getElementById("desc");
        let amount = document.getElementById("amount");
        let artists = document.getElementById("artists");
        var artistsInput = document.getElementById("artistsInput");
        let royalty = document.getElementById("royalty");
    
        if (category.value === 'select') {
            error("category", "Please select a category");
        } /*else if (category.value === 'album') {
            let albumtype = jQuery("input[type=radio][name=albumtype]:checked").val();
            if (albumtype !== 'normal' && albumtype !== 'gauntlet') {
                error("albumtype", "Incorrect Album Type.");
            }
        }*/ 
        if (typeof image === 'undefined' || image.length === 0) {
            error("fileToUpload", "An image is required.");
        } else if (fileToUpload.files['size'] > 10000000) {
            error("fileToUpload", "File is too big. Please choose a file under 10MB.");
        }
        if (Object.keys(audioList).length > 0) {
            Object.keys(audioList).forEach((audio) => {
                if (audioList[audio].size > 10000000) {
                    error("audioupload", "One or more of your audios is over 10MB. Please choose smaller audios.");
                }
            })
        }
        if (name.value == '') {
            error("name", "Name is required.");
        } else if (/^[a-zA-Z�-������������0-9-' ]*$/.test(name.value) == false) {
            error("name", "Only letters, numbers, letters with accents, and white space allowed.");
        }
        if (exlink.value) {
            try {
                let url = new URL(exlink.value);
            } catch (e) {
                error("exlink", "Invalid URL.")
            }
        }
        if (amount.value == '') {
            error("amount", "Amount must be at least 1.");
        } else if (amount.value <= 0) {
            error("amount", "Amount must be at least 1.");
        } else if (amount.value > 100) {
            error("amount", "You cannot mint more than 100 NFTs at a time. If there is a specific reason you need to, please <a target=\'_blank\' href=\'/contact\' style=\'color:#02b09f;font-weight:600;\'>contact us here.</a>");
        } /*else if (albumtype == 'gauntlet' && amount.value > 1) {
            error("amount", "You cannot mint multiple gauntlet NFTs at one time.");
        }*/
        if (artists.value) {
            let artistCheck = artists.value.split(',');
            artistCheck.forEach(artist => {
                if (artist == '') {
                    return;
                }
                artist = artist.trim();
                if (isAddress(artist) == false) {
                    isArtistsError = true;
                    artistsError.push(artist);
                }
            })
            if (isArtistsError == true) {
                var artistsString = artistsError.join(", ");
                error("artists", "The addresses \'" + artistsString + "\' are not valid Ethereum Addresses.");
            }
        }
        if (royalty.value > 50) {
            error("royalty", "Royalty percentage can not be above 50 percent.");
        }
        if (isError === true) {
            return false;
        }
    }

    async function doTransaction(TransactionParameters, nftdata){
        var chain = jQuery("input[type=radio][name=chain]:checked").val();
        try {
            if (!props.user.managed) {
                console.log(props.web3);
                console.log(TransactionParameters);
                var txHash = await props.web3.sendTransaction(TransactionParameters)
                .on('sent', function() {
                    setCreateState('Inititating Transaction');
                })
                .on('sending', function() {
                    setCreateState('Awaiting Wallet Confirmation');
                })
                .on('transactionHash', function() {
                    setCreateState('Processing Transaction');
                })
                .on('receipt', function(receipt) {
                    if (receipt.status === true) {
                        setCreateState('Uploading Data');
                        setFormSubmitting(false);
                        navigate(`/${nftdata[0]}/${nftdata[1]}`);
                    }
                })
                .on('error', function(error) {
                    console.error(error);
                    console.log(error.message);
                    console.log(error.name);
                    if (error.message.includes("Transaction was not mined within")) {
                        setCreateState("Transaction Taking Longer Than Usual");
                        //navigate(`/${nftdata[0]}/${nftdata[1]}`);
                    } else {
                        setCreateState('Transaction Failed');
                    }
                });
                //verifyTransaction(txHash, chain, nftdata);
                //notify('createComplete');
                // let nftRedirect = document.getElementById("nftredirect");
                // nftRedirect.addEventListener('click', event => {
                //     window.location.href = '/' + nftdata[0] + "/" + nftdata[1];
                // });
            } else {
                setCreateState('Processing Transaction');
                var i = 0;
                const interval = setInterval(async function() {
                    i++;
                    if (i > 600) {
                        setCreateState("Transaction Taking Longer Than Usual");
                        clearInterval(interval);
                    }
                    await props.web3.getTransactionReceipt(TransactionParameters, function(err, receipt) {
                        if (receipt) {
                            clearInterval(interval);
                            if (receipt.status === true) {
                                setCreateState('Uploading Data');
                                setFormSubmitting(false);
                                navigate(`/${nftdata[0]}/${nftdata[1]}`);
                            } else {
                                setCreateState('Transaction Failed');
                            }
                        }
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
            if (e.code === 4001) {
                unnotify();
            }
            //verifyTransaction(1, chain, nftdata);
            setFormSubmitting(false);
        }
    };

    // async function verifyTransaction(txId, chain, nftdata){
    
    //     var userData = {
    //         'txId': txId,
    //         'chain': chain,
    //         'nftdata': nftdata[0],
    //         'nftdata1': nftdata[1],
    //         'userAddress': window.userAddress[0],
    //     }
    
    //     jQuery.ajax({
    //         url: "https://api.cineteria.com/marketplace/txId.php",
    //         type: 'POST',
    //         data: userData,
    //         success: function(response) {
    //         }
    //     })
    // }

    function error(id, message) {
        setErrors( prevValues => {
            return {...prevValues, [id]: message}
        })
        staticErrors[id] = message;
        if (isError === false) {
            isError = true;
            //setIsError(true);
        }
    }

    function unerror(id) {
        setErrors( prevValues => {
            return {...prevValues, [id]: ''}
        })
        staticErrors[id] = '';
    }

    return (
        <div className="createMain">
            <SiteHelmet TITLE={TITLE} CANONICAL={CANONICAL} DESC={DESC} />
            <form id="nftform" action="mintnft.php" method="POST" enctype="multipart/form-data" onSubmit={handleFormSubmit} className="NFTForm">

    <label for="category" id="categorylabel"><h3>Category: <p className="requiredicon">*</p></h3></label>
    <p className="info">Select what your NFT is representing. This helps more people find your NFT! If none of these options match what you are creating, select "Other."</p>

    <div className="custom-select">
        <select id="category" name="category">
            <option value="select">Select</option>
            <option value="album">Album</option>
            <option value="ep">EP</option>
            <option value="single">Single</option>
            <option value="ticket">Ticket</option>
            <option value="collectable">Collectable</option>
            <option value="art">Art</option>
            <option value="other">Other</option>
        </select>
    </div><div id="category-group" className="error">{errors.category}</div><br/><br/>

    {/*Gauntlet Album*/}
    <div className="albumtype">
        <label for="albumtype"><h3>Album Type: <p className="requiredicon">*</p></h3></label>
        <p className="info">Select the Album type.</p>
        <input type="radio" name="albumtype" id="albumtype_normal" value="normal" className="chaininput" defaultChecked={true}/>
        <label for="albumtype_normal">
            <div className="d-inline-flex align-items-center chainselect">
                <span className="chaintitle">Normal</span>
            </div>
        </label>
        <p className="info">Normal Album, all songs in one NFT.<br/><br/></p>
        <input type="radio" name="albumtype" id="albumtype_gauntlet" value="gauntlet" className="chaininput"/>
        <label for="albumtype_gauntlet">
            <div className="d-inline-flex align-items-center chainselect">
                <span className="chaintitle">Gauntlet</span>
            </div>
        </label>
        <p className="info">Seperates each Album song into a seperate NFT, if an owner has all of the Album singles, they can combine them into one Album NFT.<br/><br/></p>
    </div>

    {/*File*/}
    <label for="fileToUpload"><h3>Image: <p className="requiredicon">*</p></h3></label>
    <p className="info">File types supported: JPG, PNG, GIF. Max File size: 10 MB. Aspect Ratio 1:1 recommended.</p>
    <input type="file" name="fileToUpload" id="fileToUpload" accept="image/png, image/gif, image/jpeg" className="displayNone" onChange={handleImage}/>
    <label for="fileToUpload" className="imagelabel" id="imagelabel"><img id="previewimg" className="previewimg" src={previewImg} alt="Uploaded Preview"/></label>
    <div id="file-group" className="error">{errors.fileToUpload}</div><br/><br/>

    {/*Audio Files*/}
    <label for="audioupload"><h3>Audios: <p className="optionaltext">(Optional)</p></h3></label>
    <p className="info">File types supported: MP3, WAV, OGG. Max File size: 10 MB each.<br/>Upload one or more audio files for your single, EP, or album NFT! If checked below, nobody can listen to these without having the NFT, so it's perfect for secrets. Completely optional. You don't need audio files for non-audio projects or pre-releases!</p>
    <input type="file" name="audioupload[]" id="audioupload" className="displayNone" onChange={handleAudio} multiple/>
    <div className="d-flex flex-column audioList" id="dropzone" onDrop={dropHandler} onDragOver={dragOverHandler} onDragLeave={dragLeaveHandler}>
        <ul id="audioListContainer">
        </ul>
        <div className="audioListadd">
            <label for="audioupload">
                <i className="material-icons">add</i>
            </label>
        </div>
    </div>
    <input type="checkbox" name="showAudios" id="showAudios" className="showAudiosCheck"/>
    <label for="showAudios">
        <p className="showAudiosTip">NFT's audios are only visible, playable, and downloadable by the NFT owner.</p>
    </label>
    <div id="audio-group" className="error">{errors.audioupload}</div><br/><br/>

    {/*Name*/}
    <label for="name"><h3>Name: <p className="requiredicon">*</p></h3></label>
    <p className="info">The name of your NFT.</p>
    <input type="text" name="name" id="name" onChange={() => handleInput('name')}/><br/>
    <div id="name-group" className="error">{errors.name}</div><br/><br/>

    {/*External Link*/}
    <label for="exlink"><h3>External Link: <p className="optionaltext">(Optional)</p></h3></label>
    <p className="info">This link will be included on the NFT's detail page. Users can click on this link to learn more about the item. Can be your own website page!</p>
    <input type="text" id="exlink" name="exlink" rows="5" cols="40" onChange={() => handleInput('exlink')}/><br/>
    <div id="exlink-group" className="error">{errors.exlink}</div><br/><br/>

    {/*Description*/}
    <label for="desc"><h3>Description: <p className="optionaltext">(Optional)</p></h3></label>
    <p className="info">The description will appear on the NFT's detail page, under the image. Describe to your buyers, your item and what you will receive.</p>
    <textarea id="desc" name="desc" className="desc" onChange={() => handleInput('desc')}></textarea><br/><br/><br/>

    {/*Amount*/}
    <label for="amount"><h3>Amount: <p className="requiredicon">*</p></h3></label>
    <p className="info">The number of copies that you want to mint. For example, you would use this for tickets. Items that have different properties than the rest will have to be minted by themselves.</p>
    <input type="number" name="amount" id="amount" min="1" defaultValue="1" size="50" className="numberbox" onChange={() => handleInput('amount')}/><br/><br/>
    <input type="checkbox" name="togglecounter" id="togglecounter" defaultChecked={true}/>
    <label for="togglecounter">
        <p className="tips">Show version counter in the name of each NFT. (Recommended)</p><br/>
    </label>
    <span className="darkgrey d-block info" >Example: myNFT 4/13. Only shows if the amount is more than 1.</span>
    <div id="amount-group" className="error">{errors.amount}</div><br/><br/>

    {/*Collaborators*/}
    <label for="artists"><h3>Collaborators: <p className="optionaltext">(Optional)</p></h3></label>
    <p className="info">The addresses of the featured/partnered artists on this project, will be shown on the details section. They each will be given a cut of the royalty if the option below is enabled. (Seperate each address with a comma)</p>
    <Tippy arrow={false} visible={artistSearchVisible} allowHTML={true} interactive={true} theme="transparent" maxWidth="none" offset={[0, -15]} trigger="manual" placement="bottom" content={
    <div className="d-flex flex-column searchdropdown" id="artistssearch">
        <ul className="searchusers" id="artistsresults">
        </ul>
    </div>
    }>
        {/* <ArtistSearch multiple={true} handleInput={() => handleInput('artists')} /> */}
        <input type="text" name="artists" id="artists" onKeyUp={handleArtists} onChange={() => handleInput('artists')}/>
    </Tippy><br/><br/>
    <input type="checkbox" name="toggleroyalty" id="toggleroyalty"/>
    <label for="toggleroyalty">
        <p className="tips">Share royalties with collaborators</p><br/>
    </label>
    <div id="artists-group" className="error">{errors.artists}</div><br/><br/>

    {/*Royalties*/}
    <label for="royalty"><h3>Royalties: <p className="optionaltext">(Optional)</p></h3></label>
    <p className="info">The royalty percentage taken from each transaction of the NFT. (Value as a percentage)</p>
    <div className="displayflex">
        <input className="d-flex royalty" type="number" name="royalty" id="royalty" min="0" max="50" defaultValue="0" step="0.1" onChange={() => handleInput('royalty')}/><br/>
        <div className="percentagecontainer">
            %
        </div>
    </div>
    <div id="royalty-group" className="error">{errors.royalty}</div><br/><br/>

    {/*Chain*/}
    <div>
        <label for="chain" id="chain-group"><h3>Chain: <p className="requiredicon">*</p></h3></label>
    </div>

    <input type="radio" name="chain" id="chain_MATIC" value="MATIC" className="chaininput" defaultChecked={true} disabled={formSubmitting ? true : false}/>
    <label for="chain_MATIC">
        <div className="d-inline-flex align-items-center chainselect">
            <span className="chaintitle">Polygon</span>
            <img src={polygonImg} className="chainimg"/>
        </div>
    </label>
    <p className="info">A very fast and extremely inexpensive blockchain that works with Ethereum.<br/></p>

    <input type="radio" name="chain" id="chain_ETH" value="ETH" className="chaininput" disabled={formSubmitting ? true : false}/>
    <label onClick={() => notify('ETHSoon')}>
        <div className="d-inline-flex align-items-center chainselect">
            <span className="chaintitle">Ethereum</span>
            <img src={ethImg} className="ethchainimg"/>
        </div>
    </label>
    <p className="info">Open-source blockchain that powers most NFT sales.</p><br/>

    {/*Account Hash Input*/}
    <input type="hidden" name="userAccount" id="userAccount" value=""/>

    {/*Submit Button*/}
    <div className="submitbttncont" id="submitbttncont">
        <input type="submit" id="submitbttn" className="submitbttn" name="submit" value="Create" disabled={formSubmitting ? true : false} />
    </div>

    {/*Progress Bar*/}
    <div className="progresscont" id="progresscont">
        <div className="progressdiv" id="progressdiv">
            <div id="progressbar" className="progressbar" style={{width: formProgress + "%"}}>{Math.round(formProgress) + "%"}</div>
        </div>
    </div>
    <div className="connectwalletcont" id="connectwalletcont">
        <div id="connectingwallet" className="connectingwallet">Connecting to Wallet<img className="connectingwalletimg" src="https://cineteria.com/wp-content/uploads/2021/11/grey-transparent-loading.gif" /></div>
    </div>
    <div id="submit-group" className="error submitError">{errors.submit}</div><br/>

</form>

<div className="popup" id="switchMainnet">
    <div className="popupoverlay"></div>
    <div className="popupcontent">
        <div className="popupheader">
            <h3>Please switch networks 🔁</h3>
            <div className="popupclose material-icons-outlined" onClick="notify('switchMainnet')">cancel</div>
        </div>
        <p>Your wallet has to be on either the Ethereum Mainnet or the Polygon Mainnet to make transactions on Cineteria.</p>
        <p><strong><a target="_blank" href="/help/networks">Need Help? Click or tap here.</a></strong></p>
        <div className="d-flex align-items-center">
            <button className="popupbutton" onClick={() => notify('switchMainnet')}>Okay!</button>
        </div>
    </div>
</div>

<div className="popup" id="switchMATIC">
    <div className="popupoverlay"></div>
    <div className="popupcontent">
        <div className="popupheader">
            <div className="d-flex align-items-center">
                <h3>Please switch to the Polygon network</h3>
                <img className="polygonLogo" src="https://cineteria.com/wp-content/uploads/2021/11/polygon-matic-logo.svg" />
            </div>
            <div className="popupclose material-icons-outlined" onClick={() => notify('switchMATIC')}>cancel</div>
        </div>
        <p>To make a transaction on the Polygon network, your wallet must be connected to the Polygon Mainnet.</p>
        <div className="d-flex align-items-center justify-content-center">
            <button className="unstyledbutton switchMATICbttn" id="switchMATICbttn">Switch to Polygon</button>
        </div>
        <p className="text-center"><strong><a target="_blank" href="/help/networks/#polygontutorial">Need help doing this? Click or tap here.</a></strong></p>
    </div>
</div>
<div className="popup" id="switchETH">
    <div className="popupoverlay"></div>
    <div className="popupcontent">
        <div className="popupheader">
            <div className="d-flex align-items-center">
                <h3>Please switch to the Ethereum network</h3>
                <img className="ethLogo" src="https://cineteria.com/wp-content/uploads/2021/11/EthereumLogoWhite.svg" />
            </div>
            <div className="popupclose material-icons-outlined" onClick={() => notify('switchETH')}>cancel</div>
        </div>
        <p>To make a transaction on the Ethereum network, your wallet must be connected to the Ethereum Mainnet.</p>
        <div className="d-flex align-items-center justify-content-center">
            <button className="unstyledbutton switchETHbttn" id="switchETHbttn">Switch to Ethereum</button>
        </div>
        <a target="_blank" href="/help/networks" className="text-center"><p><strong>Need help doing this? Click or tap here.</strong></p></a>
        <div className="d-flex align-items-center">
        </div>
    </div>
</div>

<Popup name="creatingNFT" header="" noCancel={createState === "Transaction Failed" ? false : true} content={
    <div className='flexCenter'>
        <div>
            <img src={createState === "Transaction Failed" ? failedImg : createState === "Transaction Taking Longer Than Usual" ? stopwatchImg : whiteLoading} className='popupLoadingImg' />
        </div>
        {formProgress < 100 &&
        <p className="creatingProgress">{`${Math.round(formProgress)}%`}</p>
        }
        <h3>{createState}</h3>
        {createState === "Transaction Failed" ? 

        <><p>Your creation transaction has failed. What now? Refresh the page and try again.</p>
        <p><strong>If this keeps happening, <Link to="/contact">contact us here.</Link></strong></p></>

        : createState === "Transaction Taking Longer Than Usual" ? 
        <><p>Your NFT is taking a long time to mint.</p>
        <button onClick={() => navigate(`/${nftNav[0]}/${nftNav[1]}`)} className='popupbutton'>Go to NFT</button></>
        
        :

        <><p>Don't close this page while your NFT is being created. Creation time is based on the amount of audios you are uploading.</p>
        <p><strong>Note: If your transaction fails, you will ONLY lose what you paid in gas. That's it!</strong></p></>

        }
        
        {/* <div className="d-flex align-items-center">
            <button className="popupbutton" onClick={() => notify('generalComplete')}>Okay!</button>
        </div> */}
    </div>
} />

<div className="popup" id="createComplete">
    <div className="popupoverlay"></div>
    <div className="popupcontent">
        <div className="popupheader">
            <h3>Your NFT creation is in progress! 🥳</h3>
            <div className="popupclose material-icons-outlined" onClick={() => notify('createComplete')}>cancel</div>
        </div>
        <p>Your transaction has gone through and should be completed soon! If you have entered an email in your account settings, we will send you an email once it is finished. Once you close this message, we will redirect you to your new NFT!</p>
        <p><strong>Note: </strong>The NFT page will say your NFT is still pending until it is finished being processed.</p>
        <div className="d-flex align-items-center">
            <button className="popupbutton" id="nftredirect">Okay!</button>
        </div>
    </div>
</div>

<Popup name="ETHSoon" header="Ethereum Blockchain coming soon!" content={
    <div className='flexCenter'>
        <p>For now, you can use the super inexpensive and fast Polygon Blockchain, which is built on Ethereum!</p>
        <strong>Note: Everything works the exact same, just faster speeds and borderline no transaction fees.</strong>
    </div>
} />
        </div>
    );
}

export default Create;