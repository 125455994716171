import React, {useState, useEffect} from 'react'
import Tippy from '@tippyjs/react';
import {copy, copyLink} from '../js/functions';

export default function CopyAddress(props) {
    const [copied, setCopied] = useState(false);
    const [copiedUrl, setCopiedUrl] = useState(false);

    function copyAddress(address) {
        copy(address);
        setCopied(true);
    }

    function outCopy() {
        setCopied(false);
    }

  return (
    <Tippy theme='white-bold' animation='scale-subtle' placement='bottom' onHidden={outCopy} hideOnClick={false} content={copied ? 'Copied!' : 'Click to Copy Address'}>
        <div className="addresstext" id="userAddress" style={copied ? {color: '#00ffcd'} : {}} onClick={() => copyAddress(props.profileData.address)}>
            {props.accountstr}
        </div>
    </Tippy>
  )
}
