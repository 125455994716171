import React, {useState, useEffect} from "react";
import {NavLink, Link} from "react-router-dom";
import jQuery from 'jquery';
import Tippy from '@tippyjs/react';
import HeaderNotifications from "./headerNotifications";
import Funds from "./funds";
import defaultProfile from '../images/defaultProfile.png';
import loadingImg from '../images/loading.gif';
import Web3 from 'web3-eth';
import cineteriaLogo from '../images/cineteria.png';
import verifiedImg from '../images/verified.png';
import wethImg from '../images/weth.png';
import ethImg from '../images/eth.png';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const cryptoImages = {
    "WETH": wethImg,
    "ETH": ethImg,
};
const bootstrap = require('bootstrap');

function Header(props) {
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgError, setImgError] = useState(false);
    const [navVisible, setNavVisible] = useState(false);
    const [offCanvas, setOffCanvas] = useState(false);
    const [mobileUserNav, setMobileUserNav] = useState(false);
    const show = () => setOffCanvas(true);
    const hide = () => setOffCanvas(false);
    const showMobileUserNav = () => setMobileUserNav(true);
    const hideMobileUserNav = () => setMobileUserNav(false);
    /*
    const [displayName, setdisplayName] = useState("Log in");
    const [profilePic, setprofilePic] = useState("https://cineteria.com/wp-content/uploads/2021/10/defaultprofilepic.png"); //set to image in workspace so dont have to find the image on internet
    const [isVerified, setisVerified] = useState(1); //1 is not verified 2 is 
    const [accentColor, setaccentColor] = useState("#000000");
    const [darkColor, setdarkColor] = useState("#000000"); //set these to default border colors
    */

    function handleImgLoad() {
        if (imgLoaded === false) {
            setImgLoaded(true);
        }
    }

    function handleImgError() {
        if (imgError === false) {
            setImgError(true);
        }
    }

    function handleMobileUserNav(e) {
        if (mobileUserNav) {
            hideMobileUserNav();
            hide();
        } else {
            e.preventDefault();
            showMobileUserNav();
        }
    }

    const colorShade = (col, amt) => {
        col = col.replace(/^#/, '')
        if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]
    
        let [r, g, b] = col.match(/.{2}/g);
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
    
        r = Math.max(Math.min(255, r), 0).toString(16)
        g = Math.max(Math.min(255, g), 0).toString(16)
        b = Math.max(Math.min(255, b), 0).toString(16)
    
        const rr = (r.length < 2 ? '0' : '') + r
        const gg = (g.length < 2 ? '0' : '') + g
        const bb = (b.length < 2 ? '0' : '') + b
    
        return `#${rr}${gg}${bb}`
    }

    if (props.isLoggedIn === true) {
            var data = props.user;
            console.log(props);
            var displayName = data.displayName;
            if (data.profilePic !== null && data.profilePic !== '') {
                var profilePic = data.profilePic;
            } else {
                var profilePic = defaultProfile;
            }
            var accentColor = "#" + data.accentColor;
            var darkColor = colorShade(accentColor, -120);
            if (data.verified === '2') {
                var isVerified = true;
            } else {
                var isVerified = false;
            }
            
            //Desktop
            //Change displayname, profile picture, link, border

            //jQuery(document).ready(function ($) {
                //jQuery("#profilepiccontainer").css("background-image", "linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, " + accentColor + "," + darkColor + ")");
                //const usernav = document.getElementById('usernav');
                //const mobileusernav = document.getElementById('mobileusernav');
                // tippy('#usernavbutton', {
                //     content: usernav,
                //     placement: 'bottom',
                //     interactive: true,
                //     arrow: false,
                //     maxWidth: 'none',
                //     offset: [0, 0],
                //     theme: '1a',
                //     animation: 'shift-away',
                //     sticky: true,
                // });

                //Mobile
                //jQuery("#mobileprofilepiccontainer").css("background-image", "linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, " + accentColor + "," + darkColor + ")");
                // tippy('#mobileusernavbutton', {
                //     content: mobileusernav,
                //     placement: 'bottom',
                //     interactive: true,
                //     arrow: false,
                //     maxWidth: 'none',
                //     offset: [0, 0],
                //     theme: '1a',
                //     animation: 'shift-away',
                //     sticky: true,
                // });
            //})
            
    } else {
    var displayName = "Log in";
    var profilePic = defaultProfile;
    var isVerified = 1;
    var accentColor = "#FFFFFF";
    var darkColor = "#FFFFFF";
    }
console.log(profilePic);
console.log(imgError);
    return (
<div className="sticky-top navbarMain">
<nav className="navbar navbar-expand-xl justify-content-center">
<div className="container-fluid navbarcontainer">
    <NavLink className="navbar-brand" to="/home">
        <img className="navlogo" src={cineteriaLogo}/>
    </NavLink>
    <SearchBar />
    <div className="d-flex justify-content-between navbaritems align-items-center">
        <div className="navbar-collapse collapse" id="collapsiblenav">
            <ul className="navbar-nav">
                <li className="nav-item homelink">
                    <NavLink className="nav-link" to="/" exact activeClassName="active">Home</NavLink>
                </li>
                <li className="nav-item marketplacelink">
                    <NavLink className="nav-link" to="/marketplace" activeClassName="active">Marketplace</NavLink>
                </li>
                <li className="nav-item contactlink">
                    <NavLink className="nav-link" to="/contact" activeClassName="active">Contact</NavLink>
                </li>
                <li className="nav-item helplink">
                    <NavLink className="nav-link" to="/help" activeClassName="active">FAQ/Help</NavLink>
                </li>
                <div className="nav-item d-flex createbttncont">
                    <NavLink to="/create">
                        <button className="unstyledbutton createnavbttn">Create</button>
                    </NavLink>
                </div>
            </ul>
        </div>
        <HeaderNotifications isLoggedIn={props.isLoggedIn} user={props.user} />
        <div className="d-flex">
                <Tippy arrow={false} disabled={!props.isLoggedIn} placement="bottom" interactive={true} allowHTML={true} theme="1a" maxWidth="none" offset={[0, 10]} sticky={true} content={
                    <div className="dropdown" id="usernav">
                        <div className="dropdowncontent">
                            <object>
                                <ul className="usernav">
                                    <li><NavLink to="/profile">My NFTs</NavLink></li>
                                    <li><NavLink to="/profile">Profile</NavLink></li>
                                    <li><NavLink to="/profile/settings">Settings</NavLink></li>
                                    <li onClick={props.logOut}><a>Log Out</a></li>
                                </ul>
                            </object>
                        </div>
                    </div>
                }>  
                    <NavLink to={props.isLoggedIn ? "/profile" : "/login"} className="profilelink" id="profilelink">
                        <button className="unstyledbutton usernavbutton" id="usernavbutton">
                            <div className="usernavimg">
                                <div className="profilepiccontainer" id="profilepiccontainer" style={{backgroundImage: `linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, ${accentColor}, ${darkColor})`}}>
                                    {imgLoaded === false &&
                                        <div className="profilepicture loadingNFT"/>
                                        //<img src={loadingImg} className="profilepicture" style={{backgroundColor:'#00ffcd'}} />
                                    }
                                    <img src={!imgError ? profilePic : defaultProfile} className="profilepicture" style={imgLoaded ? {} : {display: 'none'}} id="profilepic" onLoad={handleImgLoad} onError={handleImgError} />
                                </div>
                                <div className="usernameContainer">
                                    <span id="username">{displayName}</span>
                                    {isVerified === true &&
                                    <img src={verifiedImg} className="verifiedprofile" id="verifiedprofile" />
                                    }
                                </div>
                            </div>
                        </button>
                    </NavLink>
                </Tippy>
            <button className="navbar-toggler" type="button" onClick={show}> 
            {/* data-bs-toggle="offcanvas" data-bs-target="#offcanvasnav" */}
                <span className="navbar-toggler-icon material-icons">menu</span>
            </button>
        </div>
    </div>
</div>

</nav>

<div className={`offcanvas offcanvas-start offcanvastransition ${offCanvas ? 'show' : ''}`} id="offcanvasnav">
<div className="offcanvas-header">
    <button type="button" className="unstyledbutton material-icons" data-bs-dismiss="offcanvas" onClick={hide}>
        arrow_back
    </button>
</div>
<div className="offcanvas-body">
    <SearchBar mobile={true} offCanvasHide={hide} offCanvasShow={show} />
    {/* <div className="mobilesearchbar">
        <div className="d-flex navsearchcontainer" id="mobilenavsearchcontainer">
            <div className="d-flex justify-content-center flex-column me-2">
                <i className="material-icons">search</i>
            </div>
            <input className="me-2 navsearch" type="text" placeholder="Search for NFTs or users" id="mobilenavsearchbar"/>
            <div className="d-flex flex-column searchdropdown mobilesearchdropdown" id="mobilesearchdropdown">
                <div className="searchnftcontainer" id="mobilesearchnftcontainer">
                    <span className="searchheader">NFTs</span>
                    <ul className="searchnft" id="mobilesearchnft">
                    </ul>
                </div>
                <div className="searchuserscontainer" id="mobilesearchuserscontainer">
                    <span className="searchheader">Users</span>
                    <ul className="searchusers" id="mobilesearchusers">
                    </ul>
                </div>
            </div>
        </div>
    </div> */}
    <div className="mobileusernav">
        
            <Tippy placement="bottom" disabled={props.isLoggedIn ? false : true} visible={mobileUserNav} onClickOutside={hideMobileUserNav} interactive={true} allowHTML={true} arrow={false} maxWidth="none" offset={[0, 0]} theme="1a" sticky={true} content={
                <div className="dropdown" id="mobileusernav">
                    <div className="dropdowncontent">
                        <object>
                            <ul className="usernav" onClick={handleMobileUserNav}>
                                <li><Link to="/profile">My NFTs</Link></li>
                                <li><Link to="/profile">Profile</Link></li>
                                <li><Link to="/profile/settings">Settings</Link></li>
                                <li><a onClick={props.logOut}>Log Out</a></li>
                            </ul>
                        </object>
                    </div>
                </div>
            }>
                <Link className="profilelink" id="mobileprofilelink" to="/login" onClick={props.isLoggedIn ? (e) => handleMobileUserNav(e) : hide}>
                    <button className="unstyledbutton usernavbutton mobileusernavbutton" id="mobileusernavbutton">
                        <div className="usernavimg">
                            <div className="profilepiccontainer" id="mobileprofilepiccontainer" style={{backgroundImage: `linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, ${accentColor}, ${darkColor})`}}>
                            <img src={!imgError ? profilePic : defaultProfile} id="mobileprofilepic" className="profilepicture" style={imgLoaded ? {} : {display: 'none'}} onLoad={handleImgLoad} onError={handleImgError} />
                            </div>
                            <div className="usernameContainer">
                                <span id="mobileusername">{displayName}</span>
                                {isVerified === 2 &&
                                <img src={verifiedImg} className="mobileverifiedprofile" id="mobileverifiedprofile" />
                                }
                            </div>
                        </div>
                    </button>
                </Link>
            </Tippy>
        
    </div>
    {props.isLoggedIn && 
        <Funds web3={props.web3} user={props.user} funds={props.funds} />
    }
    <ul className="mobilenav" onClick={hide}>
        <li className="homelink">
            <NavLink className="nav-link" to="/" exact activeClassName="active">Home <i className="material-icons">home</i></NavLink>
        </li>
        <li className="marketplacelink">
            <NavLink className="nav-link" to="/marketplace" activeClassName="active">Marketplace <i className="material-icons">store</i></NavLink>
        </li>
        <li className="contactlink">
            <NavLink className="nav-link" to="/contact" activeClassName="active">Contact <i className="material-icons">contactless</i></NavLink>
        </li>
        <li className="helplink">
            <NavLink className="nav-link" to="/help" activeClassName="active">FAQ/Help <i className="material-icons">contact_support</i></NavLink>
        </li>
        <div className="d-flex createbttn">
            <NavLink to="/create" activeClassName="active">
                <button className="unstyledbutton createnavbttn">Create</button>
            </NavLink>
        </div>
    </ul>
</div>
</div>

<div className="popup" id="signPopup">
<div className="popupoverlay"></div>
<div className="popupcontent">
    <div className="popupheader">
    </div>
    <div className="d-flex justify-content-around">
        <p id="signpopupbody">Please sign your metamask notification to prove that you are the owner of this account.<br/><strong>Don't see a notifcation?</strong> Click or tap on your metamask wallet.</p>
    </div>
</div>
</div>
</div>
    );

    function SearchBar(props) {
        const [searchVisible, setSearchVisible] = useState(false);
        const [searchData, setSearchData] = useState('');
        const hide = () => setSearchVisible(false);
        const show = () => setSearchVisible(true);

        async function handleSearch(e) {
            var searchValue = e.target.value;
            if (searchValue.length > 3) {
                var data = await jQuery.ajax({
                    url: `${API_ENDPOINT}search.php`,
                    type: 'POST',
                    data: {'searchValue': searchValue}
                })
                data = JSON.parse(data);
                console.log(data);
                console.log(searchData);
                if (data.nfts === 0 && data.users === 0) {
                    console.log('SET DATA NONE');
                    setSearchData('');
                    //setSearchVisible(false);
                    hide();
                } else {
                    console.log('SET DATA');
                    setSearchData(data);
                    //setSearchVisible(true);
                    show();
                    console.log(searchData);
                }
            } else {
                if (searchVisible === true) {
                    hide();
                }
            }
        }

        function handleFocus(e) {
            var searchValue = e.target.value;
            if (searchValue !== '' && searchValue.length > 3) {
                handleSearch(e);
            }
        }

        function SearchNFT(props) {
            const offCanvasHide = props.offCanvasHide;
            props = props.data;
            console.log(props);
            return(
                <li onClick={offCanvasHide}>
                    <Link to={'/' + props.contractAddress + '/' + props.id} onClick={searchVisible ? hide : show}>
                        <div className="d-flex align-items-center">
                            <div className="searchnftnamecontainer">
                                <div className="searchcategorycontainer">
                                    <span className="searchcategory">{props.category.text}</span>
                                    <i className="material-icons searchcategoryicon">{props.category.icon}</i>
                                </div>
                                <span className="searchnftname">{props.name}</span>
                                <div className="d-flex align-items-center">
                                    <div className="searchprofileimgcontainer" style={{width: '25px', height: '25px', backgroundImage: `linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, #${props.creator.accent}, ${props.creator.dark})`}}>
                                        <img src={props.creator.image} className="searchprofileimg"/>
                                    </div>
                                    <div className="creatorsearchnamecont">
                                        <span className="creatorsearchname">{props.creator.name}</span>
                                        {props.creator.verified === true && 
                                        <img className="creatorsearchverified" src={verifiedImg} />}
                                    </div>
                                </div>
                            </div>
                            <div className="searchnftimgcontainer">
                                <img src={props.image} className="searchnftimg"/>
                            </div>
                        </div>
                        <div className="searchownercontainer">
                            <div className="d-flex flex-column align-items-center">
                                <span className="searchownertitle">Owner</span>
                                <div className="searchprofileimgcontainer" style={{width: '25px', height: '25px', backgroundImage: `linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, #${props.owner.accent}, ${props.owner.dark})`}}>
                                    <img src={props.owner.image} className="searchprofileimg"/>
                                </div>
                                <div className="ownersearchnamecont">
                                    <span>{props.owner.name}</span>
                                    {props.owner.verifed === true &&
                                        <img className="ownersearchverified" src={verifiedImg} />}
                                </div>
                            </div>     
                        </div>
                        {props.price > 0 &&
                        <div className="d-flex">
                            <span className="searchprice">{props.price}</span>
                            <img src={cryptoImages[props.ETHIcon]} className="searchethicon" />
                        </div>
                        }
                    </Link>
                </li>
            )
        }

        function SearchUser(props) {
            const offCanvasHide = props.offCanvasHide;
            props = props.data;
            console.log(props);
            return(
                <li onClick={offCanvasHide}>
                    <Link to={'/' + props.address} onClick={searchVisible ? hide : show}>
                        <div className="searchprofileimgcontainer" style={{backgroundImage: `linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, #${props.accent}, ${props.dark})`}}>
                            <img src={props.img} className="searchprofileimg" />
                            <span style={{display: 'none'}} value={props.accent}></span>
                        </div>
                        <div className="searchprofilenamecontainer">
                            <span className="searchprofilename">{props.name}</span>
                            {props.verified === true &&
                                <img className="searchuserverified" src={verifiedImg} />
                            }
                        </div>
                    </Link>
                </li>
            )
        }

        return(
            <Tippy visible={searchVisible} onClickOutside={hide} arrow={false} allowHTML={true} interactive={true} theme="transparent" maxWidth="none" offset={[0, -15]} trigger="manual" placement="bottom" content={
                <div className="d-flex flex-column searchdropdown" id="searchdropdown">
                    {searchData.nfts !== 0 && 
                    <div className="searchnftcontainer" id="searchnftcontainer">
                        <span className="searchheader">NFTs</span>
                        <ul className="searchnft" id="searchnft">
                            {searchData !== '' && Object.keys(searchData.nfts).map((nft, key) =>
                                    <SearchNFT data={searchData.nfts[nft]} offCanvasHide={props.offCanvasHide} />
                            )}
                        </ul>
                    </div>
                    }

                    {searchData.users !== 0 && 
                    <div className="searchuserscontainer" id="searchuserscontainer">
                        <span className="searchheader">Users</span>
                        <ul className="searchusers" id="searchusers">
                            {searchData !== '' && Object.keys(searchData.users).map((user, key) =>
                                    <SearchUser data={searchData.users[user]} offCanvasHide={props.offCanvasHide} />
                            )}
                        </ul>
                    </div>
                    }   
                </div>
                    
            }>
                <div className={`mainsearchbar ${props.mobile ? 'mobilesearchbar' : ''}`}>
                    <div className="d-flex navsearchcontainer" id="navsearchcontainer">
                        <div className="d-flex justify-content-center flex-column me-2">
                            <i className="material-icons searchicon">search</i>
                        </div>
                        <input className="me-2 navsearch" type="text" placeholder="Search for NFTs or users" id="navsearchbar" onKeyUp={handleSearch} onFocus={handleFocus} />
                    </div>
                </div>
            </Tippy>
        )
    }

}

export default Header;