import React, {useState, useEffect} from "react";
import SiteHelmet from "../components/siteHelmet";
import Config from "../Config.json";
import jQuery from 'jquery';
import Web3 from 'web3-eth';
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import {Link} from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import '../css/login.css';
import metamaskImg from '../images/metamask.png';
import walletconnectImg from '../images/walletconnect.png';
import cineteriaLogo from '../images/cineteria.png';
import CustomButton from "../components/custombutton";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const MANAGED_INFURA = process.env.REACT_APP_MANAGED_INFURA;

const TITLE = Config.SITE_TITLE + " - Login";
const DESC = "Log into Cineteria with your Crypto Wallet to create, sell, and buy digital music collectables";
const CANONICAL = Config.SITE_DOMAIN + "/login";

function Login(props) {
    const [metamaskDisabled, setmetamaskDisabled] = useState(false);
    const [wcDisabled, setwcDisabled] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [devError, setDevError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
      if (props.navigate !== '') {
        navigate(props.navigate);
      }
    }, [props.navigate])
    

    async function metamask() {
        try {
            var ethereum = window.ethereum;
            setmetamaskDisabled(true);
            if (typeof window.ethereum !== 'undefined') {
                var ethereum = window.ethereum;
                const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
                window.userAddress = accounts[0];
                window.provider = window.ethereum;
                localStorage.setItem("provider", "eth");
                await setWeb3(window.provider);
            } else {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform))) {
                    window.location.href = "https://metamask.app.link/dapp/cineteria.com/login/";
                    setmetamaskDisabled(false);
                } else {
                    window.open("https://metamask.io/download.html");
                    setmetamaskDisabled(false);
                } 
            }   
        } catch (e) {
            console.log(e);
        } 
    }

    async function walletconnect() {
        setwcDisabled(true);
        window.provider = "wc";
        localStorage.setItem("provider", "wc");
        window.provider = new WalletConnectProvider({
            rpc: {
                1: "https://mainnet.infura.io/v3/0997dddb58f44990b563d93c81e4e60d",
                137: "https://polygon-mainnet.infura.io/v3/0997dddb58f44990b563d93c81e4e60d",
            },
        });
        try {
            await window.provider.enable();
        } catch (e) {
            setwcDisabled(false);
            return;
        }
        window.userAddress = window.provider.accounts[0];
        await setWeb3(window.provider);
        // var web3 = window.web3;
        // let accounts = await web3.getAccounts();
        // const userAddress = accounts[0];
        // props.accountValidation();
    }

    async function coinbase() {
        const coinbaseWallet = new CoinbaseWalletSDK({
            appName: 'Cineteria',
            appLogoUrl: cineteriaLogo,
            darkMode: true,
        })
        var provider = coinbaseWallet.makeWeb3Provider('https://polygon-mainnet.infura.io/v3/0997dddb58f44990b563d93c81e4e60d', 137);
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        window.userAddress = accounts[0];
        setWeb3(provider);
    }

    async function setWeb3(provider) {
        props.enableWeb3(provider);
        //window.web3 = new Web3(window.provider);
    }

    function accountValidation() {
        var userData = {
            'userAddress': window.userAddress,
        }
    
        jQuery.ajax({
            url: `${API_ENDPOINT}validation.php`,
            type: 'POST',
            data: userData,
            success: function (data) {
                let message = data;
                let publicAddress = window.userAddress;
                signTransaction(message, publicAddress);
            }
        })
    }
    
    async function signTransaction(message, address) {
        console.log(props.web3);
        const from = address;
        const msg = message;
        try {
            var signdata = await props.web3.personal.sign(msg, from);
        } catch (e) {
            console.log(e);
            document.getElementById("walletconnect").disabled = false;
            return;
        }
        window.signdata = signdata;
        handleAuthenticate(window.userAddress, signdata);
    }
    
    function handleAuthenticate(address, signature) {
    
        var authData = {
            'userAddress': window.userAddress,
            'signature': signature,
        }
    
        jQuery.ajax({
            url: `${API_ENDPOINT}signVerify.php`,
            type: 'POST',
            data: authData,
            success: function (response) {
                createuser();
            }
        })
    }


    async function createuser() {
        jQuery.ajax({
            url: `${API_ENDPOINT}createuser.php`,
            type: 'POST',
            data: { "userAccount": window.userAddress },
            success: function(response) {
                if (response.includes("Error: SQLSTATE")) {
                    jQuery("#error").css('display', 'block')
                } else {
                    let reply = JSON.parse(response);
                    if (reply.error) {
                        accountValidation();
                        console.error("Wrong User. Please refresh the page.");
                        return;
                    }
                    //Since return if there is an error, we can call getuserData, since we have set the user address
                    //setLoggedIn(true);
                    if (reply.user == 'new') {
                        //push to profile
                        //navigate("/profile");
                        //return <Navigate to="/profile" />;
                        //window.location.href = "https://cineteria.com/profile";
                    } else {
                        if (document.referrer == "" || document.referrer == "https://cineteria.com/login/" || !document.referrer.includes('cineteria.com')) {
                            //push to profile
                            navigate("/profile");
                            //return <Navigate to="/profile" />;
                            //window.location.href = "https://cineteria.com/profile";
                        } else {
                            //push to document referrer
                            //navigate("document.referrer");
                            //return <Navigate to={document.referrer} />;
                            //window.location.href = document.referrer;
                        }
                    }
                    props.handleLogin();
                    
                }
            }
        })
    }

    function handleEmail(e) {
        setEmail(e.target.value);
        if (emailError === true && e.target.value !== '') {
            setEmailError(false);
        }
    }

    function handlePassword(e) {
        setPassword(e.target.value);
        if (passwordError === true && e.target.value !== '') {
            setPasswordError(false);
        }
    }

    async function handleLogin() {
        setLoading(true);
        setError('');
        var userData = {
            'email': email,
            'password': password,
        }

        if (email === '') {
            setEmailError(true);
            setLoading(false);
            return;
        }

        if (password === '') {
            setPasswordError(true);
            setLoading(false);
            return;
        }

        console.log("Loggin in");
        var data = await jQuery.ajax({
            url: `${API_ENDPOINT}login.php`,
            type: 'POST',
            data: userData,
            xhrFields: {
                withCredentials: true,
              }
        })
        console.log(data)
        data = JSON.parse(data);
        if (data.error === false) {
            window.managed = true;
            localStorage.setItem("provider", "managed");
            console.log(MANAGED_INFURA);
            props.enableWeb3(MANAGED_INFURA, data.token);
        } else {
            setError(data.error);
            setLoading(false);
        }
    }

    return (
        <div className="loginmain">
             <SiteHelmet TITLE={TITLE} CANONICAL={CANONICAL} DESC={DESC} />
            <div className="logincontent">
                {/* <div className="verifyEmail">
                    <div className="titleblock">
                        <h2>Verify your email</h2>
                        <h4>Enter the code provided in your email</h4>
                    </div>
                    <div className="emailLoginForm">
                        <input type="text" placeholder="Enter Code"/>
                        <div className="doubleButtons">
                            <button>Verify</button>
                            <button>Resend Code</button>
                        </div>
                    </div>
                </div> */}
                
                <div className="loginEmailContainer">
                    <div className="titleblock">
                        <h2>Log in with your email</h2>
                        <h4></h4>
                    </div>
                    <div className={`emailLoginForm ${passwordError ? 'passwordError' : ''} ${emailError ? 'emailError' : ''}`}>
                        <input type="email" placeholder="Email" onKeyUp={(e) => handleEmail(e)} />
                        <input type="password" placeholder="Password" onKeyUp={(e) => handlePassword(e)} />
                        <div className="doubleButtons buttonsRow">
                            {/* <button onClick={handleLogin}>Login</button> */}
                            <CustomButton loading={loading} disabled={loading} theme={'green'} function={handleLogin}>Login</CustomButton>
                            <Link to="/signup">
                                <button>Sign Up</button>
                            </Link>
                        </div>
                        {/* <Link to="/help/forgot-password">
                            <div className="forgotPassword">
                                Forgot Password?
                            </div>
                        </Link> */}
                        <div className="error" id="error">
                            {error}
                        </div>
                    </div>
                </div>
                <div className="loginOr">
                    Or
                </div>
                <div className="titleblock">
                    <h2>Select your wallet</h2>
                    <h4>More wallets coming soon!</h4>
                </div>
                <div className="d-flex justify-content-center flex-column align-items-center">
                    <button id="metamask" className="walletbutton" onClick={metamask} disabled={metamaskDisabled}>
                        <img src={metamaskImg}/>
                        <h3 className="wallettitle">MetaMask</h3>
                    </button>
                    <button id="walletconnect" className="walletbutton" onClick={walletconnect} disabled={wcDisabled}>
                        <img src={walletconnectImg}/>
                        <h3 className="wallettitle">WalletConnect</h3>
                    </button>
                    {/* <button id="coinbase" className="walletbutton" onClick={coinbase} disabled={false}>
                        <img src={walletconnectImg}/>
                        <h3 className="wallettitle">Coinbase</h3>
                    </button> */}
                </div>
                <div className="error" id="error">
                    {props.error !== '' && 
                        <p>{props.error} If this continues, please <Link to="/contact">contact us.</Link></p>
                    }
                </div>
                <div>{devError}</div>
            </div>
        </div>
    );
}

export default Login;