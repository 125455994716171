import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Config from "../Config.json";
import {Link} from "react-router-dom";
import jQuery from 'jquery';
import '../css/home.css';
import NFTCard from "../components/nftcard";
import LoadingNFTs from "../components/loadingNFTs";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const TITLE = Config.SITE_TITLE + " - Home";
const DESC = "Cineteria Description";
const CANONICAL = Config.SITE_DOMAIN + "/";



function Home(props) {
    const [isLoaded, setLoaded] = useState(false);
    const [nft, setNFT] = useState('');

    useEffect(() => {
        if (isLoaded === false) {
            getNFTs();
        }
    })
    
    async function getNFTs() {
        try {
            var data = await jQuery.ajax({
                url: `${API_ENDPOINT}getNFTs.php`,
                type: 'POST',
                data: {'userAddress': '', 'param': 'featured'},
                xhrFields: {
                    withCredentials: true,
                },
            })
            
        } catch(e) {
            console.log(e);
        }
        data = JSON.parse(data);
        console.log(data);
        setNFT(data);
        setLoaded(true);
    }
    return (
        <div className="homeMain">
            <Helmet>
                <title>{TITLE}</title>
                <link rel="canonical" href={CANONICAL}/>
                <meta name="description" content={DESC}/>
                <meta name="theme-color" content={Config.THEME_COLOR}/>
            </Helmet>
    <div className="d-flex justify-content-center maincontent">
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-center">
                <h2 className="quipheader">The Future of Music</h2>
            </div>
            <div className="d-flex justify-content-center">
                <h2 className="buttoninfo">Sell your Music as Digital Collectables</h2> 
                {/* Turn your Albums, EPs, Singles, Tickets, Collectables, and Art into NFTs */}
            </div>
            <div className="d-flex justify-content-center menubuttoncontainer">
                <Link to="/create">
                    <button className="unstyledbutton menubutton first">
                        Create Now
                    </button>
                </Link>
                <Link to={props.isLoggedIn ? '/marketplace' : '/login'}>
                    <button className="unstyledbutton menubutton">
                        {props.isLoggedIn ? 'Explore' : 'Log in/Sign Up'}
                    </button>
                </Link>
            </div>
        </div>
        <div className="featurednftcontainer">
            <h3>Music of the Day<span className="material-icons"></span></h3>
            <div className="featurednft" id="featuredNFT">
                {isLoaded === false && <LoadingNFTs/>}
                {isLoaded === true && <NFTCard data={nft[0]} />}
            </div>
        </div>
    </div>
    <div className="secondarycontent">
        <h4>Easily and Independently turn your</h4>
        <div className="d-flex justify-content-around categorylist">
            <div className="d-flex flex-column categoryitem">
                <i className="material-icons listicons">library_music</i>
                <h5>Albums</h5>
            </div>
            <div className="d-flex flex-column categoryitem">
                <i className="material-icons listicons">music_video</i>
                <h5>EPs</h5>
            </div>
            <div className="d-flex flex-column categoryitem">
                <i className="material-icons listicons">music_note</i>
                <h5>Singles</h5>
            </div>
            <div className="d-flex flex-column categoryitem">
                <i className="material-icons listicons">local_activity</i>
                <h5>Tickets</h5>
            </div>
            <div className="d-flex flex-column categoryitem">
                <i className="material-icons listicons">diamond</i>
                <h5>Collectables</h5>
            </div>
            <div className="d-flex flex-column categoryitem">
                <i className="material-icons listicons">palette</i>
                <h5>Art</h5>
            </div>
            <div className="d-flex flex-column categoryitem">
                <i className="material-icons listicons">category</i>
                <h5>Other</h5>
            </div>
        </div>
        <h4>Into Digital Collectables!</h4>
        <div className="d-flex flex-column justify-content-center align-items-center benefitsblock">
            <div className="benefitscont">
                <h4>Garner community and fan hype by:</h4>
                <ul>
                    <li className="listexamples">Releasing your unreleased projects, exclusives, and new awaited albums as limited time items.<br/><span className="listcomment">Make it even more limited by only allowing the NFT buyer to listen to the music!</span></li>
                    <li className="listexamples">Turning your song into multiple NFTs, so each of your fans can have a slice of the pie.</li>
                    <li className="listexamples">Creating NFT tickets as entries to your concert.<br/><span className="listcomment">(VIP tickets!)</span></li>
                    <li className="listexamples">Releasing collectable NFTs that your fans can collect and trade.</li>
                    <li className="listexamples">Selling your art or album covers.</li>
                    <li className="listexamples">Turning whatever you want into an NFT!</li>
                </ul>
            </div>
            <h4 className="maxwidthBenefits">There is no limit to what you can do on Web3. We make the tools, you make anything you want!</h4>
            <br/>
            <h4 className="maxwidthBenefits"><span className="cineteriacolor">Not an artist?</span> Collect, trade, and sell RARE and limited time items. YOU could be the only one to ever hear your favorite artists unreleased album. Share if you wish.</h4>
            <div className="startnowCont">
                <a href="#">
                    <button className="unstyledbutton menubutton">Start Now!</button>
                </a>
            </div>
        </div>
    </div>
</div>
    );
}

/**<article className="assetCard" id="placeholderNFT">
                    <Link className="styledlink" to="/0xd5093f42a98d361a2fe452ffc284bfca40c9193c/164">
                        <div className="assetCardContent">
                            <div className="assetmedia">
                                <div className="flexcenteraligned">
                                    <div className="imagecontainer">
                                        <img className="assetimage" src="https://cineteria.com/wp-content/uploads/2021/12/white-loading-icon.gif"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flexspacebetween">
                            <div className="cardspacebetween">
                                <div className="assetinfo">
                                    <div className="flexassetname">
                                        <div className="assetnameblock">
                                            <div className="assetname">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="flexassetname">
                                        <div className="assetnameblock">
                                            <div className="assetname"></div>
                                        </div>
                                    </div>
                                    <div className="artistnamecontainer">
                                        <object>
                                            <a className="artistnamelink" href="">
                                                <div className="artistname"></div>
                                            </a>
                                        </object>
                                    </div>
                                </div>
                                <div className="pricecontainer">
                                    <div className="priceflexcontainer">
                                        <span className="pricetext"></span>
                                        <div className="pricenumcontainer">
                                            <div className="ethblock">

                                            </div>
                                            <div className="pricenum">
                                                <span className="pricerawsymbol"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cardfooterannotations">
                                    </div>
                                </div>
                            </div>
                            <footer className="cardfooterflex">
                                <div className="ownerflex">
                                    <div className="ownertextcontainer">
                                    </div>
                                    <div className="ownercontainer">
                                    </div>
                                </div>
                                <span className="likescontainer">
                                    <i size="20" aria-label="like" value="like_border" className="likeicon material-icons"></i>
                                    <div className="likesamount"></div>
                                </span>
                            </footer>
                        </div>
                    </Link>
                </article> */

export default Home;