import React, {useEffect, useState} from "react";
import jQuery from 'jquery';
import Filter from "../components/filter";
import NFTCard from "../components/nftcard";
import LoadingNFTs from "../components/loadingNFTs";
import {Helmet} from "react-helmet";
import Config from "../Config.json";
import '../css/marketplace.css';
import cineteriaTile from '../images/cineteriaTile.png';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const TITLE = Config.SITE_TITLE + " - Marketplace";
const DESC = "Browse all of the collectables for sale on the Cineteria Marketplace!";
const CANONICAL = Config.SITE_DOMAIN + "/marketplace";


function Marketplace() {
    const [isLoaded, setLoaded] = useState(false);
    const [nfts, setNFTs] = useState('');
    const [showMobileFilter, setMobileFilter] = useState(false);
    
    useEffect(() => {
        if (isLoaded === false) {
            getNFTs();
        }
    })
    
    async function getNFTs(sortArgs = '') {
        setNFTs('');
        console.log(sortArgs);
        var userData = {
			'userAddress': "",
            'args': sortArgs,
		};
        try {
            var data = await jQuery.ajax({
                url: `${API_ENDPOINT}getNFTs.php`,
                type: 'POST',
                data: userData,
                xhrFields: {
                    withCredentials: true,
                },
            })
            
        } catch(e) {
            console.log(e);
        }
        data = JSON.parse(data);
        console.log(data);
        setNFTs('');
        setNFTs(data);
        setLoaded(true);
    }

    function mobileFilter() {
        setMobileFilter(showMobileFilter ? false : true);
        showMobileFilter ? document.body.classList.remove('noScroll') : document.body.classList.add('noScroll');
        console.log(showMobileFilter);
    }

    return (
        <div className="marketplacemain" style={{backgroundImage: `url(${cineteriaTile})`}}>
            <Helmet>
                <title>{TITLE}</title>
                <link rel="canonical" href={CANONICAL}/>
                <meta name="description" content={DESC}/>
                <meta name="theme-color" content={Config.THEME_COLOR}/>
            </Helmet>
            <div className="material-icons mobilefilterbutton" id="mobilefilter" onClick={mobileFilter}>filter_alt</div>
            <div className="d-flex justify-content-between me-10">
                <div className="d-flex">
                    <Filter getNFTs={(sortArgs) => getNFTs(sortArgs)} mobileFilter={mobileFilter} show={showMobileFilter} />
                </div>


                <div className="master" id="assetcardmaster">
                    {isLoaded === true && !nfts.noNFTs && Object.keys(nfts).map((nft, key) =>
                            <NFTCard data={nfts[nft]} />
                    )}
                    {isLoaded === false && Array(30).fill().map((nft, key) => 
                        <LoadingNFTs />
                    )}
                    {nfts.noNFTs && isLoaded === true &&
                    <p className="noNFTs">No NFTs found.</p>
                    }
                </div>
            </div>
        </div>
    );

    

}

export default Marketplace;