import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import SiteHelmet from '../components/siteHelmet';
import Config from '../Config.json';
import DatePicker from "react-datepicker";
// import {DatePicker} from 'antd';
import Web3 from 'web3-eth';
import abiDecoder from 'abi-decoder';
import jQuery from 'jquery';
import { Link } from 'react-router-dom';
import ArtistSearch from '../components/artistSearch';
import Popup from '../components/popup';
import Tippy from '@tippyjs/react';
import {notify, unnotify, switchETH, switchMATIC, tradeRate, copyLink, setupTransactionData} from '../js/functions';
import '../css/nft.css';
import moment from 'moment';
import NotFound from './notfound';
import loadingImg from '../images/loading.gif';
import whiteLoading from '../images/whiteLoading.gif';
import failedImg from '../images/failed.png';
import stopwatchImg from '../images/stopwatch.png';
import verifiedImg from '../images/verified.png'
import wethImg from '../images/weth.png';
import ethImg from '../images/eth.png';
import polygonImg from '../images/polygon.png';
import cineteriaLogo from '../images/cineteria.png';
import facebook from '../images/facebook.png';
import twitter from '../images/twitter.png';


//Category Images
import Album from '../images/categoryAlbum.png';
import Art from '../images/categoryArt.png';
import Collectable from '../images/categoryCollectable.png';
import EP from '../images/categoryEP.png';
import Other from '../images/categoryOther.png';
import Single from '../images/categorySingle.png';
import Ticket from '../images/categoryTicket.png';

import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import "react-datepicker/dist/react-datepicker.css";
// import 'antd/dist/antd.css';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const categoryImages = {
    "Album": Album, 
    "Art": Art,
    "Collectable": Collectable,
    "EP": EP,
    "Other": Other,
    "Single": Single,
    "Ticket": Ticket,
};

const cryptoImages = {
    "WETH": wethImg,
    "ETH": ethImg,
};

const weiToETH = 1000000000000000000;
const {RangePicker} = DatePicker;
const rootChainManagerContract = '';
var auctionEnding = '';

function NFT(props) {
    var web3 = props.web3;
    const {contractAddress, id} = useParams();
    const [isLoaded, setLoaded] = useState(false);
    const [nftData, setNFTData] = useState('');
    const [imgLoaded, setImgLoaded] = useState(false);
    const [auctionPopup, setAuctionPopup] = useState(false);
    const [unlockDisabled, setUnlockDisabled] = useState(false);
    const [txState, setTxState] = useState("Transaction Pending");
    const [likeCount, setLikeCount] = useState(0);
    const [isLiked, setLiked] = useState(false);
    var data = '';

    useEffect(() => {
        if (isLoaded === true) {
            setLoaded(false);
        }
        getNFT();
    }, [contractAddress, id, props.user])

    async function getNFT() {
        var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        //console.log(props.user.address);
        var userData = {
			'contractAddress': contractAddress,
			'tokenId': id,
			'userAddress': props.user === null ? '' : props.user.address,
			'timezone': timezone,
		};

        try {
            data = await jQuery.ajax({
                url: `${API_ENDPOINT}nftinfo.php`,
                type: 'POST',
                data: userData,
            })
            
        } catch(e) {
            console.log(e);
        }
        console.log(data);
        data = JSON.parse(data);
        setNFTData(data);
        setLikeCount(data.likesCount);
        setLiked(data.isLiked);
        setLoaded(true);
    } 
    if (isLoaded === true) {
        console.log(nftData);
        if (nftData.nftExists === false) {
            return (
                <NotFound />
            )
        } else {
            return (
                <>
                <SiteHelmet TITLE={`${nftData.name} by ${nftData.creator.name}`} CANONICAL={`${Config.SITE_DOMAIN}/${contractAddress}/${id}`} DESC={`${nftData.description ? nftData.description : `${nftData.creator.name}'s ${nftData.category.name === "Other" ? 'NFT' : nftData.category.name}, ${nftData.name}, on Cineteria.`}`} IMAGE={nftData.image} />
                <ShareButton nft={nftData} />
                <div className='maincontainer'>
                    <div className='assetcontainer'>
                        <div className='margincontainer'>
                            <div className='assetlarge'>
                                <div className='assetwrapper'>
                                    <div className='assetsummary'>
                                        <AssetCard nft={nftData} likeCount={likeCount} isLiked={isLiked} />
                                        {nftData.audio !== null && 
                                            <AudiosPanel nft={nftData} />
                                        }
                                    </div>
                                    <div className='assetinfomain'>
                                        <NFTInfo nft={nftData} likeCount={likeCount} />
                                        <NFTControls nft={nftData} user={props.user} />
                                        <Offers nft={nftData} />
                                    </div>
                                </div>
                                <AssetActivity nft={nftData}/> 
                            </div>
                        </div>
                    </div>
                </div>
                <Popup name="confirmpopup" header="Before you get started" content={
                    <div>
                        <p>When you list your NFT, the process will consist of two things. <strong className='d-inline-block' style={{marginTop: 13}}>Creating the listing for your NFT:</strong> Setting the price/how long your auction is for. <br/><strong className='d-inline-block' style={{marginTop: 3}}>Approving your NFT to be moved on the marketplace:</strong> When somebody buys your NFT, the funds are sent to you and your NFT is sent to the buyer, all automatically.<br/><strong className='d-inline-block' style={{marginTop: 13}}>Note:</strong> Your NFT will only leave your wallet when it has been sold!</p><br/>
                        <input type="checkbox" name="dontshowagain" id="dontshowagain"/><p className='d-inline' style={{fontWeight: 500}}>Don't show this again</p><br/><br/>
                        <div className="d-flex">
                            <button onClick={confirmYes} className="popupbutton">Okay!</button>
                            <button onClick={confirmNo} className="popupbutton">Go Back</button>
                        </div>
                    </div>
                } />
                <Popup name="listOptions" header="List your NFT" maxWidth={true} content={
                    <div>
                        <p className='text-center'>How do you want to list your NFT?</p><br/>
                            <div className="d-flex justify-content-around">
                                <button onClick={listingSettings} className="popupbutton">
                                    <h5>Standard Listing</h5>
                                </button>
                                <button onClick={() => notify('auctionSettings')} className="popupbutton"> {/*w-100*/}
                                    <h5>Auction</h5>
                                </button>
                            </div>
                    </div>
                } />
                <Popup name="approvePopup" header="Approve selling of your NFT" maxWidth={true} content={
                    <div>
                        <div className="d-flex justify-content-around">
                        <p className='text-center' id="approvepopupbody">Sign this transaction to approve selling of your NFT.</p>
                        </div>
                        <button className="popupbutton" style={{display: 'none'}} id="retrybutton">Retry</button>
                    </div>
                } />
                <Popup name="listingSettings" header="Enter your NFT's Price" maxWidth={true} content={
                    <div className="d-flex justify-content-around">
                        <form id="listingForm" method="POST" enctype="multipart/form-data" className="was-validated listform">
                            <label for="price"><h3>Price: <p className='d-inline' style={{color: 'red'}}>*</p></h3></label>
                            <p className="info">The price of your item(s).</p>
                            <div d-flex>
                                <input type="number" id="price" name="price" step="any" min="0.000000001" className="numberbox" required/>
                                <img src={ethImg} className="inputethicon" />
                            </div>
                            <div className='text-center'>
                                <span id="listusd" className='d-inline-flex'></span>
                            </div>
                            <div className="invalid-feedback">Please set a price.</div>
                            <div id="price-group" className="error"></div><br/>
                            <input type="submit" id="listsubmitbttn" className="unstyledbutton popupbutton submitbttn" name="List" value="List"/>
                        </form>
                    </div>
                } />
                <Popup name="bidComplete" header="Woo Hoo! You have bidded! 🥳" content={
                    <div>
                        <p>You have successfully bidded! When you get a notification from your wallet provider that your transaction was successful, come back here to see your live bid!<br/><strong>Remember,</strong> if your transaction fails, you will have to resubmit your bid. But no worries, you won't lose your ethereum that you tried to bid!</p>
                            <p><strong><a href="/help">Have more questions? Click or tap here.</a></strong></p>
                            <div className="d-flex align-items-center">
                                <button className="popupbutton" onClick={() => notify('bidComplete')}>Okay!</button>
                            </div>
                    </div>
                } />
                <Popup name="settlePopup" header="Settle your NFT auction." content={
                    <div>
                        <p>Settling your NFT auction will complete your auction with the current highest bid. You will receive the ETH, and your NFT will automatically be sent to the buyer.<br/></p>
                            <div className="d-flex">
                                <button className="popupbutton" id="popupsettlebttn" onClick={settleNFT}>Settle the Auction</button>
                                <button className="popupbutton" onClick={() => notify('settlePopup')}>Go Back</button>
                            </div>
                    </div>
                } />
                <Popup name="cancelPopup" header="Cancel your listing/auction." content={
                    <div>
                        <p>Canceling your listing/auction will immediately take your listing off of the marketplace. If your NFT is being auctioned, you will not receive any ETH that has been bidded.</p>
                            <div className="d-flex">
                                <button className="popupbutton" onClick={cancelNFT} id="popupcancelbttn">Cancel the Listing/Auction</button>
                                {/* <button className="popupbutton" onClick={() => notify('cancelPopup')}>Go Back</button> */}
                            </div>
                    </div>
                } />
                <Popup name="transferSettings" header="Enter the information" maxWidth={true} content={
                    <div>
                        <p className='text-center' style={{marginTop: '10px !important'}}>Who do you want to transfer the NFT to?</p>
                            <div className="d-flex justify-content-around">
                                <form id="transferForm" action="" method="POST" enctype="multipart/form-data" className="was-validated auctionform" onSubmit={transferNFT}>
                                    <ArtistSearch required={true} className='popupInputBox' />
                                        {/* <input type="text" name="transferAddress" className='popupInputBox' id="transferAddress" required /> */}
                                    <div className='giftCheckboxContainer'>
                                        <label className="giftSwitch">
                                            <input type="checkbox" className='giftCheckbox' id="giftSwitch" />
                                            <span className='slider round'></span>
                                        </label>
                                        <div className='giftCheckboxText'>
                                            <div>
                                                Send as a Gift
                                            </div>
                                            <i className='material-icons'>redeem</i>
                                        </div>
                                    </div>
                                    
                                    <div className="error" id="transferError">Not a valid Ethereum address.</div>
                                    <input type="submit" id="tranferSubmit" className="unstyledbutton popupbutton submitbttn" value="Send"/>
                                </form>
                            </div>
                    </div>
                } />
                <Popup name="auctionbid" header="How much do you want to bid?" content={
                    <div>
                        <p className='text-center' style={{marginBottom: '4px !important'}}>Enter the amount of ethereum you would like to bid. <br/><strong>Note:</strong> You will get all of your ETH back if someone overbids you, or the auctioner cancels.</p><br/>
                        <div className="d-flex justify-content-around">
                            <form id="bidamount" action="buynft.php" method="POST" enctype="multipart/form-data" className="was-validated">
                                <div className="d-flex flex-column justify-content-around">
                                    <div className="previousbidcontainer">
                                        <p className='d-inline-flex' style={{fontWeight: 500}}>Current Highest Bid:</p>
                                        <p className='d-inline-flex' style={{fontWeight: 500}} id="previousbid">1</p>
                                        <img className="mainethimg w-auto" src={ethImg}/>
                                    </div>
                                    <div className="yourbidcontainer">
                                        <p style={{fontWeight: 500}}>Your Bid:</p>
                                        <div d-flex>
                                            <input type="number" id="bid" name="bid" step="any" min="0.000000001" className="numberbox d-inline-flex" required/>
                                            <img className="mainethimg w-auto" src={ethImg}/>
                                        </div>
                                        <div className="invalid-feedback text-center">Please set a bid amount.</div>
                                        <div className='text-center'>
                                            <span id="bidusd" className='d-inline-flex'></span>
                                        </div>
                                        <div id="bid-group" className="error"></div>
                                    </div>
                                </div>
                                <div style={{textAlign: 'center', marginTop: 12}}>
                                    <input type="submit" id="submitbttn" className="unstyledbutton popupbutton submitbttn" value="Bid"/>
                                </div>
                            </form>
                        </div>
                    </div>
                } />
                <Popup name="generalComplete" header="" darkMode={true} noCancel={txState === "Transaction Pending" ? true : false} content={
                    <div className='flexCenter'>
                        <div>
                            <img src={txState === "Transaction Failed" ? failedImg : txState === "Transaction Taking Longer Than Usual" ? stopwatchImg : whiteLoading} className='popupLoadingImg' />
                        </div>
                        {txState !== "Transaction Pending" &&
                            <h3>{txState}</h3>
                        }
                        {txState === "Transaction Failed" ? 
                        <><p>Your transaction has failed. Please try again.</p>
                        <p><strong>If this keeps happening, <Link to="/contact">contact us here.</Link></strong></p></>

                        : txState === "Transaction Taking Longer Than Usual" ?
                        <><p>Your transaction is taking longer than expected.</p>
                        <p style={{marginTop:'10px'}}><strong>Come back to this page whenever you get a "Transaction Completed" notification from your wallet.</strong></p></>

                        :

                        <><p>Your transaction is pending, This should only take about a minute.</p></>

                        }
                    </div>
                } />
                <Popup name="timeoutPopup" header="Your transaction is taking a little longer than we expected 🤔" content={
                    <div>
                        <p>Your transaction is taking a while to process on the blockchain. Whenever you get a notification from your wallet that the transaction was successful, just come back here! ☺</p>
                        <p><strong>Note: If your transaction fails, all you have to do is retry.</strong></p>
                        <div className="d-flex align-items-center">
                            <button className="popupbutton" onClick={() => notify('timeoutPopup')}>Okay</button>
                        </div>
                    </div>
                } />
                <Popup name="failedPopup" header="Uh oh! Your transaction has failed ❌" content={
                    <div>
                        <p style={{marginBottom: '2px !important'}}>But that's alright, just try again!</p>
                        <p><strong>Need Help?</strong><a href="/help"> Visit the FAQ page</a><strong> or </strong><a href="/contact">Contact us here.</a></p>
                        <div className="d-flex align-items-center">
                            <button className="popupbutton" onClick={() => notify('failedPopup')}>Okay!</button>
                        </div>
                    </div>
                } />
                <Popup name="noWETH" header="Looks like you have no ETH on the Polygon Blockchain!" content={
                    <div>
                        <div>
                            <p>In order to buy NFTs on the Polygon Blockchain, you will need some Polygon ETH. To get some Ethereum on the Polygon Blockchain, all you have to do is Bridge your ETH! This is essentially just wrapping and moving some of your ETH tokens over to the Polygon chain. To bridge some tokens, click or tap "Bridge".</p>
                            <p><strong><a href="/help">Have more questions? Click or tap here.</a></strong></p>
                            <div className="d-flex align-items-center">
                                <button className="popupbutton" onClick={() => notify('bridgeETH')}>Bridge</button>
                                <button className="popupbutton" onClick={() => notify('noWETH')}>Go Back</button>
                            </div>
                        </div>
                    </div>
                } />
                <Popup name="insufficientBalance" header="You need a bit more Polygon ETH" content={
                    <div>
                        <p>Looks like you need a little bit more ETH on the Polygon blockchain to buy or bid on this NFT. To get some Ethereum on the Polygon Blockchain, all you have to do is Bridge your ETH! This is essentially just wrapping and moving some of your ETH tokens over to the Polygon chain. To bridge some tokens, click or tap "Bridge".</p>
                        <p><strong><a>Have more questions? Click or tap here.</a></strong></p>
                        <div className="d-flex align-items-center">
                            <button className="popupbutton" onClick={() => notify('bridgeETH')}>Bridge</button>
                            <button className="popupbutton" onClick={() => notify('insufficientBalance')}>Go Back</button>
                        </div>
                    </div>
                } />
                <Popup name="bridgeETH" header="Bridge your ETH" content={
                    <div>
                        <div className="yourbidcontainer">
                            <p style={{fontWeight: 500}}>How much ETH do you want to bridge?</p>
                            <p><strong>Note: Bridging takes about 7-10 minutes to process. So, if you're bidding we recommend that you bridge a little more than you planned to. Just incase somebody over-bids what you were planning to bid, especially if it is a busy auction.</strong></p>
                            <div d-flex>
                                <input type="number" id="bridgeamt" name="bridgeamt" step="any" min="0.000000001" className="numberbox d-inline-flex" required/>
                                <img className="mainethimg w-auto" src={ethImg}/>
                            </div>
                            <div className="invalid-feedback text-center">Please set a bridge amount.</div>
                            <div className='text-center'>
                                <span id="bridgeusd" className='d-inline-flex'></span>
                            </div>
                            <div id="bridge-group" style={{marginTop: 8}} className="error"></div>
                        </div>
                        <div className='text-center'>
                            <input type="submit" id="bridgebttn" className="unstyledbutton popupbutton submitbttn" value="Bridge"/>
                        </div>
                        <p className='text-center' style={{marginTop: '15px !important'}}><strong><a>Have more questions? Click or tap here.</a></strong></p>
                        <div className="d-flex align-items-center">
                        </div>
                    </div>
                } />
                <Popup name="walletWETH" header="Add this asset to your wallet" content={
                    <div>
                        <p>Accept this transaction to add Polygon ETH to your wallet. This just allows you to see your balance of the coin in your wallet without entering the details manually.</p>
                        <div className="d-flex align-items-center">
                        </div>
                    </div>
                } />
                <Popup name="switchMATIC" header="Please switch to the Polygon network" content={
                    <div>
                        <p>To make a transaction on the Polygon network, your wallet must be connected to the Polygon Mainnet.</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <button className="unstyledbutton switchMATICbttn" id="switchMATICbttn">Switch to Polygon</button>
                        </div>
                        <p className='text-center'><strong><a target="_blank" href="/help/networks/#polygontutorial">Need help doing this? Click or tap here.</a></strong></p>
                    </div>
                } customHeader={
                    <div className="popupheader">
                        <div className="d-flex align-items-center">
                            <h3>Please switch to the Polygon network</h3>
                            <img src={polygonImg} style={{width:30, marginLeft:9}}/>
                        </div>
                        <div className="popupclose material-icons-outlined" onClick="notify('switchMATIC')">cancel</div>
                    </div>
                } />

                <Popup name="switchETH" header="Please switch to the Ethereum network" content={
                    <div>
                        <p>To make a transaction on the Ethereum network, your wallet must be connected to the Ethereum Mainnet.</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <button className="unstyledbutton switchETHbttn" id="switchETHbttn">Switch to Ethereum</button>
                        </div>
                        <a target="_blank" href="/help/networks" className='text-center'><p><strong>Need help doing this? Click or tap here.</strong></p></a>
                        <div className="d-flex align-items-center">
                        </div>
                    </div>
                } customHeader={
                    <div className="popupheader">
                        <div className="d-flex align-items-center">
                            <h3>Please switch to the Ethereum network</h3>
                            <img src={ethImg} style={{width:20, marginLeft: 5}} />
                        </div>
                        <div className="popupclose material-icons-outlined" onClick="notify('switchETH')">cancel</div>
                    </div>
                } />
                <Popup name="waitERC20" header="Processing Approval for your ERC20 Tokens" content={
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <h4 className='mb-0' style={{fontSize: 18}}>This will take around a minute</h4>
                        <img style={{width: 45, marginLeft: 10}} src={whiteLoading} />
                    </div>
                } />
                <Popup name="alreadyListing" header="Your listing is still pending!" content={
                    <div>
                        <p>Your listing transaction is still pending and should be done in about a minute! Refresh, or come back to this page when your Wallet notifies you that your transaction is successful. If for some reason it fails, just re-list it!</p>
                        <p><strong><a href="/help">Have more questions? Click or tap here.</a></strong></p>
                        <div className="d-flex align-items-center">
                            <button className="popupbutton" onClick={() => notify('alreadyListing')}>Okay!</button>
                        </div>
                    </div>
                } />
                <Popup name="addWETH" header="Add Polygon Ethereum to your Wallet's Balance" content={
                    <div>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <p>Accept this transaction to add Polygon Ethereum to the "assets" tab in your wallet, so you can keep track of your balance.</p>
                            <p><strong>Note: If you already have Polygon ETH added to your wallet, your wallet may display a warning. If this is the case, you are fine to just reject the transaction.</strong></p>
                        </div>
                        <div className="d-flex align-items-center">
                        </div>
                    </div>
                } />
                <Popup name="askAddWETH" header="Want to add Polygon ETH to your wallet?" content={
                    <div>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <p>Do you want to add Polygon Ethereum to the "assets" tab in your wallet? This will visually allow you to keep track of your balance.</p>
                            <p><strong>Note: If you already have Polygon ETH visually in your wallet, you can decline this.</strong></p>
                        </div>
                        <div className="d-flex align-items-center">
                            <button className="popupbutton" id="addWETHYES">Okay!</button>
                            <button className="popupbutton" id="addWETHNO">No</button>
                        </div>
                    </div>
                } />
                <Popup name="confirmBuy" header="Buy Collectable" darkMode={true} content={
                    <div>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <p>Current balance: ${props.funds.usd}</p>

                            <h4>You are buying <strong>'{nftData.name}'</strong> for <strong>${nftData.price.usd}</strong>.</h4>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <button className="darkButton popupbutton" onClick={buyNFT}>Buy</button>
                        </div>
                    </div>
                } />
                <Popups />
                </>
            )
        }
    } else {
        return (
            <div className='maincontainer'>
                <img className='cineteriaLoading' src={loadingImg} />
            </div> 
        )
    }


    function AssetCard(props) {

        //const [likeCount, setLikeCount] = useState(props.nft.likesCount);

        function handleLoad() {
            if (imgLoaded === false) {
                setImgLoaded(true);
            }
        }

        function Collaborator(props) {
            var props = props.artist;
            return(
                <Link className='collablink' to={'/' + props.address}>
                    <div className='collaborator'>
                        <span style={props.cineteria ? {} : {color: 'white'}}>
                            {props.name}
                        </span>
                        {props.verified === true && 
                        <img src={verifiedImg} className="collabverificationbadge" />
                        }
                        <span className='collaboratorsseperator' style={props.cineteria ? {} : {color: 'white'}}>
                            ,
                        </span>
                    </div>
                </Link>
            )
        }

        async function like() {
            if (window.userAddress === '') {
                return;
            }
    
            var data = await jQuery.ajax({
                url: `${API_ENDPOINT}likenft.php`,
                type: 'POST',
                data: {'tokenId': id, 'userAddress': window.userAddress}
            })
    
            data = JSON.parse(data);
            if (data.success === true) {
                if (data.action === 0) {
                    setLiked(true);
                    setLikeCount(Number(props.likeCount) + 1);
                } else {
                    setLiked(false);
                    setLikeCount(Number(props.likeCount) - 1);
                }
            }
        }

        return(
            <article className='assetframe'>
                <header className='assetheader'>
                    <div className='flexcontainer'>

                    </div>
                    <span className='assetinfolikescontainer'>
                        <i className={`material-icons assetinfolikeicon ${props.isLiked ? 'liked' : ''}`} onClick={() => like(id)}>favorite</i>
                        <div className='likesamount'>
                            {props.likeCount}
                        </div>
                    </span>
                </header>
                <div className='assetlarge'>
                    <div className='imageframe'>
                        <div className='imageflex'>
                            <div className='imagecontainer'>
                                <img className={`mainassetimage ${imgLoaded ? '' : 'loadingNFT'}`} src={props.nft.image} onLoad={handleLoad} />
                            </div>
                        </div>
                    </div>
                </div>
                <footer className='assetheader'>
                    <div className='flexcontainer'>

                    </div>
                    {props.nft.isArtists === true &&
                        <div className="flexcontainer">
                            <span className='collaboratorstext'>Collaborators: </span>
                                {Object.keys(props.nft.artists).map((artist) =>
                                        <Collaborator artist={props.nft.artists[artist]} />
                                )}
                        </div>
                    }
                </footer>
            </article>
        )
    }

    function AudiosPanel(props) {
        return(
            <section className='assetdetailsframe'>
                <div>
                    <div>
                        <div className='audiobasepanelcontainer'>
                            <button className='unstyledbutton audiobasepanelheader'>
                                <i className='material-icons panelicon'>volume_up</i>
                                <span>Audios</span>
                            </button>
                            <div className='audiobasepanelbody'>
                                <div className='audiopanelcontentcontainer'>
                                    {props.nft.privateAudios === '0' &&
                                    <div className='audiopanelpaddedcontent'>
                                        <div className='audioslist'>
                                        {Object.keys(props.nft.audio).map((audio) =>
                                            <Audios audio={props.nft.audio[audio]} />
                                        )}
                                        </div>
                                    </div>
                                    }
                                    {props.nft.privateAudios === '1' &&
                                        <div className='audiopanelpaddedcontent'>
                                            <div className='audioslist'>
                                                <UnlockAudios />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    function Audios(props) {
        console.log(props);
        return(
            <div>
                <p className='audiodisplayname'>{props.audio.name}</p>
                <audio controls={true} className='audioplayer'>
                    <source src={props.audio.url}/>
                    Your browser does not support the audio element.
                </audio>
            </div>
        )
    }

    function UnlockAudios(props) {
        return(
            <button class="submitbttn unstyledbutton unlockAudiosBttn" disabled={unlockDisabled} onClick={unlockAudios}>
                {!unlockDisabled &&
                    <div className='flexCenter flexRow'>
                        <span>View Audios</span>
                        <i class="material-icons">preview</i>
                    </div>
                }
                {unlockDisabled &&
                    <div className='flexCenter'>
                        <img src={whiteLoading} />
                    </div>
                }
                
            </button>
        )
    }

    function NFTInfo(props) {
        function AssetHeader(props) {
            return (
                <section className='assetinfoheader'>
                    <div className="assetinfoshare">
                        </div>
                        <div className="assetinfocategorycontainer">
                            <button className="assetcategorybutton unstyledbutton">
                                <p>{props.nft.category.name}</p>
                            </button>
                            <div className="assetinfocategoryimgcontainer">
                                <img className="assetinfocategoryimg" id="categoryimg" src={categoryImages[props.nft.category.name]} />
                            </div>
                        </div>
                        <div className="assetinfotitlecategory">
                            <div className="assetinfotitlecontainer">
                                <h1 className="assetinfotitle">{props.nft.name}</h1>
                            </div>
                        </div>
                        <div className="artistnamecontainer">
                        <Link to={"/" + props.nft.creator.name} className='creatorname'>
                            <div className="d-inline-flex align-items-center">
                                <span>{props.nft.creator.name}</span>
                                {props.nft.creator.verified === true &&
                                    <img className="creatorverified" src={verifiedImg} />
                                }
                            </div>
                        </Link>
                    </div>
                </section>
            )
        }

        function AssetSocial(props) {
            return(
                <section className="itemcounts">
                    <div className="ownernamecontainer">
                        <div className="ownernameflex">
                            Owned by&nbsp;
                            <Link className="ownernamelink" to={"/" + props.nft.owner.name}>
                                <div className="d-flex align-items-center">
                                    <span className="ownernametext" id="ownername">{props.nft.owner.name}</span>
                                    {props.nft.owner.verified === true &&
                                        <img className="ownerverified" src={verifiedImg} />
                                    }
                                </div>
                            </Link>  
                        </div>
                    </div>
                    <div className="viewscontainer">
                        <div className="iconflex">
                            <i className="material-icons">visibility</i>
                        </div>
                        <p id="viewstext">{props.nft.viewCount} views</p>
                    </div>
                    <button className="unstyledbutton viewlikesbutton">
                        <div className="iconflex">
                            <i className="material-icons">favorite</i>
                        </div>
                        <p id="favoritestext">{props.likeCount} favorites</p>
                    </button>
                </section> 
            )
        }

        return(
            <div>
                <AssetHeader nft={props.nft} />
                <div className='descriptioncontainer'>
                    <p className='description'>
                        {props.nft.description}
                    </p>
                </div>
                <div className='externallinkcontainer'>
                    <a href={props.nft.exlink} target='_blank'>
                        <p>
                            {props.nft.exlink}
                        </p>
                    </a>
                </div>
                <AssetSocial nft={props.nft} likeCount={props.likeCount} />
            </div>
        )
        
    }

    function NFTControls(props) {
        return(
            <div className="itemframe">
                <div className="assetlarge">
                    <div className="saleinfoframe">
                        <div className="saleendingcontainer">
                        {(props.nft.isAuction && props.nft.state === "3") &&
                            <SaleTimer endingDate={props.nft.endingDate} />
                        }
                        <div className="pricemain">
                                {(props.nft.state > 2 && props.nft.isApproved === true) && 
                                <div>
                                    <div className="currentpricelabel">
                                        {props.nft.price.text}
                                    </div>
                                    <div className="assetinfopricecontainer">
                                        <div className="priceflex">
                                            <div className="mainethicon">
                                                <a className="styledlink" href={props.nft.ETHLink} rel="nofollow noopener" target="_blank">
                                                    <div className="mainethimgcontainer">
                                                        <img className="mainethimg" src={cryptoImages[props.nft.ETHIcon]} />
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="priceoverflowcontainer">
                                                {props.nft.price.crypto}
                                                <span className="pricesymbol"></span>
                                            </div>
                                        </div>
                                        <div className="fiatpricecontainer">
                                            <div className="fiatpricetext" id="fiatprice">
                                                ({'$' + props.nft.price.usd})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {(props.nft.state === 2 && props.nft.isOwner === false) &&
                                <p>This NFT is not currently for sale.</p>
                                }
                                <div className="buycontainer">
                                    <div className="contentsblock">
                                        <div className={`${props.nft.state > 1 ? 'buyflexcontainer' : ''}`}>
                                            <ControlButtons nft={nftData} user={props.user} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        function ControlButtons(props) {

            if (props.nft.isOwner === true) {
                switch (props.nft.state) {
                    case '0':
                        return(
                            <div style={{alignItems: 'center', textAlign: 'center'}}>
                                <div style={{marginBottom:'25px'}}>
                                    <p className='nftProcessingHeader' id="nftprocessing">Your NFT is still being minted.</p>
                                    <img className="loadingimg" style={{width:'40px'}} src={whiteLoading} />
                                </div>
                                <p style={{color:'white', fontWeight:600, textAlign:'center', fontSize:'17px', marginBottom:'25px'}}>Pay attention to your wallet, if you tried to mint this a long time ago, there is a chance that the transaction has failed.</p>
                                <a href="https://cineteria.com/FAQ?transactions&amp;" style={{color:'#03e0ca', fontSize: '20px', fontWeight: 500, display:'block'}}> What does this mean?</a>
                            </div>
                        )
                    case '1':
                        return(
                            <div className='text-center'>
                                <div style={{display:'block'}}>
                                    <p style={{color:'#de4437', fontWeight:600, textAlign: 'center', fontSize:'18px',backgroundColor:'rgba(222,68,55,.1)', padding:'15px', borderRadius: '10px', display:'inline-block'}}>Transaction Failed!</p>
                                    <a href="/create?" style={{color:'#03e0ca', fontSize:'15px', marginLeft:'10px', fontSize: '18px', fontWeight:500, marginLeft:'10px'}}>Try again</a>
                                </div>
                                <p style={{color:'lightgray', margin:'10px'}}>This NFTs transaction failed.</p>
                                <a href="/FAQ?transactions" style={{color:'#03e0ca', fontSize: '20px', fontWeight: 500, display:'block'}}>Need Help?</a>
                            </div>
                        )
                    case '2':
                        var button = [];
                        button.function = listConfirm;
                        button.icon = "local_offer";
                        button.text = "List";
                        var button2 = [];
                        button2.function = () => notify('transferSettings');
                        button2.icon = "swap_horiz";
                        button2.text = "Transfer";
                        return(
                            <div className="d-flex flex-row">
                                <Button button={button} />
                                <Button button={button2} />
                            </div>
                        )
                    case '3':
                        if (props.nft.isApproved === true) {
                            var button = [];
                            button.function = () => notify('cancelPopup');
                            button.icon = "cancel_presentation";
                            button.text = "Cancel";
                            return(
                                <div>
                                    <Button button={button} />
                                </div>
                            )
                        } else {
                            var button = [];
                            button.function = () => auctionApprove('list');
                            button.icon = "back_hand";
                            button.text = "Approve Token Spending";
                            return(
                                <div>
                                    <Button button={button} />
                                </div>
                            )
                        }
                    case '4':
                        if (props.nft.isAuction === true) { //Dont need this, it will always be isAuction if state = 4
                            if (props.nft.price.crypto === '0') {
                                var button = [];
                                button.function = () => notify('cancelPopup');
                                button.icon = "cancel_presentation";
                                button.text = "Cancel";
                                return(
                                    <div>
                                        <Button button={button} />
                                        <div className="d-flex auctionnobids">
                                        <span className='controlBtnInfo'>Auction has ended with no bids. Cancel the auction to re-list.</span>            
                                        </div>
                                    </div>
                                )
                            } else {
                                var button = [];
                                button.function = () => notify('settlePopup');
                                button.icon = "fact_check";
                                button.text = "Settle";
                                var button2 = [];
                                button2.function = () => notify('cancelPopup');
                                button2.icon = "cancel_presentation";
                                button2.text = "Cancel";
                                return(
                                    <div className="d-flex flex-row">
                                        <Button button={button} />
                                        <Button button={button2} />
                                    </div>
                                )
                            }
                        }
                }
            } else {
                console.log('hi');
                switch (props.nft.state) {
                    case '0':
                    case '1':
                        return(
                            <p style={{color:'white', fontWeight:600, textAlign:'center', fontSize:'17px'}}>Sorry, this NFT does not exist. To go to the Marketplace page, click<a href='/assets' style='color:#03e0ca;'> here.</a></p>
                        )
                    case '2':
                        return(
                            <p style={{color:'white', fontWeight:600, textAlign:'center', fontSize:'17px'}}>NFT is not currently for sale.</p>
                        )
                    case '3':
                        var button = [];
                        console.log('hi2');
                        console.log(props.nft.isApproved);
                        if (props.nft.isApproved === true) {
                            if (props.nft.isAuction === true) {
                                button.function = bidNFT;
                                button.icon = "account_balance_wallet";
                                button.text = "Bid";
                            } else {
                                if (props.user) {
                                    if (props.user.managed === true) {
                                        console.log("popup first");
                                        button.function = () => notify('confirmBuy'); 
                                    }
                                } else {
                                    console.log("straight to buy");
                                    button.function = buyNFT;
                                }
                                //button.function = props.user.managed ? () => notify('confirmBuy') : buyNFT;
                                button.icon = "account_balance_wallet";
                                button.text = "Buy";
                            }
                            return (
                                <Button button={button} />
                            )
                        } else {
                            return (
                                <p style={{color:'white', fontWeight:600, textAlign:'center', fontSize:'17px'}}>NFT has not been approved for sale.</p>
                            )
                        }
                    case '4':
                        return(
                            <p style={{color:'white', fontWeight:600, textAlign:'center', fontSize:'17px'}}>Auction has ended, awaiting owner to settle.</p>
                        ) 
                }
            }

            function Button(props) {
                console.log(button);
                return(
                    <button className="buybutton unstyledbutton" id="buybutton" onClick={props.button.function}>
                        <div className="buyiconcontainer">
                            <i className="material-icons buyicon">{props.button.icon}</i>
                        </div>
                        {props.button.text}
                    </button> //INSTEAD HAVE MULTIOLE BUTTON TEMPELS AND SAY RETRAIVE THIS BUTTON
                )
            }
        }
    }

    function SaleTimer(props) {
        props = props.endingDate;
        const [timeLeft, setTimeLeft] = useState(CountDown());

        useEffect(()=> {
            const timer = setTimeout(() => {
                setTimeLeft(CountDown());
            }, 1000)
            return () => clearTimeout(timer);
        });

        function CountDown() {
            var formattedDate = moment.utc(props.date).format();
            var formattedDate = moment(formattedDate).local().format();
            var countDownDate = new Date(formattedDate).getTime();
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var timeLeft = {};
            
            if (distance > 0) {
                timeLeft = {
                    days: Math.floor(distance / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds: Math.floor((distance % (1000 * 60)) / 1000),
                }
                return timeLeft;
            } else {
                return false;
            }
        }

        return(
            <div>
                <div className="saleendstexttflex">
                    <div className="flexblock">
                        <div className="inlinecontainer">
                            <span className="nomarginblock" id="saleends">{timeLeft === false ? 'Grace Period' : 'Sale ends '}</span>
                            {timeLeft !== false &&
                            <span className="nomarginblock" id="saleenddate">{props.text}</span>
                            }
                            <span className="nomarginblock"></span>
                        </div>
                    </div>
                </div>
                <div className="salecountdownblock">
                    <div className="salecountdowncontainer">
                        <div className="salecountdownflex" id="timeparent">
                            {timeLeft === false &&
                                'Auction ending soon!'
                            }
                            {timeLeft !== false &&
                                <div className='salecountdownflex'>
                                <div className="timerblock">
                                <div className="timernumbers" id="timerdays">
                                        {timeLeft.days}
                                    </div>
                                    <p className="timertext">Days</p>
                                </div>
                                    <div className="timerblock">
                                    <div className="timernumbers" id="timerhours">
                                        {timeLeft.hours}
                                    </div>
                                    <p className="timertext">Hours</p>
                                </div>
                                <div className="timerblock">
                                    <div className="timernumbers" id="timerminutes">
                                        {timeLeft.minutes}
                                    </div>
                                    <p className="timertext">Minutes</p>
                                </div>
                                <div className="timerblock">
                                    <div className="timernumbers" id="timerseconds">
                                        {timeLeft.seconds}
                                    </div>
                                    <p className="timertext">Seconds</p>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function Offers(props) {
        return(
            <div className="itemactivityframe">
                <div className="itemactivitycontainer">
                    <div className="panelcontainer">
                        <div className="basepanelcontainer">
                            <header className="basepanelheader">
                                <i className="material-icons panelicon">swap_vert</i>
                                <span>Offers</span>
                                <i className="material-icons togglepanel">expand_less</i>
                            </header>
                            <div className="basepanelbody">
                                <div className="panelcontentcontainer">
                                    <div className="assethistorypanel">
                                        <div className="assethistoryfilters">
                                        </div>
                                        <div className="scrollboxcontainer">
                                            {!props.nft.offers &&
                                            <div className='noOffers'>
                                                <div className='noOffersText'>
                                                    No Offers.
                                                </div>
                                            </div>
                                            }
                                            {props.nft.offers && 
                                            <div className="scrollboxcontent" id="nftactivity"><div className="rowheadercontainer" role="row">
                                                <div className="spacedcellevent rowcell">
                                                    Price
                                                </div>
                                                <div className="spacedcellprice rowcell">
                                                    USD
                                                </div>
                                                <div className="rowcell cellspaced">From</div>
                                            </div>
                                            {props.nft.offers && 
                                                Object.keys(props.nft.offers).map((offer, key) =>
                                                    <OfferRow nft={props.nft} offer={props.nft.offers[offer]} />
                                                )
                                            }
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function OfferRow(props) {
        console.log(props);
        return(
            <div className="displayflex" role="row">
                <div className="rowcell cellspaced">
                    <div className="displayflex">
                        <div className="rowpricecontainer">
                            <div cursor="pointer">
                                {props.offer.price !== '' &&
                                <a className="styledlink" href={props.nft.ETHLink} rel="nofollow noopener" target="_blank">
                                    <div className="rowethimgcontainer">
                                        <img className="rowethimg" src={cryptoImages[props.nft.ETHIcon]} />
                                    </div>
                                </a>
                                }
                            </div>
                            <div className="rowpriceamount">
                                {props.offer.price}
                                <span className="pricesymbol"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rowcell cellspaced">
                    <div className='rowpriceamount'>
                        {`$${props.offer.priceUSD}`}
                    </div>
                </div>
                <div className="rowcell cellspaced">
                    <div className="ownernameflex">
                        <Link className="styledlink ellipsisoverflow" to={`/${props.offer.from.address}`}>
                            <div className="d-flex flex-row align-items-center">
                            <span className="activityname">{props.offer.from.name}</span>
                            {props.offer.from.verified === true && 
                                <img className="activityverified" src={verifiedImg} />
                            }
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="rowcell cellspaced">
                    <div className="ellipsisoverflow" id="eventtimestamp">
                        <span aria-expanded="false"></span>
                    </div>
                </div>
            </div>
        )
    }

    function AssetActivity(props) {
        return(
            <div className="itemactivityframe">
                <div className="itemactivitycontainer">
                    <div className="panelcontainer">
                        <div className="basepanelcontainer">
                            <header className="basepanelheader">
                                <i className="material-icons panelicon">swap_vert</i>
                                <span>Asset Activity</span>
                                <i className="material-icons togglepanel">expand_less</i>
                            </header>
                            <div className="basepanelbody">
                                <div className="panelcontentcontainer">
                                    <div className="assethistorypanel">
                                        <div className="assethistoryfilters">

                                        </div>
                                        <div className="scrollboxcontainer">
                                            <div className="scrollboxcontent" id="nftactivity"><div className="rowheadercontainer" role="row">
                                                <div className="spacedcellevent rowcell">
                                                    Event
                                                </div>
                                                <div className="spacedcellprice rowcell">
                                                    Price
                                                </div>
                                                <div className="rowcell cellspaced">From</div>
                                                <div className="rowcell cellspaced">To</div>
                                                <div className="rowcell cellspaced">Date</div>
                                            </div>
                                            {props.nft.event && 
                                                Object.keys(props.nft.event).map((event, key) =>
                                                    <ActivityRow nft={props.nft} event={props.nft.event[event]} />
                                                )
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ActivityRow(props) {
        return(
            <div className="displayflex" role="row">
                <div className="rowcell cellspaced spacedcellevent">
                    <a href={props.event.url} className="eventLink" target="_blank">
                        <i className="eventcellicon material-icons">{props.event.icon}</i>
                        <span>{props.event.text}</span>
                    </a>                
                </div>
                <div className="rowcell cellspaced">
                    <div className="displayflex">
                        <div className="rowpricecontainer">
                            <div cursor="pointer">
                                {props.event.price !== '' &&
                                <a className="styledlink" href={props.nft.ETHLink} rel="nofollow noopener" target="_blank">
                                    <div className="rowethimgcontainer">
                                        <img className="rowethimg" src={cryptoImages[props.nft.ETHIcon]} />
                                    </div>
                                </a>
                                }
                            </div>
                            <div className="rowpriceamount">
                                {props.event.price}
                                <span className="pricesymbol"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rowcell cellspaced">
                    <div className="ownernameflex">
                        <a className="styledlink ellipsisoverflow" href="/weluvmax">
                            <div className="d-flex flex-row align-items-center">
                            <span className="activityname">{props.event.from.name}</span>
                            {props.event.from.verified === true && 
                                <img className="activityverified" src={verifiedImg} />
                            }
                            </div>
                        </a>
                    </div>
                </div>
                <div className="rowcell cellspaced">
                    <div className="ownernameflex">
                        <a className="styledlink ellipsisoverflow">
                            <div className="d-flex flex-row align-items-center">
                            <span className="activityname">{props.event.to.name}</span>
                            {props.event.to.verified === true && 
                                <img className="activityverified" src={verifiedImg}/>
                            }
                            </div>
                        </a>
                    </div>
                </div>
                <div className="rowcell cellspaced">
                    <div className="ellipsisoverflow" id="eventtimestamp">
                        <span aria-expanded="false">{props.event.date}</span>
                    </div>
                </div>
            </div>
        )
    }

    function handleDate(date) {
        auctionEnding = date;
    }

    function AuctionDate(props) { //Now only this component refreshes on state change, and we dont have to do a work around that was fixing a global re-render, causing the auction settings popup to close
        const [endDate, setEndDate] = useState(new Date());
        console.log(endDate);
        function handleDate(date) {
            var error = '';
            const msInMinute = 60 * 1000;
            var minDiff = Math.round((date - new Date()) / msInMinute);
            console.log(minDiff);
            if (new Date() > date) {
                error = 'Your Auction cannot take place in the past.';
            } else if (minDiff < 30) {
                error = 'Your Auction must last at least 30 minutes.';
            }
            props.setPopupErrors( prevValues => {
                return {...prevValues, ['auctionError']: error}
            })
            setEndDate(date);
            props.handleDate(date); //For global usage upon submit
        }

        return(
            <div>
                <DatePicker 
                dateFormat={'MM-dd-yyyy h:mm a'}
                showTimeSelect 
                inline 
                selected={endDate} 
                onChange={(date) => handleDate(date)}
                timeIntervals={5}
                minDate={new Date()} />
            </div>
        )
    }

    function ShareButton(props) {
        var nft = props.nft;
        const [copiedUrl, setCopiedUrl] = useState(false);

        function copyUrl() {
            if (copyLink() === true) {
                setCopiedUrl(true);
            }
        }
    
        function copyUrlOut() {
            setCopiedUrl(false);
        }

        return (
            <div class="headerbuttonsgrid">
                    <div class="headerbuttonsflex">
                        <div class="headerbuttonscontainer">
                            <Tippy placement="bottom" interactive={true} interactiveBorder={5} arrow={false} theme='transparent' appendTo={document.body} trigger='click' offset={[0, 0]} animation='shift-away' onHidden={copyUrlOut} content={
                                <div className="sharedropdown" id="sharedropdown">
                                    <ul className="sharelist">
                                        <li>
                                            <div className="shareitem" id="copyurl" onClick={copyUrl}>
                                                <img src={cineteriaLogo} />
                                                <span id="copyurltext">{copiedUrl ? 'Copied!' : 'Copy Link'}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <a href={nft.isOwner ? `https://twitter.com/intent/tweet?text=Check out my ${nft.category.name === "Other" ? "NFT" : nft.category.name}, ${nft.name}, on Cineteria! via @cineteria https://cineteria.com/${contractAddress}/${id}` : `https://twitter.com/intent/tweet?text=Check out ${nft.creator.name}'s ${nft.category.name === "Other" ? "NFT" : nft.category.name}, ${nft.name}, on Cineteria! via @cineteria https://cineteria.com/${contractAddress}/${id}`} rel="nofollow noopener" target="_blank" id="sharetwitter">
                                                <div className="shareitem">
                                                    <img src={twitter} />
                                                    <span>Share on Twitter</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`https://www.facebook.com/sharer/sharer.php?u=https://cineteria.com/${contractAddress}/${id}`} rel="nofollow noopener" target="_blank" id="sharefacebook">
                                                <div className="shareitem">
                                                    <img src={facebook} />
                                                    <span>Share on Facebook</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            }>  
                                
                                <button className="headerbutton" id="sharebutton">
                                    <div className="d-flex">
                                        <i className="material-icons">share</i>
                                    </div>
                                </button>
                                                
                            </Tippy>
                    </div>
                </div>
            </div>     
        )
    }

    // function Popup(props) {
    //     return (
    //         <div className="popup" id={props.name}>
    //             <div className="popupoverlay"></div>
    //             <div className={`popupcontent ${props.maxWidth ? 'w-100' : ''}`}>
    //                 {!props.customHeader && 
    //                 <div className="popupheader">
    //                     <h3>{props.header}</h3>
    //                     <div className="popupclose material-icons-outlined" onClick={() => unnotify(props.name)}>cancel</div>
    //                 </div>
    //                 }
    //                 {props.customHeader && props.customHeader}
    //                 {props.content}
    //             </div>
    //         </div>
    //     )
    // }

    function Popups(props) {
        const [popupErrors, setPopupErrors] = useState({});

        return(
            <div>
                <Popup name="auctionSettings" header="Enter the information" maxWidth={true} content={
                    <div>
                        <p className='text-center' style={{marginTop: '10px !important'}}>Select the ending date and time for your Auction.</p>
                            <div className="d-flex justify-content-around">
                                <form id="auctionForm" action="" method="POST" enctype="multipart/form-data" className="was-validated auctionform">
                                    {/**<RangePicker showTime={{format: 'h:mm a'}} popupStyle={{position:'fixed'}} /> We don't need range because we just need the ending date */}
                                    {/* <DatePicker showTime={{format: 'h:mm a'}} popupStyle={{position:'fixed'}}/> */}
                                    <AuctionDate setPopupErrors={setPopupErrors} handleDate={(date) => handleDate(date)}/>
                                    {/* <input type="text" name="datetimes" id="datetimes" required /> */}
                                    <div className="error" id="auctionError">{popupErrors.auctionError}</div>
                                    <input type="submit" id="auctionsubmitbttn" className="unstyledbutton popupbutton submitbttn" value="List" onClick={auctionSettings}/>
                                </form>
                            </div>
                    </div>
                } />  
            </div>
        )
    }

    // async function like() {
    //     if (window.userAddress === '') {
    //         return;
    //     }

    //     var data = await jQuery.ajax({
    //         url: `${API_ENDPOINT}likenft.php`,
    //         type: 'POST',
    //         data: {'tokenId': id, 'userAddress': window.userAddress}
    //     })

    //     data = JSON.parse(data);
    //     if (data.success === true) {
    //         if (data.action === 0) {
    //             setLiked(true);
    //             //setLikeCount(likeCount + 1);
    //             setNFTData( prevValues => {
    //                 return {...prevValues, ['likesCount']: nftData.likesCount + 1, ['isLiked']: true}
    //             })
    //         } else {
    //             setLiked(false);
    //             //setLikeCount(likeCount - 1);
    //             setNFTData( prevValues => {
    //                 return {...prevValues, ['likesCount']: nftData.likesCount - 1, ['isLiked']: false}
    //             })
    //         }
    //     }
    // }

    async function doTransaction(TransactionParameters, list = 0, popup = 'generalComplete', wait = true) {
        setTxState("Transaction Pending");
        try {
            if (!props.user.managed) {
                var txHash = await web3.sendTransaction(TransactionParameters)
                .on('transactionHash', function(txHash){
                    notify(popup);
                    if (list === 1) {
                        localStorage.setItem("listTx" + id, txHash.transactionHash);
                    }
                })
                .on('error', function(error) {
                    if (error.message.includes("Transaction was not mined within")) {
                        setTxState("Transaction Taking Longer Than Usual");
                    } else {
                        setTxState("Transaction Failed");
                    }
                });
                
                if (wait === true) { //If want to do either reset NFT on transaction complete or show failed popup
                    if (txHash.status === true) {
                        getNFT();
                        unnotify();
                    }
                }
                return txHash;
            } else {
                notify(popup);
                var i = 0;
                const interval = setInterval(async function() {
                    i++;
                    if (i > 600) {
                        setTxState("Transaction Taking Longer Than Usual");
                        clearInterval(interval);
                    }
                    await props.web3.getTransactionReceipt(TransactionParameters, function(err, receipt) {
                        if (receipt) {
                            clearInterval(interval);
                            if (receipt.status === true) {
                                setTimeout(() => {
                                    getNFT();
                                    unnotify();
                                }, 1500)
                            } else {
                                setTxState('Transaction Failed');
                            }
                        }
                    });
                }, 1000);
            }
        } catch (e) {
            console.log(e);
            return false;
            //set to not doing transaction anymore
        }
    }

    function waitForTx(txId, onSuccess, onFail, timeOut, onError, intervalTime = 1000, secondsToRun = 600) { //intervalTime => Milliseconds
        try {
            var maxInterval = (secondsToRun * 1000) / intervalTime; //Seconds to run will be the same without calculator if interval time is 1000
            var i = 0;
            const interval = setInterval(async function() {
                i++;
                if (i > maxInterval) {
                    clearInterval(interval);
                    timeOut();
                }
                await props.web3.getTransactionReceipt(txId, function(err, receipt) {
                    if (receipt) {
                        clearInterval(interval);
                        if (receipt.status === true) {
                            setTimeout(() => {
                                onSuccess();
                            }, 1500) //1500 Here to make sure backend gets time to update this transaction
                        } else {
                            onFail();
                        }
                    }
                });
            }, intervalTime);
        } catch (e) {
            onError();
        }
    }

    function listConfirm() {
        if (checkChain() == false) {
            return;
        } else if (localStorage.getItem('listTx' + id)) {
            // var txHash = localStorage.getItem('listTx' + id);
            // web3.getTransactionReceipt(txHash, (error, receipt) => { //mainWeb3?
            //     if (receipt.status == true) {
            //         localStorage.removeItem('listTx' + id);
            //         getNFT();
            //         return;
            //     } else if (receipt == null) {
            //         var listbutton = document.getElementById('listbutton');
            //         listbutton.disabled = true;
            //         unnotify();
            //         notify('alreadyListing');
            //         return;
            //     } else if (receipt.status == false) {
            //         localStorage.removeItem('listTx' + id);
            //     }
            // });
        }
        if (localStorage.dontshowagain == "true") {
            notify('listOptions');
        } else {
            notify("confirmpopup");
        }
    }

    function confirmYes() {
        if (document.getElementById('dontshowagain').checked) {
            localStorage.setItem("dontshowagain", "true");
        } else {
            localStorage.setItem("dontshowagain", "false");
        }
        notify('listOptions');
    }
    
    function confirmNo() {
        if (document.getElementById('dontshowagain').checked) {
            localStorage.setItem("dontshowagain", "true");
        } else {
            localStorage.setItem("dontshowagain", "false");
        }
        unnotify();
        jQuery("#listbutton").attr("disabled", false);
    }

    async function listingSettings() {
        // if (localStorage.getItem('listTx' + id)) {
        //     unnotify();
        //     return;
        // }
        unnotify();
        notify('listingSettings');
        let price = document.getElementById("price");
        price.removeEventListener("keyup", event => {
        });
        price.addEventListener("keyup", async () => {
            document.getElementById("listusd").innerHTML = await tradeRate(price.value); //Did not make this an inline thing just cause we would have to make it another function to account for the price
        });
        jQuery("form#listingForm").unbind('submit');
        jQuery("form#listingForm").submit(async function (e) {
            e.preventDefault();
            jQuery("#listsubmitbttn").attr("disabled", true);
            var formData = new FormData(this);
    
            formData.append('contractAddress', contractAddress);
            formData.append('tokenId', id);
            formData.append('userAddress', window.userAddress);
            try {
                var data = await jQuery.ajax({
                    url: `${API_ENDPOINT}listnft.php`,
                    type: 'POST',
                    data: formData,
                    cache: false,
                    contentType: false,
                    processData: false,
                    xhrFields: {
                        withCredentials: true,
                    },
                })
                console.log(data);
                var parsed = JSON.parse(data);
            } catch (e) {
                jQuery("#listsubmitbttn").attr("disabled", false);
                return;
            }
            var transactiondata = parsed.transactiondata;
            var TransactionParameters = setupTransactionData(transactiondata, props.user);
            // var serializedTransaction = JSON.parse(transactiondata.serializedTransaction);
            // var TransactionParameters = {
            //     to: serializedTransaction.to,
            //     from: window.userAddress,
            //     data: serializedTransaction.data,
            // };
            await doTransaction(TransactionParameters, 1);
            jQuery("#listsubmitbttn").attr("disabled", false);
        })
    }

    async function auctionSettings(e) {
        e.preventDefault();
        e.target.disabled = true;
        var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        var dateString = auctionEnding.toISOString();
        console.log(dateString);
        var auctionData = {
            'contractAddress': contractAddress,
            'tokenId': id,
            'userAddress': window.userAddress,
            'endingDate': dateString,
            'timezone': timezone,
        }
        console.log(auctionEnding);
        var data = await jQuery.ajax({
            url: `${API_ENDPOINT}auctionlist.php`,
            type: 'POST',
            data: auctionData,
            xhrFields: {
                withCredentials: true,
            },
        })
        console.log(data);
        try {
            var parsed = JSON.parse(data);
        } catch(e) {
            e.target.disabled = false;
            return;
        }
        var transactiondata = parsed.transactiondata;
        var TransactionParameters = setupTransactionData(transactiondata, props.user);

        await doTransaction(TransactionParameters, 1);
        e.target.disabled = false;

    }

    // async function auctionSettings() {
    //     unnotify();
    //     notify("auctionSettings");
    //     jQuery("form#auctionForm").unbind('submit');
    //     jQuery("form#auctionForm").submit(async function (e) {
    //         e.preventDefault();
    //         jQuery("#auctionsubmitbttn").attr("disabled", true);
    //         var formData = new FormData(this);
    //         var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    //         formData.append('contractAddress', contractAddress);
    //         formData.append('tokenId', id);
    //         formData.append('userAddress', window.userAddress);
    //         formData.append('datetimes', jQuery("#datetimes").val());
    //         formData.append('timezone', timezone);
    
    //         var data = await jQuery.ajax({
    //             url: "https://cineteria.com/wp-content/plugins/marketplace/auctionlist.php",
    //             type: 'POST',
    //             data: formData,
    //             cache: false,
    //             contentType: false,
    //             processData: false,
    //         })

    //         var parsed = JSON.parse(data);
    //         var transactiondata = parsed.transactiondata;
    //         var serializedTransaction = JSON.parse(transactiondata.serializedTransaction);

    //         var TransactionParameters = {
    //             to: serializedTransaction.to,
    //             from: window.userAddress,
    //             data: serializedTransaction.data,
    //         };

    //         await doTransaction(TransactionParameters, 1);
    //         jQuery("#listbutton").attr("disabled", true);
    //     });
    // }

    async function auctionApprove() {
        if (checkChain() == false) {
            return;
        }

        unnotify();
        notify("approvePopup");

        var data = {
            'contractAddress': contractAddress,
            'tokenId': id,
            'userAddress': window.userAddress,
        };

        var data = await jQuery.ajax({
            url: `${API_ENDPOINT}auctionapprove.php`,
            type: 'POST',
            data: data,
            xhrFields: {
                withCredentials: true,
            },
        })

        var parsed = JSON.parse(data);
        console.log(parsed);
        var transactiondata = parsed.transactiondata;
        var TransactionParameters = setupTransactionData(transactiondata, props.user);
        document.getElementById("buybutton").disabled = true;
        await doTransaction(TransactionParameters);
        // var txData = { //This does the same thing on the backend that our frontend does in (doTransaction)
        //     'contractAddress': contractAddress,
        //     'tokenId': id,
        //     'userAddress': window.userAddress,
        //     'transactiontx': txHash.transactionHash,
        // }
        // try {
        //     var data = await jQuery.ajax({ //CHANGE LISTNER TIMER TO 0 SECONDS AT FIRST BECAUSE TO GET HERE IN SENDTRANSACTION, IT WAITS FOR THE TRANSACTION TO BE DONE, NO POINT IN WAITING LONGER
        //         url: "https://api.cineteria.com/marketplace/approveListener.php",
        //         type: 'POST',
        //         data: txData,
        //     })
        //     console.log(data);
        //     data = JSON.parse(data);
        // } catch (e) {
        //     console.log(e);
        // }
        
        // if (data.success === true) {
        //     getNFT();
        // } else if (data.success === false) {
        //     notify('failedPopup');
        // }
        document.getElementById("buybutton").disabled = false;
    }

    async function settleNFT() {
        if (checkChain() == false) {
            return;
        }
    
        var userData = {
            'contractAddress': contractAddress,
            'tokenId': id,
            'userAddress': window.userAddress,
        }
    
        var data = await jQuery.ajax({
            url: `${API_ENDPOINT}auctionsettle.php`,
            type: 'POST',
            data: userData,
            xhrFields: {
                withCredentials: true,
            },
        })
        var parsed = JSON.parse(data);
        var transactiondata = parsed.transactiondata;
        var TransactionParameters = setupTransactionData(transactiondata, props.user);
        // document.getElementById("settlebttn").disabled = true;
        // document.getElementById("settlebttn").style.cursor = "wait";
        // document.getElementById("popupsettlebttn").disabled = true;
        doTransaction(TransactionParameters);
    }

    async function cancelNFT() {
        if (checkChain() == false) {
            return;
        }

        var userData = {
            'contractAddress': contractAddress,
            'tokenId': id,
            'userAddress': window.userAddress,
        }
    
        var data = await jQuery.ajax({
            url: `${API_ENDPOINT}cancelnft.php`,
            type: 'POST',
            data: userData,
            xhrFields: {
                withCredentials: true,
            },
        })
        console.log(data);
        var parsed = JSON.parse(data);
        var transactiondata = parsed.transactiondata;
        var TransactionParameters = setupTransactionData(transactiondata, props.user);

        document.getElementById("buybutton").disabled = true;
        document.getElementById("popupcancelbttn").disabled = true;
        doTransaction(TransactionParameters);
    }

    async function transferNFT(e) { //contract needs debugging
        e.preventDefault();
        if (checkChain() == false) {
            return;
        }
        //var formData = new FormData(this);
        var toAddress = document.getElementById('artists').value;
        var isGift = document.getElementById('giftSwitch').checked;
        console.log(toAddress);
        
        
        // var transferABI = [{ "inputs": [ { "internalType": "address", "name": "from", "type": "address" }, { "internalType": "address", "name": "to", "type": "address" }, { "internalType": "uint256", "name": "tokenId", "type": "uint256" }, { "internalType": "bytes", "name": "dataBytes", "type": "bytes" } ], "name": "safeTransferFrom", "outputs": [], "stateMutability": "payable", "type": "function" }];
        // var transferContract = new web3.Contract(transferABI, contractAddress);
        // transferContract.methods.safeTransferFrom(window.userAddress, toAddress, id, []).send({from: window.userAddress}, function(err, result) {
        //     console.log(result);
        // });

        var userData = {
            'contractAddress': contractAddress,
            'tokenId': id,
            'userAddress': window.userAddress,
            'to': toAddress,
            'isGift': isGift,
        }
        console.log(userData.userAddress);
        var data = await jQuery.ajax({
            url: `${API_ENDPOINT}transfernft.php`,
            type: 'POST',
            data: userData,
            xhrFields: {
                withCredentials: true,
            },
        })
        console.log(data);
        var parsed = JSON.parse(data);
        console.log(parsed);
        var transactiondata = parsed.transactiondata;
        var TransactionParameters = setupTransactionData(transactiondata, props.user);
        doTransaction(TransactionParameters);
    }

    async function buyNFT() {
        if (checkChain() === false) {
            return;
        }

        //disable buy button

        var userData = {
            'contractAddress': contractAddress,
			'tokenId': id,
			'userAddress': window.userAddress,
        }

        var result = await checkERC20(userData);
        if (result === false) {
            //enable buy button
            return;
        }

        result = await jQuery.ajax({
            url: `${API_ENDPOINT}buynft.php`,
            type: 'POST',
            data: userData,
            xhrFields: {
                withCredentials: true,
            },
        });
        console.log(result);
        var parsed = JSON.parse(result);
        var transactiondata = parsed.transactiondata;
        var TransactionParameters = setupTransactionData(transactiondata, props.user);
        doTransaction(TransactionParameters);
    }

    async function bidNFT() {
        if (checkChain() == false) {
            return;
        }
        unnotify();
        notify('auctionbid');
        var bid = document.getElementById("bid");
        bid.removeEventListener("keyup", event => { });
	    bid.addEventListener("keyup", event => {
		    document.getElementById("bidusd").innerHTML = tradeRate(bid.value);
        })
        jQuery("form#bidamount").unbind('submit');
	    jQuery("form#bidamount").submit(async function (e) {
            e.preventDefault();
            unerror('bid-group');
            var formData = new FormData(this);
            jQuery("#submitbttn").attr("disabled", true);
            jQuery("#bid").attr("disabled", true);
            formData.append('userAddress', window.userAddress);
            formData.append('contractAddress', contractAddress);
            formData.append('tokenId', id);
            var result = await checkERC20(formData);
            if (result == false) {
                jQuery("#submitbttn").attr("disabled", false);
                jQuery("#bid").attr("disabled", false);
                return;
            }
            var data = await jQuery.ajax({
                url: `${API_ENDPOINT}buynft.php`,
                type: 'POST',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                xhrFields: {
                    withCredentials: true,
                },
            })
            var parsed = JSON.parse(data);
            if (parsed.error) {
                error('bid-group', parsed.error);
            }
            var transactiondata = parsed.transactiondata;
            var TransactionParameters = setupTransactionData(transactiondata, props.user);
            unnotify();
            doTransaction(TransactionParameters, 0);
	    })
    }

    async function checkChain() {
        window.chainId = await web3.getChainId();
        if (data.chain === 'MATIC' && window.chainId !== 137) {
            switchMATIC();
            return false;
        } else if (data.chain === 'ETH' && window.chainId !== 1) {
			switchETH();
            return false;
		}
    }

    async function txWait(txHash, interval = 3000) {
        const self = this;
        const transactionReceipt = function (resolve, reject) {
            web3.getTransactionReceipt(txHash, (error, receipt) => {
                if (error) {
                    reject(error);
                } else if (receipt === null) {
                    setTimeout(
                        () => transactionReceipt(resolve, reject),
                        interval ? interval : 500);
                } else {
                    console.log(receipt);
                    resolve(receipt);
                }
            })
        }

        if (Array.isArray(txHash)) {
            return Promise.all(txHash.map(
                oneTxHash => self.txWait(oneTxHash, interval)));
        } else if (typeof txHash === "string") {
            var result = await new Promise(transactionReceipt);
            return Promise.resolve(result);
        } else {
            throw new Error("Invalid Type: " + txHash);
        }

    }

    async function checkERC20(userData) {
        if (userData instanceof FormData) {
            var result = await jQuery.ajax({
                url: `${API_ENDPOINT}checkERC20.php`,
                type: 'POST',
                data: userData,
                cache: false,
                contentType: false,
                processData: false,
            })
        } else {
            var result = await jQuery.ajax({
                url: `${API_ENDPOINT}checkERC20.php`,
                type: 'POST',
                data: userData,
            })	
        }
        let data = JSON.parse(result);
        if (data.error === 'noWeth') {
            unnotify();
            notify('noWeth');
            //enable buy button
            //ISERROR TRUE
        } else if (data.error === 'insufficientBalance') {
            unnotify();
		    notify('insufficientBalance');
            //enable buy button
            //ISERROR TRUE
        } else if (data.error === 'insufficientAllowance') {
            if (userData instanceof FormData) {
                if (userData.get('bid') != 'undefined') {
                    var isAuction = true;
                    var result = await approveERC20(userData.get('bid'));
                }
            } else {
                var isAuction = false;
                var result = await approveERC20();
            }

            if (result === true) {
                unnotify();
                var isError = false;
                if (isAuction === true) {
                    notify('auctionbid');
                }
            } else if (result === false) {
                unnotify();
                notify('failedPopup');
                var isError = true;
            } else if (result == 'error') {
                return false;
            }
        } else if (data.error == 'none') {
            var isError = false;
        }
        if (isError == true) {
            return false;
        } else {
            return true;
        }
    }

    async function approveERC20(bid = 0) {

        var data = {
            'tokenId': id,
            'userAddress': contractAddress,
            'bid': bid,
        }
        var result = await jQuery.ajax({
            url: `${API_ENDPOINT}approveERC20.php`,
            type: 'POST',
            data: data,
            xhrFields: {
                withCredentials: true,
            },
            //async: false,
        })
        console.log(result);
        var parsed = JSON.parse(result);
        var transactiondata = parsed.transactiondata;
        if (props.user.managed === true) {
            notify('waitERC20');
            waitForTx(
            transactiondata, 
            () => {txHash.status = true}, 
            () => {unnotify(); notify('generalComplete'); setTxState("Transaction Failed")}, 
            () => {unnotify(); notify('generalComplete'); setTxState("Transaction Taking Longer Than Usual")}, 
            () => {txHash.status = false});
        } else {
            var serializedTransaction = JSON.parse(transactiondata.serializedTransaction);
            var TransactionParameters = {
                to: serializedTransaction.to,
                from: window.userAddress,
                data: serializedTransaction.data,
            };

            try {
                var txHash = await web3.sendTransaction(TransactionParameters) //this returns a receipt when the transaction is completed, no use having to use a (transactionWait) function. It does it for us already, to do other things in the meantime, like display a "waiting" screen, we can do that with the line below, (on transactionHash is returned) NOT reciept, do something.
                .on('sending', () => console.log('sending'))
                .on ('sent', () => console.log('sent'))
                .on('transactionHash', () => notify('waitERC20')); //Could but a varible inside the parenthasis to use it in the arrow function
            } catch (e) {
                if (e.code === 4001) {
                    return 'error'; //Cancels transaction
                }
            }
        }
        

        if (txHash.status === true) {
            return true;
        } else {
            return false;
        }

    }

    async function unlockAudios() {
        authVal();

        function authVal() {
            
            var userData = {
                'userAddress': window.userAddress,
            }
        
            jQuery.ajax({
                url: `${API_ENDPOINT}validation.php`,
                type: 'POST',
                data: userData,
                success: function (data) {
                    let message = data;
                    let publicAddress = userData.userAddress;
                    authSign(message, publicAddress);
                }
            })
        }
        
        async function authSign(message, address) {
        
            const from = address;
            const msg = message;
            setUnlockDisabled(true);
            try {
                var signdata = await web3.personal.sign(msg, from);
            } catch (e) {
                setUnlockDisabled(false);
                return;
            }
            authHandle(address, signdata);
        }
        
        function authHandle(address, signature) {
        
            var authData = {
                'userAddress': address,
                'signature': signature,
                'method': 2,
                'contractAddress': contractAddress,
			    'tokenId': id,
            }
        
            jQuery.ajax({
                url: `${API_ENDPOINT}signVerify.php`,
                type: 'POST',
                data: authData,
                xhrFields: {
                    responseType: 'blob' // to avoid binary data being mangled on charset conversion
                },
                success: function (blob, status, xhr) {
                    let fileName = "";
                    let disposition = xhr.getResponseHeader('Content-Disposition');
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = fileNameRegex.exec(disposition);
                        if (matches != null && matches[1]) fileName = matches[1].replace(/['"]/g, '');
                    }
        
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(blob, fileName);
                    } else {
                        let URL = window.URL || window.webkitURL;
                        let downloadUrl = URL.createObjectURL(blob);
                        if (fileName) {
                            let a = document.createElement("a");
                            if (typeof a.download === 'undefined') {
                                window.location.href = downloadUrl;
                            } else {
                                a.href = downloadUrl;
                                a.download = fileName;
                                document.body.appendChild(a);
                                a.click();
                            }
                        } else {
                            window.open(downloadUrl, '_blank');
                        }
                    }
                    setUnlockDisabled(false);
                }
            })
        }

    }

    async function maticBridging(amt) {
        let balance = await web3.getBalance(window.userAddress[0]);
        balance = balance / weiToETH;
        console.log(balance);
        if (amt > balance) {
            document.getElementById("bridge-group").innerHTML = "You are trying to bridge more ETH than you have.";
            document.getElementById("bridge-group").style.display = "block";
            document.getElementById("bridgebttn").disabled = false;
            return;
        }
        var result = await rootChainManagerContract.methods
            .depositEtherFor(window.userAddress[0])
            .send({ from: window.userAddress[0], value: amt * 1000000000000000000 })
            .then((txId) => {
                return true;
            })
            .catch((error) => {
                if (error.code === 4001) {
                    document.getElementById("bridgebttn").disabled = false;
                }
                return false;
            })
        return result;
    }

    function error() {

    }

    function unerror() {

    }
}

export default NFT;