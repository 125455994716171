import React from 'react';

export default function CustomButton(props) {
  return (
    <button className={props.theme ? props.theme : 'darkButton'} onClick={props.function} disabled={props.disabled}>
      {!props.loading &&
        props.children
      }
      {props.loading &&
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      }
    </button>
  )
}
