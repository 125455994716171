import React from "react";
import SiteHelmet from "../components/siteHelmet";
import Config from "../Config.json";
import '../css/help.css';

const TITLE = Config.SITE_TITLE + " - Help";
const DESC = "Have any questions about Cineteria? FAQ are here!";
const CANONICAL = Config.SITE_DOMAIN + "/help";

function Help() {
    return (
        <div className="d-flex flex-column mainhelp">
            <SiteHelmet TITLE={TITLE} CANONICAL={CANONICAL} DESC={DESC} />
    <div className="helpcontact">
        <h3>Need help? <a href="/contact">Contact us</a> at</h3>
        <h3 className="email">help@cineteria.com</h3>
    </div>

    <h3 className="faqheader">FAQ:</h3>

    <div className="qacontainer">

        <div className="qa">
            <button className="question darkButton" data-bs-toggle="collapse" data-bs-target="#q1">Is Cineteria Safe?</button>

            <div className="answer collapse" id="q1">
                <span>Yes. Cineteria is Safe! If you have any questions, feel free to contact us through <a href="/contact">any of the contact methods here.</a></span>
            </div>
        </div>

        <div className="qa">
            <button className="question darkButton" data-bs-toggle="collapse" data-bs-target="#q2">My transaction failed!</button>

            <div className="answer collapse" id="q2">
                <span>This usually happens when you didn't pay enough gas <strong>(for wallet users)</strong> or there was a hiccup in the transaction process. No worries though! Everything that you sent in the transaction is reverted back to your wallet, besides transaction fees. <strong>Now what? Simply retry the transaction!</strong></span>
            </div>
        </div>

        <div className="qa">
            <button className="question darkButton" data-bs-toggle="collapse" data-bs-target="#q3">Why do I have to pay transaction fees?</button>

            <div className="answer collapse" id="q3">
                <span><strong>This does not apply to users with an email and password account.<br/><br/> </strong>Transaction fees (or gas) is a fee that is paid to blockchain "miners". These are the people that verify your transactions on the blockchain! The fee does not go to us, they are necessary to keep the blockchain working. Gas fees are required for anything you do on the blockchain, off and on Cineteria.</span>
            </div>
        </div>

        <div className="qa">
            <button className="question darkButton" data-bs-toggle="collapse" data-bs-target="#q4">Does Cineteria take a marketplace fee?</button>

            <div className="answer collapse" id="q4">
                <span>Yes, we take a small 3% of all <strong>final</strong> sales.</span>
            </div>
        </div>

        <div className="qa" id="claimusername">
            <button className="question darkButton" data-bs-toggle="collapse" data-bs-target="#claimusernamea">Somebody using your username?</button>

            <div className="answer collapse" id="claimusernamea">
                <span>If you are an established artist and someone is using your username, message us on <a href="https://www.instagram.com/cineteria" target="_blank">Instagram (@cineteria)</a> from your artist account. Please include your <strong>current name</strong> and <strong>the name you want</strong>.</span>
            </div>
        </div>

    </div>
</div>
    );
}

export default Help;