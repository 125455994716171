import React, {useState, useEffect} from 'react';
import {NavLink, Link} from "react-router-dom";
import Tippy from '@tippyjs/react';
import jQuery from 'jquery';
import Highlighter from "react-highlight-words";
import wethImg from "../images/weth.png";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function HeaderNotifications(props) {
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [notifications, setNotifications] = useState('');
  const [gifts, setGifts] = useState('');
  const hide = () => setNotificationsVisible(false);
  const show = () => setNotificationsVisible(true);

  useEffect(() => { //When we sign in our getUser that fetches the profile picture and name, also gets the count of new notifications. This is showing them before any notification requests have been called.
    if (props.user !== null) {
      setNotifications( prevValues => {
        return {...prevValues, ['new']: props.user.newNotifications}
      })
    } else {
      setNotifications('');
    }
  }, [props.user])
  

  async function handleNotifications() {
    if (notificationsVisible === false) {
      if (props.isLoggedIn === true) {
        var data = await jQuery.ajax({
          url: `${API_ENDPOINT}notifications.php`,
          type: 'POST',
          data: {'userAddress': window.userAddress, 'authToken': window.token}
        })
        var data = JSON.parse(data);
        console.log(data);
        setNotifications(data);
      }
      show();
      if (data.new > 0) {
        var userData = {
          'userAddress': window.userAddress,
          'method': 'notification',
          'id': data.id,
        }
        var data = await jQuery.ajax({
          url: `${API_ENDPOINT}seen.php`,
          type: 'POST',
          data: userData,
        })
      }
    } else {
      hide();
      setNotifications( prevValues => {
        return {...prevValues, ['new']: 0}
      })
    }
  }

  return (
    <Tippy visible={notificationsVisible} onClickOutside={hide} arrow={false} allowHTML={true} interactive={true} theme="transparent" maxWidth="none" offset={[0, 10]} trigger="manual" placement="bottom" content={
      <div className='notificationsContainer'>
        <div className='notificationsContent'>
        {Object.keys(notifications).map((noti) =>
          <NotificationIdentifier data={notifications[noti]} />
        )}
        {props.isLoggedIn === false &&
        <Link to="/login" className='notificationsLogInContainer' onClick={hide}>
          <button className='notificationsLogInButton'>Log In</button>
        </Link>
        }
        </div>
      </div>
    }>
      <div className='headerNotificationsMaster' onClick={handleNotifications}>
          <div className='notificationNumber'>
              {notifications.new > 0 ? (notifications.new > 99 ? '99' : notifications.new) : ''}
          </div>
          <i className='fa-solid fa-bell notificationBell'></i>
      </div>
    </Tippy>
  )
  
  function NotificationIdentifier(props) {
    console.log(props);
    props = props.data;
    switch (props.type) {
      case "0": //Custom
        return (
          <Notification>
            {props.customText}
          </Notification>
        )
      case "1": //NFT
        switch (props.typeId) {
          case "Minted": //Minted
            return(
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  Your {props.nft.category}, <Bold>{props.nft.name}</Bold>, has been minted!
              </Notification>
            )
          case "NFTBatchMint": //Batch Mint
              return(
                <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                    Your {props.mintAmount} {props.nft.category}s, <Bold>{props.NFT}</Bold>, have been minted!
                </Notification>
              )  
          case "Transfer": //Transferred
                return (
                  <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                      <Bold>{props.user.name}</Bold> has transferred <Bold>{props.nft.name}</Bold> to you.
                  </Notification>
                )
          case "TransferSender": //Transferred Sender
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  You have transferred <Bold>{props.nft.name}</Bold> to <Bold>{props.user.name}</Bold>.
              </Notification>
            )
          case "Gift": //Gift
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  <Bold>{props.user.name}</Bold> has gifted you <Bold>{props.nft.name}</Bold>!
              </Notification>
            )
          case "GiftSender": //Gift Sender
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  You have Gifted <Bold>{props.nft.name}</Bold> to <Bold>{props.user.name}</Bold>.
              </Notification>
            )
          case "NFTBurned": //Burned
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  <Bold>{props.name}</Bold>, has been burned.
              </Notification>
            )
          case "ListingCreated": //Listed Create
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                You have listed <Bold>{props.nft.name}</Bold>. Make sure to approve your NFT for selling!
              </Notification>
            )
          case "ListingCancelled": //Listing Cancelled
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                You canceled the listing for <Bold>{props.nft.name}</Bold>.
              </Notification>
            )
          case "AuctionCreated": //Auction Create
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  You have started an auction for <Bold>{props.nft.name}</Bold>. Make sure to approve your NFT for selling.
              </Notification>
            )
          case "AuctionCancelled": //Auction Cancelled
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  You canceled the auction for <Bold>{props.nft.name}</Bold>.
              </Notification>
            )
          case "AuctionSettled": //Auction Settle
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  <Bold>{props.nft.name}</Bold>'s Auction has been settled with a final bid of <WethContainer>{props.price}</WethContainer>.
              </Notification>
            )
          case "AuctionSettledWinner":
            return (
              <Notification image={props.nft.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  You have won <Bold>{props.nft.name}</Bold>'s Auction with a final bid of <WethContainer>{props.price}</WethContainer>!
              </Notification>
            )
          default:
            break;
        }
        break;
      case "2": //User
        switch (props.typeId) {
          case "ListingSold": //Listing Buy
            return (
              <Notification image={props.user.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  <Bold>{props.user.name}</Bold> has bought your {props.nft.category}, <Bold>{props.nft.name}</Bold> for <WethContainer>{props.price}</WethContainer>
              </Notification>
            )
          case "AuctionBid": //Auction Bid
            return (
              <Notification image={props.user.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  <Bold>{props.user.name}</Bold> has bidded <WethContainer>{props.price}</WethContainer> on your {props.nft.category}, <Bold>{props.nft.name}</Bold>.
              </Notification>
            )
          case "Like": //Like
            return (
              <Notification>
                <Bold>{props.user.name}</Bold> liked your {props.nft.category}, <Bold>{props.nft.name}</Bold>
              </Notification>
            )
          default:
            break;
        }
        break;
      case "3": //Self
        switch (props.typeId) {
          case "AccountSetup":
            return (
              <Notification>
                Create a username and add a profile picture to allow your fans to find you!
              </Notification>
            )
          case "ListingSoldSender": //Listing Buy
            return (
              <Notification image={props.self.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  You have bought <Bold>{props.user.name}</Bold>'s {props.nft.category}, <Bold>{props.nft.name}</Bold> for <WethContainer>{props.price}</WethContainer>
              </Notification>
            )
          case "AuctionBidSender": //Auction Bid
            return (
              <Notification image={props.self.image} time={props.date} seen={props.seen} url={`/${props.nft.contractAddress}/${props.tokenId}`} type={props.type}>
                  You have bidded <WethContainer>{props.price}</WethContainer> <Weth/> on <Bold>{props.user.name}</Bold>'s {props.nft.category}, <Bold>{props.nft.name}</Bold>.
              </Notification>
            )
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  function Notification(props) {
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgError, setImgError] = useState(false);

    function handleLoad() {
      if (imgLoaded === false) {
          setImgLoaded(true);
      }
    }

    function handleImgError() {
      if (imgError === false) {
        setImgError(true)
      }
    }

    return(
      <Link to={props.url} className='notification' onClick={hide}>
        <div style={{display:'flex', flexDirection:'row', alignContent:'center', alignItems:'center'}}>
          <div className={`notificationImage ${props.type === "1" ? 'nft' : 'user'}`}>
            {(imgLoaded === false || imgError) &&
            <div className={`notificationloading ${props.type === "1" ? 'nft' : 'user'} loadingNFT`} />
            }
            {imgError === false &&
              <img src={props.image} style={imgLoaded ? {} : {display:'none'}} onError={handleImgError} onLoad={handleLoad} />
            }
            
          </div>
          <div className='notificationText'>
            {props.children}
          </div>
          {props.seen === "0" && 
          <div className='notificationNew'></div>
          }
        </div>
        <div className='notificationTime'>
          {props.time}
        </div>
      </Link>
    )
  }

  function Bold(props) {
    return (
      <strong style={{fontWeight:'700'}}>{props.children}</strong>
    )
  }

  function Weth(props) {
    return (
      <img src={wethImg} className="ethimg" />
    )
  }

  function WethContainer(props) {
    return (
      <div className='wethContainer'>{props.children} <Weth/></div>
    )
  }

}
