import React, {useState} from 'react';
import Tippy from '@tippyjs/react';
import jQuery from 'jquery';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function ArtistSearch(props) {
    const [artistSearchVisible, setArtistSearchVisible] = useState(false);


    function handleArtists(e) {
        var artistsInput = e.target;
        let artist = artistsInput.value.split(',');
        let focusedArtist = artist[artist.length - 1];

        let data = {
            'artists': focusedArtist
        }

        jQuery.ajax({
            url: `${API_ENDPOINT}searchartists.php`,
            type: 'POST',
            data: data,
            success: function (response) {
                var results = JSON.parse(response);
                console.log(results);
                if (results.userhtml === 0) {
                    //artiststippy.current.hide();
                    setArtistSearchVisible(false);
                } else {
                    //artiststippy.current.show();
                    setArtistSearchVisible(true);
                    var artistsresults = document.getElementById("artistsresults");
                    artistsresults.innerHTML = results.userhtml.join("");
                    document.querySelectorAll(".artistsresults").forEach(item => {
                        item.addEventListener("click", event => {
                            if (props.multiple) {
                                artist[artist.length - 1] = item.firstChild.innerHTML + ',';
                                artistsInput.value = artist.join(', ');
                            } else {
                                artist[artist.length - 1] = item.firstChild.innerHTML;
                                artistsInput.value = artist;
                            }
                            setArtistSearchVisible(false);
                        })
                    })
                }
            }
        })
    }

  return (
    <Tippy arrow={false} visible={artistSearchVisible} allowHTML={true} interactive={true} theme="transparent" maxWidth="none" offset={[0, -15]} trigger="manual" placement="bottom" content={
            <div className="d-flex flex-column searchdropdown" id="artistssearch">
                <ul className="searchusers" id="artistsresults">
                </ul>
            </div>
        }>
            <input type="text" name="artists" id="artists" placeholder='Search for users' className={props.className ? props.className : ''} onKeyUp={handleArtists} required={props.required ? true : false} onChange={props.handleInput ? props.handleInput : ''}/>
    </Tippy>
  )
}
