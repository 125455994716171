import React from 'react';
import {Helmet} from "react-helmet";
import Config from "../Config.json";

export default function SiteHelmet(props) {
  return (
    <Helmet>
        <title>{props.TITLE}</title>
        <link rel="canonical" href={props.CANONICAL}/>
        <meta name="description" content={props.DESC}/>
        <meta name="theme-color" content={Config.THEME_COLOR}/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={props.CANONICAL}/>
        <meta property="og:title" content={props.TITLE}/>
        <meta property="og:description" content={props.DESC} />
        {props.IMAGE &&
          <meta property="og:image" content={props.IMAGE}/>
        }
    </Helmet>
  )
}
