import React from "react";

function Footer() {
    return (
        <div className="footercont">
            <span className="footer">
            © 2022 CINETERIA. ALL RIGHTS RESERVED
            </span>
        </div>
    );
}

export default Footer;