import React, {useState, useEffect} from "react";
import SiteHelmet from "../components/siteHelmet";
import Config from '../Config.json';
import jQuery from 'jquery';
import {Link, useNavigate} from 'react-router-dom';
import "../css/profilesettings.css";
import defaultProfile from '../images/defaultProfile.png';
import { notify, unnotify } from "../js/functions";
import Popup from "../components/popup";
import CustomButton from "../components/custombutton";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const TITLE = Config.SITE_TITLE + " - Profile Settings";
const DESC = "Change your profile picture, name, bio, email, and all things associated with your profile.";
const CANONICAL = Config.SITE_DOMAIN + "/profile/settings";

function ProfileSettings(props) {
    var web3 = props.web3;
    const [userInfo, setUserInfo] = useState({displayName: '', bio: '', email: '', profilePic: defaultProfile, accentColor: '#FFFFFF'});
    const [changeButton, setChangeButton] = useState({disabled: false, value: 'Update'});
    const [updating, setUpdating] = useState(false);
    const [errors, setErrors] = useState({displayName: {type: ''}, bio: '', email: '', profilePic: ''});
    const navigate = useNavigate();
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgError, setImgError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [newSettings, setNewSettings] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    function handleImgLoad() {
        if (imgLoaded === false) {
            setImgLoaded(true);
        }
    }

    function handleImgError(e) {
        if (imgError === false) {
            setImgError(true);
        }
    }

    function handlePassword(e) {
        setPassword(e.target.value);
        if (passwordError === true && e.target.value !== '') {
            setPasswordError(false);
        }
    }

    async function handleSubmit() {
        setSubmitting(true);
        if (password === '') {
            return;
        }
        var formData = newSettings;
        formData.append('profilepicUpload', userInfo.profilePic);
        formData.append('accentColor', userInfo.accentColor);
        formData.append('method', 1);
        formData.append('password', password);
        var response = await jQuery.ajax({
            url: `${API_ENDPOINT}managedVerify.php`,
            type: 'POST',
            data: formData,
            contentType: false,
            processData: false,
            cache: false,
            xhrFields: {
                withCredentials: true,
            },
        })
        console.log(response);
        handleSettingsResponse(response);
        unnotify();
        setSubmitting(false);
        return response;
    }

    async function managedVerify(formData) {
        setChangeButton('disabled', '');
        doChangeButton('value', "Update");
        setNewSettings(formData);
        setPassword('');
        document.getElementById('verifyPassword').value = '';
        notify('managedVerify');
    }

    useEffect(() => {
        async function getprofileInfo() {
            setLoaded(false);
            var userData = {
            'userAddress': window.userAddress,
            }
        
            var data = await jQuery.ajax({
                    url: `${API_ENDPOINT}profilesettingsInfo.php`,
                    type: 'POST',
                    data: userData,
                    xhrFields: {
                        withCredentials: true,
                    },
            })
            console.log(data);
            var profileinfo = JSON.parse(data);
            if (profileinfo.displayName === null) {
                profileinfo.displayName = '';
            }
            if (profileinfo.bio === null) {
                profileinfo.bio = '';
            }
            if (profileinfo.emailAddress === null) {
                profileinfo.emailAddress = '';
            }
            setUserInfo({
            displayName: profileinfo.displayName, 
            bio: profileinfo.bio, 
            email: profileinfo.emailAddress, 
            profilePic: profileinfo.profileimgUrl, 
            accentColor: profileinfo.accentColor});
            var darkColor = colorShade(profileinfo.accentColor, -120);
            jQuery("#settingsprofileimgconatiner").css("background-image", "linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, " + profileinfo.accentColor + "," + darkColor + ")");
            jQuery('#bordercolor').val(profileinfo.accentColor);
            setLoaded(true);
            //make one set state, see if we can do that with the different form changing things
        }
        getprofileInfo();
    }, [])

    const colorShade = (col, amt) => {
        col = col.replace(/^#/, '')
        if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]
    
        let [r, g, b] = col.match(/.{2}/g);
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
    
        r = Math.max(Math.min(255, r), 0).toString(16)
        g = Math.max(Math.min(255, g), 0).toString(16)
        b = Math.max(Math.min(255, b), 0).toString(16)
    
        const rr = (r.length < 2 ? '0' : '') + r
        const gg = (g.length < 2 ? '0' : '') + g
        const bb = (b.length < 2 ? '0' : '') + b
    
        return `#${rr}${gg}${bb}`
    }
    console.log(changeButton);
    function handleBorderColor(e) {
        var color = e.target.value;
        var darkColor = colorShade(color, -120);
        jQuery("#settingsprofileimgconatiner").css("background-image", "linear-gradient(#1a1a1a, #1a1a1a), radial-gradient(circle at top left, " + color + "," + darkColor + ")");
        setUserInfo(prevValues => {
            return {...prevValues, ['accentColor']: color}
        })
    }   

    function handleDisplayName(e) {
        var value = e.target.value;
            if (/\s/.test(value)) {
                error('displayName', 'noSpaces');
                doChangeButton('disabled', 'true');
            } else {
                error('displayName', '');
                doChangeButton('disabled', '');
                var errors = false;
            }
    }

    function changeprofilePic(e) {
        setImgError(false);
        setImgLoaded(false);
        const input = e.target;
        if (input.files[0]) // truthy, falsey
        {
            var file = input.files[0];
            setUserInfo(prevValues => {
                return {...prevValues, ['profilePic']: URL.createObjectURL(file)}
            })
            //settingsprofilepic.src = URL.createObjectURL(file);
        }
    }

    function doChangeButton(id, value) {
        setChangeButton( prevValues => {
            return {...prevValues, [id]: value}
        })
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        doChangeButton('disabled', 'true');
        //jQuery("#changebutton").attr("disabled", true);
        if (settingsRequired() == true) {
            doChangeButton('value', "Updating...");
            //changebutton.value = "Updating..."; //set state
            //var updating = true; //set state
            setUpdating(true); //Since set updating state is true, can change changebutton to have loading icon
            jQuery("#userAddress").val(window.userAddress); //set state
            var formData = new FormData(e.target);
            if (window.managed) {
                managedVerify(formData);
            } else {
                accountValidation(formData);   
            }  
        } else {
            doChangeButton('disabled', '');
        }
    }

    function settingsRequired() {
        var isError = false;
        setErrors('');

        let image = document.getElementById("profilepicUpload");
        let username = document.getElementById("displayName");
        let bio = document.getElementById("bio");
        let email = document.getElementById("email");
    
        let profilePic = image.files[0];
        if (typeof profilePic !== 'undefined') {
            if (profilePic.size > 50000000) {
                document.getElementById("profilepic-group").innerHTML = "File size is too big. Please make sure your file is UNDER 50 MB";
                error("profilepic-group", "File size is too big. Please make sure your file is UNDER 50 MB");
                console.log("PROFILE PIC");
                isError = true;
            }
        }
        if (/\s/.test(username.value)) {
            error("displayName", "noSpaces");
            isError = true;
        } else if (username.value == '') {
            error("displayName", "A Display Name is required.");
            isError = true;
        }
        if (bio.value.length > 200) {
            error("bio", "Bio is too long. 200 characters maximum. You are " + (bio.value.length - 200) + " characters over.");
            isError = true;
            console.log("bio");
        }
        if (email.value == '') {
            error("email", "An email is required for sending updates on your NFTs.");
            isError = true;
            console.log("email");
        } else if (email.value.length > 320) {
            error("email", "Email is too long. 320 characters maximum. You are " + (email.value.length - 320) + " characters over.");
            isError = true;
            console.log("email loing");
        }
    
        if (isError == false) {
            return true;
        }
    }
    
    function error(id, message) {
        console.log(errors);
        if (id === "displayName") {
            setErrors((prevValues) => {
                return {...prevValues, [id]: {type: message}}
            })   
        } else {
            setErrors( prevValues => {
                return {...prevValues, [id + 'type']: message}
            })
        }
        
        //document.getElementById(id).innerHTML = message;
    }

    function accountValidation(formData) {
        var userData = {
        'userAddress': window.userAddress,
        }
    
        jQuery.ajax({
                url: `${API_ENDPOINT}validation.php`,
                type: 'POST',
                data: userData,
            success: function(data) {
                let message = data;
                let publicAddress = window.userAddress;
                signTransaction(message, publicAddress, formData);
                }
            })
    }
    
    async function signTransaction(message, address, formData) {
        
        const from = address;
        const msg = message;
        try {
            console.log(changeButton.disabled);
            var signdata = await web3.personal.sign(msg, from);
        } catch (e) {
            console.log(e);
            console.log(e.code);
            if (e.code === 4001) {
                doChangeButton('value', "Update")
            } else if (e.message === "MetaMask Personal Message Signature: User denied message signature.") {
                doChangeButton('value', "Update")
            } else {
                doChangeButton('value', "Error ❌ (Please refresh the page)");
            }
            doChangeButton('disabled', '');
            return;
        }
        window.signdata = signdata;
        handleAuthenticate(window.userAddress, signdata, formData);
    }
    
        function handleAuthenticate(address, signature, formData) {
            formData.append('userAddress', address);
            formData.append('signature', signature);
            formData.append('profilepicUpload', userInfo.profilePic);
            formData.append('accentColor', userInfo.accentColor);
            formData.append('method', 1);
    console.log(formData);
        jQuery.ajax({
            url: `${API_ENDPOINT}signVerify.php`,
            type: 'POST',
            data: formData,
            contentType: false,
            processData: false,
            cache: false,
            success: function(response) {
                console.log(response);
                doChangeButton('disabled', '');
                setUpdating(false);
                try {
                    var parsed = JSON.parse(response);
                    console.log(parsed);
                } catch (e) {
                    console.log(e);
                    doChangeButton('value', "Error ❌ (Please refresh the page)");
                    return;
                }
                if (parsed.errors) {
                    setErrors(parsed.errors);
                    // Object.keys(parsed.errors).forEach(key => {
                    //     error(key, parsed.errors[key]);
                    // })
                    doChangeButton('value', "Update");
                    return;
                }
                if (parsed.updated == true) {
                    doChangeButton('value', "Updated ✅");
                    props.getuserData();
                } else {
                    doChangeButton('value', "Update");
                }
                //jQuery("#displayName-group").html(parsed.errors.displayName);
                var changebuttonDOM = document.getElementById("changebutton");
                changebuttonDOM.addEventListener("mouseover", () => {
                    doChangeButton('value', "Update");
                })
            }
        })
        }

    return (
        <div className="psMain">
            <SiteHelmet TITLE={TITLE} CANONICAL={CANONICAL} DESC={DESC} />
            <div class="d-flex align-items-center">
                <div class="backarrow" onClick={() => navigate(-1)}>
                <i class="material-icons psBack">arrow_back</i>
                </div>
                <h2 className="psHeader">Profile Settings</h2>
            </div>
            <div className="profilesettingsform">
                <form id="settingsform" action="profilesettings.php" method="POST" enctype="multipart/form-data" className="d-flex" onSubmit={handleFormSubmit}>
                    <div className="d-flex flex-column">

                        <label for="profilepicUpload"><h3>Profile Picture:</h3></label>
                        <p className="info">File types supported: JPG, PNG, GIF. Max File size: 50 MB.</p>
                        <input type="file" name="profilepicUpload" id="profilepicUpload" accept="image/png, image/gif, image/jpeg" onChange={changeprofilePic} />
                        <div className="d-flex ppinputcont">
                            <label for="profilepicUpload" className="profilepiclabel" id="profilepiclabel">
                                <div className="imagediv">
                                    <div className="imageinputcontainer">
                                        <div className="imageinputoverlay">
                                            <div className="imageinputoverlayflex">
                                                <i className="material-icons">edit</i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profileimgcontainer" id="settingsprofileimgconatiner">
                                        {(imgLoaded === false || loaded === false) &&
                                            <div className="profilepicture loadingNFT"/>
                                        }
                                       <img id="settingsprofilepic" className="profilepic" src={!imgError ? userInfo.profilePic : defaultProfile} alt="Uploaded Preview" style={loaded ? {} : {display: 'none'}} onLoad={handleImgLoad} onError={(e) => handleImgError(e)} />
                                    </div>
                                </div>
                            </label>
                            <div id="profilepic-group" className="error">{errors.profilePic}</div><br/>
                            <div className="colorinputcontainer">
                                <input type="color" className="colorinput" id="bordercolor" defaultValue={userInfo.accentColor} onInput={handleBorderColor} />
                            </div>
                        </div><br/>


                        <div className="d-flex flex-column">
                            <label for="displayName"><h3>Display Name: <p className="required">*</p></h3></label>
                            <input className="displayNameInput" type="text" name="displayName" id="displayName" onKeyUp={handleDisplayName} defaultValue={userInfo.displayName} />
                            <div id="displayName-group" className="error"><DisplayNameError errors={errors} /></div>
                        </div><br/><br/>

                        <div className="d-flex flex-column">
                            <label for="bio"><h3>Bio:</h3></label>
                            <textarea rows="4" cols="50" className="bio" name="bio" id="bio" defaultValue={userInfo.bio} ></textarea>
                            <div id="bio-group" className="error">{errors.bio}</div>
                        </div><br/><br/>

                        <div className="d-flex flex-column">
                            <label for="emailinput"><h3>Email: <p className="required">*</p></h3></label>
                            <p className="info">Used to send you notifications letting you know if your NFT was sold or received a higher bid.</p>
                            <input type="email" id="email" name="email" defaultValue={userInfo.email} disabled={window.managed}/>
                            <div id="email-group" className="error">{errors.email}</div>
                        </div><br/><br/>

                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-center">
                                <input type="submit" id="changebutton" className="changebutton" name="submit" value={changeButton.value} disabled={changeButton.disabled}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Popup name="managedVerify" header='Confirm Changes' darkMode={true} content={
                <div className={`emailLoginForm ${passwordError ? 'passwordError' : ''}`} >
                    <p>Enter your password</p>
                    <input type="password" placeholder="Password" id="verifyPassword" onKeyUp={(e) => handlePassword(e)} />
                    {/* <button className="darkButton" onClick={handleSubmit}>Confirm</button> */}
                    <CustomButton loading={submitting} function={handleSubmit}>Confirm</CustomButton>
                </div>
            } />
        </div>
    );

    function DisplayNameError(props) {
        var errors = props.errors;
        if (errors.displayName) {
            if (errors.displayName.type) {
                switch (errors.displayName.type) {
                    case "noSpaces":
                        return (
                            `No spaces are allowed in a username. Use dashes (-) or underscores (_) instead.`
                        )
                        break;
                    case "tooLong":
                        return (
                            `Display name is too long. Can only be 38 characters long. You are ${errors.displayName.focus} characters over.`
                        )
                    break;
                    case "specialChars":
                        return(
                            `You can only have regular letters and numbers in your name`
                        )
                    break;
                    case "ethAddress":
                        return(
                            `You cannot make your display name an Ethereum address.`
                        )
                    break;
                    case "notAvailable":
                        return(
                            `Display name ${errors.displayName.focus} is not available`
                        )
                    break;
                    case "nameTaken":
                        return(
                            <div>
                                There is already another user with the name, ${errors.displayName.focus} If you are an artist and someone has stolen your name, feel free to 
                                <Link to="/contact"> contact us.</Link>
                            </div>
                        )
                        
                    break;
                    default:
                        break;
                }
            }
        }
    }

    function handleSettingsResponse(response) {
        doChangeButton('disabled', '');
        try {
            var parsed = JSON.parse(response);
            console.log(parsed);
        } catch (e) {
            console.log(e);
            doChangeButton('value', "Error ❌ (Please refresh the page)");
            return;
        }
        if (parsed.errors) {
            setErrors(parsed.errors);
            // Object.keys(parsed.errors).forEach(key => {
            //     error(key, parsed.errors[key]);
            // })
            doChangeButton('value', "Update");
            return;
        }
        if (parsed.updated == true) {
            doChangeButton('value', "Updated ✅");
            props.getuserData();
        } else {
            doChangeButton('value', "Update");
        }
        //jQuery("#displayName-group").html(parsed.errors.displayName);
        var changebuttonDOM = document.getElementById("changebutton");
        changebuttonDOM.addEventListener("mouseover", () => {
            doChangeButton('value', "Update");
        })
    }

}

export default ProfileSettings;