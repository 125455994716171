import React, {useState, useEffect} from 'react'
import jQuery from 'jquery';
import {Link} from "react-router-dom";
import { tradeRate } from '../js/functions';

//Category Images
import Album from '../images/categoryAlbum.png';
import Art from '../images/categoryArt.png';
import Collectable from '../images/categoryCollectable.png';
import EP from '../images/categoryEP.png';
import Other from '../images/categoryOther.png';
import Single from '../images/categorySingle.png';
import Ticket from '../images/categoryTicket.png';

import verifiedImg from '../images/verified.png';
import wethImg from '../images/weth.png';
import ethImg from '../images/eth.png';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const categoryImages = {
    "Album": Album, 
    "Art": Art,
    "Collectable": Collectable,
    "EP": EP,
    "Other": Other,
    "Single": Single,
    "Ticket": Ticket,
}

const cryptoImages = {
    "WETH": wethImg,
    "ETH": ethImg,
};

export default function NFTCard(props) {
    props = props.data;
    const [isLiked, setLiked] = useState(props.likes.isLiked);
    const [likeCount, setLikeCount] = useState(props.likes.count);
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgError, setImgError] = useState(false);
    useEffect(() => {setLikeCount(props.likes.count)}, [props.likes.count]);
    
    function handleLoad() {
        if (imgLoaded === false) {
            setImgLoaded(true);
        }
    }

    function handleImgError() {
        if (imgError === false) {
            setImgError(true);
        }
    }

  return (
    <article className="assetCard">
        <Link className="styledlink" to={'/' + props.contractAddress + '/' + props.id}>
            <div className="assetCardContent">
                <div className="assetmedia">
                    <div className="flexcenteraligned">
                        <div className="imagecontainer">
                            {(imgLoaded === false || imgError) &&
                                <div className="assetimage loadingNFT" />
                            }
                            {imgError === false &&
                            <img className="assetimage" style={imgLoaded ? {} : {display: 'none'}} src={props.image} onError={handleImgError} onLoad={handleLoad} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="flexspacebetween">
                <div className="cardspacebetween">
                    <div className="assetinfo">
                        <div className="categoryblock">
                                <div className="categorycontainer">{props.category.name}<div className="categoryimgcontainer">
                                        <img className="categoryimg" id="categoryimg" src={categoryImages[props.category.name]}/>
                                    </div>
                                </div>
                                </div>
                        <div className="flexassetname">
                            <div className="assetnameblock">
                                <div className="assetname">
                                    {props.name}   
                                </div>
                            </div>
                        </div>
                        <div className="artistnamecontainer">
                            <object>
                                <Link className="artistnamelink" to={'/' + props.creator.address}>
                                    <div className="d-flex align-items-center">
                                        <div className="artistname">
                                            {props.creator.name}
                                        </div>
                                        {props.creator.verified === true &&
                                        <img className="creatorverified" src={verifiedImg} />}
                                    </div>
                                </Link>
                            </object>
                        </div>
                    </div>
                    {props.state > 2 &&
                    <Price />
                    }
                </div>
                <footer className="cardfooterflex">
                    <div className="ownerflex">
                        <div className="d-flex align-items-center">
                            <div className="ownertextcontainer">
                                {'Owned by: ' + props.owner.name}
                            </div>
                            {props.owner.verified === true &&
                            <img className="ownerverified" src={verifiedImg} />}
                        </div>
                        <div className="ownercontainer"> 
                        </div>
                    </div>
                        <span className="likescontainer">
                            <i size="20" aria-label="like" value="like_border" className={`likeicon material-icons ${isLiked ? 'liked' : ''}`} onClick={(e) => like(props.id, e)}>favorite</i>
                            <div className="likesamount">{likeCount}</div>
                        </span>
                </footer>
            </div>
        </Link> 
    </article>
  )

    function Price() {
        if ((props.isAuction === false && props.price.amount === 0) || props.isApproved === false) {
            return (
                <div></div>
            )
        } else {
            return (
                <div className="pricecontainer">
                    <div className="priceflexcontainer">
                        <span className="pricetext">{props.isAuction ? props.price.amount > 0 ? 'Highest Bid' : 'Be the first to bid' : 'Current Price'}</span>
                        {props.price.amount > 0 &&
                        <div className="pricenumcontainer">
                            <div>
                                {`$${props.price.usd}`}
                            </div>
                            {/* <div className="ethblock">
                                <object>
                                    <a className="ethlink" href={props.ETHLink} rel="nofollow nooppener" target="_blank">
                                        <div size="14" className="ethcontainer" >
                                            <img className="ethimg" size="14" src={cryptoImages[props.ETHIcon]}/>
                                        </div>
                                    </a>
                                </object>
                            </div>
                            <div className="pricenum">
                                {props.price.amount}
                                <span className="pricerawsymbol"></span>
                            </div> */}
                        </div>
                        }
                        {props.isAuction === true && <TimeLeft data={props} />}
                    </div>
                    <div className="cardfooterannotations">
                    </div>
                </div>
            )
        }
        

        function TimeLeft(props) {
            props = props.data;
            return(
                <div className="d-flex align-items-center timeLeftCont">
                    <i className="material-icons">timer</i>
                    <span className="timeLeft">{props.timeLeft}</span>
                </div>
            )
        }

    }

    async function like(id, e) {
        e.preventDefault();
        if (window.userAddress === '') {
            return;
        }

        var data = await jQuery.ajax({
            url: `${API_ENDPOINT}likenft.php`,
            type: 'POST',
            data: {'tokenId': id, 'userAddress': window.userAddress}
        })

        data = JSON.parse(data);
        if (data.success === true) {
            if (data.action === 0) {
                setLiked(true);
                setLikeCount(Number(likeCount) + 1);
            } else {
                setLiked(false);
                setLikeCount(Number(likeCount) - 1);
            }
        }

    }

}
