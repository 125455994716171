import React from "react";
import '../css/adminDash.css';


function AdminDash() {
    return (
        <div className="adminDash">
            <div className="tab">

            </div>
            <div className="panel active">
                <div className="featuredNFT">
                    <span className="featuredheader">Featured NFT</span>
                    <div className="contractAddress">
                        <span className="contractAddressheader">Contract Address:</span>
                        <input type="text" />
                    </div>
                    <div className="tokenId">
                        <span className="tokenIdheader">Token Id:</span>
                        <input type="text"/>
                    </div>
                    <button>
                        Change
                    </button>
                </div>
                <div className="hideNFT">

                </div>
                <div className="verifyUser">

                </div>
                <div className="banUser">

                </div>
            </div>
        </div>
    );
}

export default AdminDash;