import React from 'react'
import loadingImg from '../images/loading.gif';

export default function LoadingNFTs(props) {
    return (
        <article className="assetCard loadingNFT">
    <a className="styledlink">
        <div className="assetCardContent">
            <div className="assetmedia">
                <div className="flexcenteraligned">
                    <div className="imagecontainer">
                        <div className="assetimage" />
                    </div>
                </div>
            </div>
        </div>
        <div className="flexspacebetween">
            <div className="cardspacebetween">
                <div className="assetinfo">
                    <div className="categoryblock">
                            <div className="categorycontainer"><div className="categoryimgcontainer">
                                    <img className="categoryimg" id="categoryimg"/>
                                </div>
                            </div>
                            </div>
                    <div className="flexassetname">
                        <div className="assetnameblock">
                            <div className="assetname">
                            </div>
                    </div>
                    </div>
                    <div className="artistnamecontainer">
                        <object>
                            <a className="artistnamelink">
                                <div className="d-flex align-items-center">
                                    <div className="artistname">
                                    </div>
                                </div>
                            </a>
                        </object>
                    </div>
                </div>
            </div>
            <footer className="cardfooterflex">
                <div className="ownerflex">
                    <div className="d-flex align-items-center">
                        <div className="ownertextcontainer">
                        </div>
                    </div>
                    <div className="ownercontainer"> 
                    </div>
                </div>
                <span className="likescontainer">
                    <i size="20" aria-label="like" value="like_border" className={`likeicon material-icons`}></i>
                    <div className="likesamount"></div>
                </span>
            </footer>
        </div>
    </a> 
</article>
    )
    
}
